
import React,{useState} from "react";
import {useForm} from 'react-hook-form'
import {NavLink} from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import * as firebase from 'firebase'
import { db } from "MY_LOGIN_AND_SIGNUP/db/config";

const functions = firebase.functions()


function Edit_Student({notify,userData,setUserData,onClick}){
    const [visible,setVisible] = useState(false)
    const [error,setError] = useState('')
    const {register,handleSubmit,errors} = useForm()
    
    // console.log(firstname,lastname,mobile,status)
    return (
      <>
        <div className="content" style={{width:1150,}}>
          <Row style={{}}>
            <Col md="8">
              <Card style={{border:'2px solid white'}}>
                <CardHeader>
                  <h5 className="title">Edit Student</h5>
                </CardHeader>
                <CardBody>
                  <Form style={{paddingRight:10}} onSubmit={handleSubmit(async data=>{
                     console.log('data',data)
                    //  return
                    setVisible(true)
                    // console.log('form submitted')
                    // console.log(data.mobile)
                    // console.log(data.status)
                    // console.log(data.firstname)
                    // console.log(data.lastname)
                    onClick()
                        if(data.mobile<4000000000){
                             setError('invalid mobile number')
                           }else{
                             setError('')
                              const finalData={
                                userID:userData._uniqueID,
                                firstname:data.firstname,
                                lastname:data.lastname,
                                mobile:data.mobile,
                                status:data.status,
                                address:userData.address,
                                shift:data.shift,
                                createdFrom:userData.createdFrom,
                                disabled:userData.disabled,
                                email:userData.email,
                                secondaryEmail:data.secondaryEmail,
                                enrollmentNO:data.enrollment,
                                password:userData.password,
                                profilePic:userData.profilePic,
                                _uniqueID:userData._uniqueID,
                                lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                sendEmail: data.sendEmail
                               }
                               console.log('finalData',finalData)
                              document.querySelector("#loading-indicator").style.display = "flex";
                              if(data.secondaryEmail){

                                let query = await db.collection('zSystemStudents')
                                  .where("secondaryEmail", "==", data.secondaryEmail)
                                  .get()
                                console.log('query.docs',query.docs)
                                
                                if(query.docs.length > 0){
                                  if(query.docs.length !== 1){
                                    document.querySelector("#loading-indicator").style.display = "none";
                                    notify("This Secondary email address is already exist" , true)
                                    return
                                  }
                                  let email = query.docs.map(doc => doc.data())
                                  console.log('email uniq',email) 
                                  if(email[0]._uniqueID !== userData._uniqueID){
                                    document.querySelector("#loading-indicator").style.display = "none";
                                    notify("This Secondary email address is already exist" , true)
                                    return
                                  }
  
                                }
                              }
                              if(data.enrollment){
                                let query = await db.collection('zSystemStudents')
                                  .where("enrollmentNO", "==", data.enrollment)
                                  .get()
                               
                              
                                if(query.docs.length > 0){
                                  if(query.docs.length !== 1){
                                    document.querySelector("#loading-indicator").style.display = "none";
                                    notify("This Enrollment No is already exist" , true)
                                    return
                                  }
                                  let email = query.docs.map(doc => doc.data())
                                  console.log(' Enrollment No',email) 
                                  if(email[0]._uniqueID !== userData._uniqueID){
                                    document.querySelector("#loading-indicator").style.display = "none";
                                    notify("This  Enrollment No address is already exist" , true)
                                    return
                                  }

                                }
                              }
                              const updateStudent = functions.httpsCallable('updateStudent');
                              updateStudent(finalData).then(result =>{
                              console.log('result',result)
                              if(result.data.hasError === false){
                                document.querySelector("#loading-indicator").style.display = "none";

                                setUserData(prev => prev.map((item) => item._uniqueID == userData._uniqueID ? finalData : item))
                                notify('Student details updated successfully',false)  
                              }else{
                                document.querySelector("#loading-indicator").style.display = "none";
                                console.log('result',result)
                                notify(result.data.message.errorInfo !== undefined ? result.data.message.errorInfo.message !== undefined && result.data.message.errorInfo.message.toString() : result.data.message.toString() || 'Something went wrong',true)  

                              }

                              }).catch(err=>{
                                
                                notify(err.toString(), true);
                                if(document.querySelector("#loading-indicator") !== null)
                                document.querySelector("#loading-indicator").style.display = "none";
                                console.log(err.toString())
                              })
      
                            // db.collection('zSystemStudents').doc(userData._uniqueID).update().then(function(){
                            //   notify('Student details edited successfully',false)  
                            //   console.log('documnet successfully inserted')
                            // }).catch(function(error){
                            //   notify(error,true)
                            //   console.log('this is error', error)
                            // })
                           }
                       }
                  )}   >
                     <Row >
                       <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            defaultValue={userData.firstname}
                            innerRef={register({required:true})}
                            name='firstname'
                            placeholder="First Name"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            defaultValue={userData.lastname}
                            innerRef={register}
                            name='lastname'
                            placeholder="Last Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Mobile</label>
                          <Input
                            defaultValue={userData.mobile}
                            innerRef={register({minLength:10,maxLength:10,required:true,})}
                            name='mobile'
                            placeholder="Mobile"
                            type='number'
                            required
                          />
                          {errors.mobile && errors.mobile.type !== 'required' &&
                            <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Valid Mobile No.</span>
                          }
                        </FormGroup>
                      </Col>
                      <Col>
                          <FormGroup>
                          <label>Enrollment number</label>
                          <Input
                            defaultValue={userData.enrollmentNO}
                            innerRef={register({ required: false })}
                            name='enrollment'
                            placeholder="Enrollment number"
                            type='text'
                          />
                          </FormGroup>
                      </Col>
                      
                      
                    </Row>
                    <Row>
                      
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Status</label>
                          <Input
                            defaultValue={userData.status}
                            
                            innerRef={register({required:true,})}
                            name='status'
                            placeholder="Status"
                            type="select"
                            required
                          >
                            <option>Active</option>
                            <option>Inactive</option>

                          </Input>
                        </FormGroup>
                      </Col> 
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Secondary Email</label>
                          <Input
                            innerRef={register({ required: false })}
                            name='secondaryEmail'
                            placeholder="Email"
                            type="email"
                            defaultValue = {userData.secondaryEmail}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                          <label>Shift</label>
                          <Input
                            defaultValue={userData.shift ? userData.shift : ""}
                            innerRef={register({required:true,})}
                            name='shift'
                            type="select"
                            required
                          > 
                            <option value="">Select Shift</option>
                            <option value="morningShift">Morning Shift</option>
                            <option value="afternoonShift">Afternoon Shift</option>
                            <option value="eveningShift">Evening Shift</option>
                          </Input>
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6" style={{paddingLeft: "110px", paddingTop: 36}}>
                      <div >
                        <Input 
                          id='sendEmail'
                          name="sendEmail"
                          innerRef={register({required:false,})}
                          type="checkbox"
                          style={{marginRight:"10px"}}
                        />
                        <label htmlFor='sendEmail' style={{fontSize: "15px", color: "rgba(255,255,255,0.6)"}}>Send Invitation mail</label>
                      </div>
                    </Col>
                  </Row>
                    <div style={{display:'flex' , flexDirection:'row'}}>
                       
                    {/* {visible===false ?<Button className="btn-fill" color="info" type="submit">
                    Submit
                  </Button> : <Button className="btn-fill" color="info" onClick={onClick}>
                    Done
                  </Button>}
                  {visible===false&& <Button className="btn-fill"  onClick={onClick}>
                    Cancel
                  </Button>} */}

                  {/* <Button color='info' type="submit"> 
                 <NavLink to={{
            pathname:'/admin/student',
            
          }}
          
          style={{color:'white'}}> Update </NavLink>
                  </Button> */}
             <Button color='info' type="submit" style={{marginRight:5}}>Update</Button>
                  <Button  onClick={onClick}>Back</Button>
                    </div>
                  </Form>
                </CardBody>
               
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }


export default Edit_Student;
