/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import {useFormik} from 'formik';
import * as Yup from 'yup'

const validationSchema= Yup.object().shape({
    name:Yup.string().required().label("Name"),
    email:Yup.string().email().required().label('Email'),
    phone:Yup.string().label('Phone').required().min(10,'Phone number should be of 10 charcter'),
    age:Yup.string().required().label("Age")
   })
 function  MyUserProfile(){

    const formik = useFormik({
        initialValues:{
            name:'',
            email:'',
            phone:'',
            age:''
        },
        validationSchema:validationSchema,
        onSubmit:values => {
            console.log(values)
        }
    })
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      {/* <Col className="pr-md-1" md="5">
                         <FormGroup>
                          <label>Company (disabled)</label>
                          <Input
                            defaultValue="Creative Code Inc."
                            disabled
                            placeholder="Company"
                            type="text"
                          />
                        </FormGroup> 
                      </Col> */}
                      <Col className="px-md-1" md="3">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            
                            placeholder="Username"
                            type="text"
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange} 
                          />
                          {formik.errors.name&&formik.touched.name&&<p style={{color:'red'}}>{formik.errors.name}</p>}
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input placeholder="mike@email.com" type="email"
                           name="email"
                           value={formik.values.email}
                           
                           onChange={formik.handleChange} 
                           />
                           {formik.errors.email&&formik.touched.email&&<p style={{color:'red'}}>{formik.errors.email}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Mobile NO.</label>
                          <Input
                            
                            placeholder="Mobile Number"
                            type="text"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange} 
                          />
                          {formik.errors.phone&&formik.touched.phone&&<p style={{color:'red'}}>{formik.errors.phone}</p>}
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Age</label>
                          <Input
                            placeholder='Enter your age'
                            name="age"
                            type="text"
                            value={formik.values.age}
                            onChange={formik.handleChange} 
                          />
                          {formik.errors.age&&formik.touched.age&&<p style={{color:'red'}}>{formik.errors.age}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input
                            defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>City</label>
                          <Input
                            defaultValue="Mike"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>Country</label>
                          <Input
                            defaultValue="Andrew"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Postal Code</label>
                          <Input placeholder="ZIP Code" type="number" />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col md="8">
                        <FormGroup>
                          <label>About Me</label>
                          <Input
                            cols="80"
                            defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                            placeholder="Here can be your description"
                            rows="4"
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <CardFooter>
                    <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                    </CardFooter>
                  </Form>
                </CardBody>
                {/* <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </CardFooter> */}
                
              </Card>
            </Col>
            {/* <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={require("assets/img/emilyz.jpg")}
                      />
                      <h5 className="title">Mike Andrew</h5>
                    </a>
                    <p className="description">Ceo/Co-Founder</p>
                  </div>
                  <div className="card-description">
                    Do not be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="google">
                      <i className="fab fa-google-plus" />
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col> */}
          </Row>
        </div>
      </>
    );
    }


export default MyUserProfile;
