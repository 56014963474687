import React, { useEffect, useState, useRef } from 'react'
import NotificationAlert from "react-notification-alert";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    Progress,
    Input,
} from "reactstrap";
import {format} from "date-fns";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';

function FeedbackTable() {
    
    const [limit,setLimit] = useState(10);
    const notification = useRef();
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)
    const [enquiryData, setEnquiryData] = useState([])
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(0)

    const notify = (msg, err) => {
        let color = err === true ? "danger" : "success"
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
                    </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }

    const getFeedback = () => {
        setProgressValue(99)
        document.querySelector("#loading-indicator").style.display = "flex";
        db.collection('feedback').orderBy('createdOn', 'desc').limit(limit).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))

            data = data.map(item => {
                const time = new Date(item.createdOn.seconds * 1000);
                return({
                    id: item.id,
                    name: item.StudentName,
                    rating: item.emoji,
                    feedback: item.data,
                    createdOn: time,
                    createdActual:item.createdOn,
                    email: item.email,
                    type: item.type
                })
            })
            setProgressVisible(false)
            setEnquiryData(data)
            setEndIndex(data.length);
            document.querySelector("#loading-indicator").style.display = "none";
        })
    }

    const nextSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";
        db.collection("feedback")
          .orderBy("createdOn", "desc")
          .startAfter(enquiryData[enquiryData.length - 1].createdActual)
          .limit(limit)
          .get()
          .then((querySnapshot) => {
                let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
               data = data.map(item => {
                const time = new Date(item.createdOn.seconds * 1000);
                return({
                    id: item.id,
                    name: item.StudentName,
                    rating: item.emoji,
                    feedback: item.data,
                    createdOn: time,
                    createdActual:item.createdOn,
                    email: item.email,
                    type: item.type
                    })
                }
            );
              
            if (data.length !== 0) {
              document.querySelector("#loading-indicator").style.display = "none";
              setEnquiryData(data);
              setStartIndex(endIndex + 1);
              setEndIndex((prev) => prev + data.length);
            } else {
              document.querySelector("#loading-indicator").style.display = "none";
              notify("End Of the list", true);
            }
          })
          .catch((err) => {
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString());
          });
      };
    
      const prevSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";
    
        db.collection("feedback")
          .orderBy("createdOn")
          .startAfter(enquiryData[0].createdActual)
          .limit(limit)
          .get()
          .then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            data = data.map(item => {
             const time = new Date(item.createdOn.seconds * 1000);
             return({
                 id: item.id,
                 name: item.StudentName,
                 rating: item.emoji,
                 feedback: item.data,
                 createdOn: time,
                 createdActual:item.createdOn,
                 email: item.email,
                 type: item.type
                 })
             }
            )
            if (data.length !== 0) {
              document.querySelector("#loading-indicator").style.display = "none";
    
              data = data.reverse();
              setStartIndex((prev) => prev - data.length);
              setEndIndex((prev) => prev - enquiryData.length);
              setEnquiryData(data);
            } else {
              document.querySelector("#loading-indicator").style.display = "none";
            }
          })
          .catch((err) => {
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString());
          });
      };

    useEffect(() => {
        getFeedback()
    }, [limit])

    return (
        <>
            {progressVisible ? (
                <>
                    <Progress color='info' value={progressValue} style={{ height: 4 }} />
                    <div className="content">
                    </div>
                </>
            ) : (
                <>
                    <div className="content">
                        <div className="react-notification-alert-container">
                            <NotificationAlert ref={notification} />
                        </div>

                        <Row>
                            <Col md="12">
                                <Card >
                                    <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                        <CardTitle tag="h4" style={{ marginRight: 'auto', marginLeft: 'auto', fontWeight: 300, color:'#fff' }}>FEEDBACK LIST</CardTitle>
                                        {/* <Button color='info' onClick={() => setIsModalVisible(true)}>Create User <Add /></Button> */}
                                    </CardHeader>
                                    <CardBody>
                                        <Row style={{ justifyContent: 'flex-end', width: '100%', paddingBottom: '8px' }}>                                            
                                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                                            <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                                                {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                                            </p>
                                            <div style={{ position: 'relative', top: '-5px', marginRight: '5px' }}>
                                            {
                                                startIndex > 1 &&
                                                <IconButton style={{ outline: 'none' }}
                                                onClick={() => {
                                                    prevSet()
                                                }}
                                                >
                                                <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                                    
                                                />
                                                </IconButton>

                                            }
                                                <IconButton style={{ outline: 'none' }}
                                                onClick={() => nextSet()}
                                                >
                                                <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                                                </IconButton>
                                            </div>
                                            
                                            <Input
                                                style={{maxWidth:67, marginRight:10,}}
                                                type="select"
                                                value={limit}
                                                onChange={(e)=>setLimit(parseInt(e.target.value))}
                                                // required
                                            >
                                                <option value='10'>10</option>
                                                <option value='50'>50</option>
                                                <option value='100'>100</option>
                                            </Input>
                                            </Col>

                                        </Row>
                                        <Table className="tablesorter" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Student Name</th>
                                                    <th style={{textAlign: "center"}}>Email</th>
                                                    <th style={{textAlign: "center"}}>Type</th>
                                                    <th style={{textAlign: "center"}}>Submitted At</th>
                                                    <th style={{textAlign: "center"}}>Rating</th>
                                                    <th style={{textAlign: "center"}}>Feedback</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {enquiryData.map(item => {
                                                    console.log("object of data:", item);
                                                    return (
                                                        <tr key={item.id}>
                                                            <td style={{ minWidth:110 }}>{item.name}</td>
                                                            <td style={{textAlign: "center"}}>{item.email}</td>
                                                            <td style={{textAlign: "center"}}>{item.type}</td>
                                                            <td style={{ minWidth:110, textAlign:"center" }}>{item.createdOn ? format(new Date(item.createdOn), "dd-MM-yy hh:mm aa") : 'N/A'}</td>
                                                            <td style={{textAlign: "center"}}>{item.rating}</td>
                                                            <td style={{textAlign: "center"}}>{item.feedback}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </>
    )
}

export default FeedbackTable;