import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { db } from '../db/config'
import * as firebase from 'firebase';


function Delete_User_Modal({ uniqueId, onClick, myUser, setUserData, notify }) {
    
    // console.log('my status is ', myUser)
    
    const [visible, setVisible] = useState(false)
    const functions = firebase.functions();
    
    // const [status, setStatus] = useState(myUser.disabled)
    let mystatus = myUser.disabled === false ? 'disable' : 'enable'
    


    const deleteRecord = () => {
        // let mystatus = myUser.disabled==='enable'?'disable':'enable'
        let status= myUser.disabled;
        // let finalStatus
        setVisible(true)
        if(status===true) {
            status=false;
        }
        else if(status===false) 
        {
            status=true;
        }
        console.log(status)
        // console.log("my unique id",myUser)
        const updatedData = {
            firstname: myUser.firstname,
            lastname: myUser.lastname,
            mobile: myUser.mobile,
            email: myUser.email,
            disabled: status,
            permissions: myUser.permissions,
            password: myUser.password,
            username: myUser.username,
        }
        const updateUserData={userID:myUser._uniqueID,disabled:status}
        
        const updateUser = functions.httpsCallable('updateUser');
        updateUser(updateUserData).then(result => {
            console.log(result)
            if(result.data.hasError === false){
 
                const _uniqueID = result.data.user.uid;
                // const finalData = { ...userData, _uniqueID }
                db.collection("zSystemUsers").doc(_uniqueID).update({
                    disabled: status
                }).then(() => {
                    setUserData(prev => prev.map((item) => item._uniqueID == myUser._uniqueID ? ({ ...updatedData, _uniqueID: myUser._uniqueID }) : item))
                    notify(`User is now ${mystatus}d `,false)
                    onClick()
                    console.log(status)
        
                    //  console.log('status is ',mystatus)
                }).catch((error) => {
                    notify(error.toString(),true)
        
                    console.log('this is error ', error)
                })
            }else{
                notify(result.data.message.errorInfo.message.toString(),true)
    
            }
     

        }).catch(error=>{
            
            notify(error.toString(),true)
    
            console.log('this is error ', error)
        })

       
    }
    return (<div>
        <p >Do you want to {mystatus} this user</p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button color='info' onClick={deleteRecord}>Yes</Button>
            <Button onClick={onClick}>No</Button>

            {/* {visible===false ?<Button color='info' onClick={deleteRecord}>Yes</Button>:<Button color='info' onClick={onClick}>Done</Button> } */}
            {/* {visible===false&&<Button  onClick={onClick}>No</Button>} */}
        </div>
    </div>
    )
}

export default Delete_User_Modal;