import React, { useState, useRef, useEffect } from "react";
import { ModalBody, Modal, Row, Col, Input } from 'reactstrap'
import { Edit, Delete, Money, BookmarkBorderSharp, } from "@material-ui/icons";
import { db } from "MY_LOGIN_AND_SIGNUP/db/config";

import NotificationAlert from "react-notification-alert";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';


import Book_Edit_Step1 from './Book_Add_Step/Book_Edit_Step1'
import Book_Delete_Step1 from './Book_Add_Step/Book_Delete_Step1'

function Book_List({ setVisible, setProgressVisible, setProgressValue, }) {

  const [editVisible, setEditVisible] = useState(false)
  const [bookData, setBoookData] = useState([])
  const [editBook, setEditBook] = useState()
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const [startIndex, setStartIndex] = useState(1)
  const [endIndex, setEndIndex] = useState(0)

  const [loading, setLoading] = useState(false);

  const [limit,setLimit] = useState(10);

  const notifi = useRef();

  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? 'success' : 'danger'
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6
    };
    if(notifi.current){
      notifi.current.notificationAlert(options)
    }
    // notification(options);
  }


  useEffect(() => {
    document.querySelector("#loading-indicator").style.display = "flex";
    setLoading(true);
    setProgressValue(99)
    db.collection('zSystemStore')
      .where('type', '==', 'book').orderBy('createdAt','desc').limit(limit).get()
      .then(function (querySnapshot) {
        let books = querySnapshot.docs.map(doc=>doc.data())
        // console.log("should go", books[2].title)
        setBoookData(books)
        setProgressVisible(false)
        setEndIndex(books.length)
        document.querySelector("#loading-indicator").style.display = "none";
        setLoading(false);
      })
    // .then(querySnapshot=>{

    //   let data = querySnapshot.docs.map(doc=>doc.data())
    //   console.log('my api data',data)
    //   setBoookData(data)
    //   setProgressVisible(false)
    //   console.log('Book data',bookData)
    // })
  }, [limit])



  const nextSet = () => {
    // console.log('next bookData[2].title', bookData[2].createdAt)
    // if (bookData.length === limit) {
      // setClicked(true)
      if(bookData.length === 0){
        notify('End Of the list', true)

        return
      }
      document.querySelector("#loading-indicator").style.display = "flex";

      db.collection('zSystemStore')
        .where('type', '==', 'book')
        .orderBy('createdAt','desc').startAfter(bookData[bookData.length-1].createdAt).limit(limit).get()
        .then(querySnapshot => {
          let data = querySnapshot.docs.map(doc => {

            return doc.data()
          })
          console.log('dataaaa', data)
          if (data.length !== 0) {
            // console.log("should go", data[2].title)
            document.querySelector("#loading-indicator").style.display = "none";

            setBoookData(data)
            setStartIndex(endIndex + 1)
            setEndIndex(prev => prev + data.length)
            // console.log('my user data is ', data)

          } else {
           document.querySelector("#loading-indicator").style.display = "none";

            notify('End Of the list', true)
          }

        }).catch(err=>{
                                
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
          console.log(err.toString())
        })
    // } else {
    //   notify('End Of the list', true)
    // }
  }

  const prevSet = () => {
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection('zSystemStore')
      .where('type', '==', 'book')
      .orderBy('createdAt')
      .startAfter(bookData[0].createdAt).limit(limit).get()
      .then(querySnapshot => {
        let data = querySnapshot.docs.map(doc => {

          return doc.data()
        })
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";
    
          data = data.reverse()

          setStartIndex(prev => prev - data.length - 1)
          setEndIndex(prev => prev - bookData.length)
          setBoookData(data)
          console.log('my user data is ', data)
        } else {
          document.querySelector("#loading-indicator").style.display = "none";


          console.log('Can not go back')
        }
      }).catch(err=>{
                                
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })
  }


  return (
    <Col>
      <Row>
        {/* <div className="content" style={{ width: '100%' }}> */}
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>
        {editVisible === true &&
          <Book_Edit_Step1
            setBoookData={setBoookData}
          booknotify={notify}
          
            editBook={editBook !== undefined && editBook}
            setVisible={setVisible}
            onClick={() => {
              setEditVisible(false)
            }} />}

        {editVisible === false &&

          <Row style={{ justifyContent: 'flex-end', width: '100%', paddingBottom: '8px' }}>

            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
              <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                {startIndex <= 1 ? 1 : startIndex} - {endIndex}
              </p>
              <div style={{ position: 'relative', top: '-5px', marginRight: '5px' }}>
              {
                startIndex > 1 &&
                <IconButton style={{ outline: 'none' }}
                  onClick={() => {

                    prevSet()
                  }}
                >
                  <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                    
                  />
                </IconButton>

              }
                <IconButton style={{ outline: 'none' }}
                  onClick={() => nextSet()}
                >
                  <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                </IconButton>
              </div>
              
              <Input
                style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                type="select"
                value={limit}
                onChange={(e)=>setLimit(parseInt(e.target.value))}
                // required
              >
                <option value='10'>10</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </Input>
            </Col>

          </Row>
        }

          <Modal isOpen={deleteModalVisible} style={{ width: 230 }}>
            <ModalBody>
              <Book_Delete_Step1
                deletedBook={editBook !== undefined && editBook}
                // setVisible={setVisible}
                setBoookData={setBoookData}
              notify={notify}
              setEndIndex = {setEndIndex}
                onClick={() => {
                  setDeleteModalVisible(false)
                }} />
            </ModalBody>
          </Modal>

        <Row style={{ margin: 0, width: '100%' }}>
          { !loading && bookData.length == 0 && 
          <Col sm={3} style={{ paddingBottom: '25px', minWidth: '100%', color: "white" }}>
            <div style={{textAlign: "center", marginRight: 185}}>No Data Found</div>
          </Col>
          }
          {bookData !== undefined && editVisible === false && bookData.map(book => (
            <Col sm={3} style={{ paddingBottom: '25px', minWidth: '210px', maxWidth: '250px' }}>
              <div style={{ overflow: 'hidden', margin: 0, border: '1px solid #2184f8', borderRadius: 5 }}>
                {book.bookcover ? 
                  <img src={book.bookcover} alt='bookimage' style={{ width: '100%', height: '200px', objectFit: 'contain', borderRadius: 0, cursor: 'pointer' }} onClick={() => {
                    
                    setEditVisible(true)
                    setDeleteModalVisible(false)
                    setEditBook(book)
                    setVisible(true)
                  }} />
                  :
                  <img src={require('../assets/img/book.png')} alt='....' 
                    style={{ width: '100%', height: '200px', objectFit: 'contain', borderRadius: 0, cursor: 'pointer' }} onClick={() => {
  
                      setEditVisible(true)
                      setDeleteModalVisible(false)
                      setEditBook(book)
                      setVisible(true)
                    }} 
                  />
                }
                
                <div style={{ display: 'flex', flexDirection: "row", height: 40, padding: 3, justifyContent: 'space-around' }}>
                  <div style={{ width: 120, height: 40, paddingLeft: 3 }}>
                    <div style={{ height: 20, display: 'flex', alignItems: 'center' }}> <p style={{ fontSize: 10, maxLines: 1 }}>{book.title}</p></div>
                    <div style={{ height: 20, display: 'flex', alignItems: 'center' }}> <p style={{ fontSize: 10, }}>{book.author}</p></div>
                  </div>

                  <div style={{ minWidth: '20%', alignItems: "center", justifyContent: 'center', height: 40 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 20 }}>
                      <Edit style={{ fontSize: 18, cursor: 'pointer', color: '#247cf7' }}
                        onClick={() => {

                          setEditVisible(true)
                          //  setDeleteVisible(false)
                          setDeleteModalVisible(false)
                          setEditBook(book)
                          setVisible(true)
                        }}
                      />

                      <Delete style={{ color: '#ff4d4d', fontSize: 18, cursor: 'pointer' }}
                        onClick={() => {
                          setEditVisible(false)
                          // setDeleteVisible(true)
                          setDeleteModalVisible(true)
                          setEditBook(book)
                          // setVisible(true)
                        }}
                      />    
                    </div>


                    <p style={{ fontSize: 11, textAlign: 'center', height: 20 }}><i className="fas fa-rupee-sign" style={{ fontSize: 9, marginRight: 3 }}></i>{book.price}</p>

                  </div>
                </div>
              </div>
            </Col>
          ))}
          {/* </div> */}
        </Row>
        {/* </div> */}
      </Row>
    </Col>
  );
}
export default Book_List;


