/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState ,useRef,useEffect} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import MyUserProfile from "myNewFolder/MYFromWork/UserProfile";
import SignIn from "MY_LOGIN_AND_SIGNUP/check";
import Students_Table from './STUDENT_MODULE/student_info_table'
import Edit_Student from './STUDENT_MODULE/Student_Edit._Form'
import Book_Add_Step1 from "Book_Management_Module/Book_Add_Step/Book_Add_Step1";
import Book_Add_Page from "Book_Management_Module/Book_Add_page"
import GetUser from './Utilities/GetUser'
// import { StateMachineProvider, createStore,useStateMachine,GlobalState } from "little-state-machine";
import NotificationAlert from "react-notification-alert";


// createStore({
//   notify: () => console.log("sdfsdf")
// });

// const updateNotify = (state, payload) =>{
//   return {
//     notify: state
//   };
// }
// const hist = createBrowserHistory();
// const notifi = useRef();

//   const notify = (msg, err) => {
//     // console.log('notify')
//     let color = err == false ? 'success' : 'danger'
//     let options = {
//       type: color,
//       place: "tr",
//       message: (
//         <div>
//           <div>
//             <b>{err == false ? 'Sucess' : 'Error'}</b> -{msg}.
//           </div>
//         </div>
//       ),
//       icon: "tim-icons icon-bell-55",
//       autoDismiss: 6
//     };

//     notifi.current.notificationAlert(options)
//     // notification(options);
//   }
 
const App = () => {
  
  // const { state } = useStateMachine();

  // console.log(',,,,,,',state)
  const hist = createBrowserHistory();
  const notifi = useRef();

  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? 'success' : 'danger'
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? 'Sucess' : 'Error'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6
    };
    if(notifi.current){

      notifi.current.notificationAlert(options)
    }
    // notification(options);
  }
  useEffect(()=>{
    // actions.updateNotify(notify)
  },[])
  //hhh
 

  return (
    // <StateMachineProvider>
    <>
      <audio id="messageIn">
        <source src={require('./assets/audio/msgIn.ogg')} type="audio/ogg" />
        <source src={require('./assets/audio/msgIn.mp3')} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      <audio id="messageOut">
        <source src={require('./assets/audio/msgOut.ogg')} type="audio/ogg" />
        <source src={require('./assets/audio/msgOut.mp3')} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    <Router history={hist}>
      <Switch>
      <Route path="/check" render={(props)=> <GetUser {...props}></GetUser>}/>

        <Route path="/login" render={(props)=> <SignIn {...props}></SignIn>}/>
        <Route path='/book-step-1' render={(props)=><Book_Add_Step1 {...props}></Book_Add_Step1>} /> 
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/rtl" render={props => <RTLLayout {...props} />} />
        <Redirect from="/" to="/check" /> 
        {/* <Redirect from="/" to="/admin"/> */}
      </Switch>
    </Router> 
    <div id="loading-indicator" style={{width:"100vw", height:"100vh", position:"absolute", background:"#eef1f8", zIndex:99999,opacity:0.6, top:0, left:0, display:"none", justifyContent:"center", alignItems:"center"}}>
      <img src={require("./assets/img/loading.gif")} width="100px"></img>
    </div>
    </>
    // <div className="react-notification-alert-container">
    //   <NotificationAlert ref={notifi} />
    // </div>
    
    // </StateMachineProvider>
  )
}

ReactDOM.render (
  <App/>
  ,document.getElementById("root")
);