import React,{useState} from 'react'
import {
    Button,
    Card,
    Input,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    Table,
    ButtonGroup,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

import Chat from './Chat'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ChatDialog() {

    const [open, setOpen] = useState(true);


    const handleClickOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (

        <div className="content">
            {/* <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div> */}
            <Dialog  fullScreen open={open} style={{overflow:'hidden'}} onClose={handleClose} TransitionComponent={Transition}>

            <Chat  handleClose={handleClose}/>
            </Dialog>

            <Row>
                <Col md="12">
                    <Card >
                        <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <CardTitle tag="h4"  style={{marginLeft:'auto',marginRight:'auto'}}>Chat</CardTitle>
                            <Button color='info' onClick={handleClickOpen}>Chat</Button>
                        </CardHeader>
                        <CardBody></CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ChatDialog
