import React, { useState, useEffect, useRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Table,
    Col,
    Progress,
    CustomInput,
} from "reactstrap";
import Switch from '@material-ui/core/Switch'
import { useForm } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import format from "date-fns/format";
import NotificationAlert from "react-notification-alert";

import firebase from 'firebase';
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes';

function PackageDialog({ handleClose,notifi, setPackageData, currentPackage }) {

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#272a3d' : 'black',
            padding: 10,
        }),
        control: () => ({
      
            display: 'none'
        }),
      
    }

    const notification = useRef();

    const { handleSubmit, register, errors, reset } = useForm();
    const [bookData, setBookData] = useState([])
    const [quizData, setQuizData] = useState([])
    const [selectedBook, setSelectedBook] = useState({})
    const [selectedQuiz, setSelectedQuiz] = useState({})
    const [selectedQuizCount, setSelectedQuizCount] = useState([])
    const [selectedBookCount, setSelectedBookCount] = useState([])
    const [noOfQuestions, setNoOfQuestions] = useState('')
    const [price, setPrice] = useState('')
    const [rating, setRating] = useState('')
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8);
    const [withClasses, setWithClasses] = useState(false);
    const [packageCover, setPackageCover] = useState();    
    const [imageUrl, setimageUrl] = useState('');
    const [packageImage, setPackageImage] = useState();
    const [onlyQuery, setOnlyQuery] = useState(false)
    const [shiftsSelected, setShiftsSelected] = useState([])

    // console.log('selectedBook',selectedBook)
    // console.log('selectedQuiz',selectedQuiz)

    const notify = (msg, err) => {
        let color = err === true ? 'danger' : 'success'
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
              </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notifi.current){
            notification.current.notificationAlert(options)
        }
    }
    let bookCount = Object.entries(selectedBook).map(([k, v], i) => {
        //    let a= {item[i],item[i+1}

        // console.log(k,v,i)
        return { id: k, value: v }
    }
    )
    let quizCount = Object.entries(selectedQuiz).map(([k, v], i) => {
        //    let a= {item[i],item[i+1}

        // console.log(k,v,i)
        return { id: k, value: v }
    }
    )
    useEffect(()=>{
        if(currentPackage){
            setWithClasses(currentPackage.withClasses ? currentPackage.withClasses : false)
            setOnlyQuery(currentPackage.onlyForQuery ? currentPackage.onlyForQuery : false)
            setShiftsSelected(currentPackage.shiftsSelected ? currentPackage.shiftsSelected : [])
            setNoOfQuestions(currentPackage.numberOfQuestions ? currentPackage.numberOfQuestions : '')
        }
    },[currentPackage])

    useEffect(() => {
        bookCount = bookCount.filter((item) => (item.value === true))
        setSelectedBookCount(bookCount)

        console.log('selected COunt', bookCount)
    }, [selectedBook])

    useEffect(() => {
        quizCount = quizCount.filter((item) => (item.value === true))
        setSelectedQuizCount(quizCount)

        console.log('selected COunt', quizCount)
    }, [selectedQuiz])

    const getBookData = (needfilter) => {

        db.collection('zSystemStore').where('type', '==', 'book').get().then(querySnapshot => {
            let book = querySnapshot.docs.map((doc) => doc.data())
            // console.log('All data',data)
            // let book =data.filter(item=>item.type==='book')
            // let quiz =data.filter(item=>item.type==='quiz')
            if (needfilter) {
                console.log('inside edit get book', needfilter)

            }
            setBookData(book)

            // setQuizData(quiz)
        })
    }
    const getQuizData = (needfilter) => {

        db.collection('zSystemStore').where('type', '==', 'quiz').get().then(querySnapshot => {
            let quiz = querySnapshot.docs.map((doc) => doc.data())
            // console.log('All data',data)
            // let book =data.filter(item=>item.type==='book')
            // let quiz =data.filter(item=>item.type==='quiz')
            if (needfilter) {
                console.log('inside edit get quiz', needfilter)

            }
            setProgressVisible(false)

            // setBookData(book)
            setQuizData(quiz)
        })
    }

    const getBooks = () => {
        // let currentbookArray = currentPackage.book
        console.log('in get EDit Package book')
        db.collection('zSystemStore').where('type', '==', 'book')
            // .where('__name__', 'in', currentbookArray)
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data() }
                })
                let bookArray = {};
                currentPackage.book.forEach((item, i) => {
                    console.log(item)
                    bookArray[item] = true
                })

                console.log('current bookArray Array', bookArray)
                setSelectedBook(bookArray)
                // setQuestionsData(data)
                // setEndIndex(data.length)
                setBookData(data)
                // getBookData(data)
                console.log('my user data is ', data)
            }).catch(err => {
                notify(err.toString(), true)

                console.log('err', err)

            })
    }

    const getQuiz = () => {
        console.log('in get EDit Package book')
        db.collection('zSystemStore').where('type', '==', 'quiz')
            // .where('__name__', 'in', currentQuizArray)
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data() }
                })
                console.log('current quiz data data', data)

                let quizArray = {};
                currentPackage.quiz.forEach((item, i) => {
                    console.log(item)
                    quizArray[item] = true
                })
                setProgressVisible(false)

                console.log('current quizArray Array', quizArray)
                setSelectedQuiz(quizArray)
                // setQuestionsData(data)
                // setEndIndex(data.length)
                setQuizData(data)
                // getQuizData()
                console.log('my user data is ', data)
            }).catch(err => {
                notify(err.toString(), true)

                console.log('err', err)

            })
    }

    useEffect(() => {
        setProgressValue(99)
  
        console.log('currentPackage', currentPackage)
        if (currentPackage !== '') {
            // if (currentPackage.numberOfBook !== 0) {

                getBooks()

            // }
            // if (currentPackage.numberOfQuiz !== 0) {

                getQuiz()

            // }

        } else {
            getBookData()
            getQuizData()
        }

    }, [])

    const selectPackageCover = (event) => {
        console.log(event.target.files[0])
        console.log("my image name", event.target.files[0].name)
        if (!event.target.files[0]) {
          return
        }
        if(event.target.files[0].type=== 'image/jpeg' ||event.target.files[0].type=== 'image/png' ){
    
          try {
            let date = new Date().valueOf();
    
            var uploadTask = firebase.storage().ref().child(`packageCover/${date}${event.target.files[0].name}`).put(event.target.files[0])
            uploadTask.on('state_changed', function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log('upload is paused')
                  break;
                case firebase.storage.TaskState.RUNNING:
                  console.log('upload is running')
                  break
              }
            }, function (error) {
              console.log('this is error', error)
            }, function () {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                notify('Book Cover Uploaded Successfully', false)
      
                console.log('File available at ', downloadURL)
                setimageUrl(downloadURL)
                setPackageImage(downloadURL)
              }).catch(err => {
                notify(err.toString(), true)
      
              })
            })
      
          } catch (error) {
            notify(error.toString(), true)
      
            console.log('my error', error)
          }
          setPackageCover(event.target.files[0].name)
        }else {
          notify('Please select only .jpeg/.png file', true)
    
        }
    }

    const onSubmit = (data) => {
        console.log('onsubmit', data)
        // console.log('bookCount', bookCount)
        // console.log('quizCount', quizCount)
        let finalSelectedQuiz = quizCount.lenght !== 0 ? quizCount.map(item => item.id) : []
        let finalSelectedBook = bookCount.lenght !== 0 ? bookCount.map(item => item.id) : []
        console.log('finalSelectedQuiz', finalSelectedQuiz)
        console.log('finalSelectedBook', finalSelectedBook)

        let questions = noOfQuestions === '' ? 0 : noOfQuestions
        let res;
        
        console.log("type of",typeof(data.rating) );
        if (currentPackage) {

            res = db.collection('zSystemStore').doc(currentPackage.itemID)

        } else {

            res = db.collection('zSystemStore').doc()
        }
        let finalData = {}

        if (currentPackage) {
            finalData = {
                itemID: res.id,
                type: 'package',
                title: data.packageTitle,
                price: parseFloat(data.price),
                quiz: finalSelectedQuiz,
                numberOfQuiz: quizCount.length,
                book: finalSelectedBook,
                numberOfBook: bookCount.length,
                packageExpiry: data.packageExpiry,
                numberOfQuestions: parseFloat(questions),
                packageCover: imageUrl,
                rating: parseFloat(data.rating),
                withClasses: withClasses,
                createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                onlyForQuery: onlyQuery,
                shiftsSelected: shiftsSelected

            }

        } else {
            finalData = {
                itemID: res.id,
                type: 'package',
                title: data.packageTitle,
                price: parseFloat(data.price),
                quiz: finalSelectedQuiz,
                numberOfQuiz: quizCount.length,
                book: finalSelectedBook,
                numberOfBook: bookCount.length,
                packageCover: imageUrl,
                packageExpiry: data.packageExpiry,
                numberOfQuestions: parseFloat(questions),
                rating: parseFloat(data.rating),
                lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                withClasses: withClasses,
                onlyForQuery: onlyQuery,
                shiftsSelected: shiftsSelected
            }

        }
         
        res.set(finalData, {merge: true}).then(res => {
            console.log("res", res)
            if (currentPackage) {

                notifi('Package updated Successfully', false)

            } else {

                notify('Package has been submitted', false)
            
            }
            if (currentPackage) {

                setPackageData(prev => prev.map((item) => item.itemID == currentPackage.itemID ? ({ ...finalData }) : item))

            } else {

                setPackageData(prev => [...prev, finalData])

            }

            handleClose()
            console.log('submitted', res)
        }).catch(err => {
            notify(err.toString(), true)
            notifi(err.toString(), true)


        })
        console.log('response', res)
    }

    return (
        <div style={{ backgroundColor: '#1f1e2e', height: '100%', overflowY: 'scroll' }} >
            {progressVisible && <Progress color='info' value={progressValue} style={{ height: 4 }} />}

            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>

            <div style={{ padding: '5px 20px' }}>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <IconButton edge="start" style={{ color: 'white' }} onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                </Row>

                <Row >
                    <Col md="12" style={{ marginBottom: '100px' }}>
                        <Card style={{ margin: 0 }}>
                            <CardHeader>
                                <h5 className="title">Package Management</h5>
                            </CardHeader>
                            <CardBody>

                                <Row>
                                    <Col sm={4} style={{ borderRight: '1px solid #3d4356' }}>
                                        <h5 className="title" style={{ paddingLeft: '20px' }}>Books</h5>
                                        <br></br>

                                        <Table className="tablesorter" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th> </th>
                                                    <th>Book Title</th>
                                                    <th>Author</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bookData.map((item) => {

                                                        return (
                                                            <tr key={item.itemID} >
                                                                <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                                    <Input type="checkbox" checked={selectedBook[item.itemID]} onChange={(e) => {
                                                                        setSelectedBook((prev) => ({ ...prev, [item.itemID]: !prev[item.itemID] }))
                                                                    }} />
                                                                </td>
                                                                <td>{item.title}</td>
                                                                <td>{item.author}</td>
                                                                <td style={{ minWidth: '70px' }}>₹ {item.price}</td>
                                                            </tr>

                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </Table>
                                    </Col>
                                    <Col sm={5} style={{ borderRight: '1px solid #3d4356' }}>
                                        <h5 className="title" style={{ paddingLeft: '20px' }}>Quiz</h5>
                                        <br></br>

                                        <Table className="tablesorter" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th> </th>
                                                    <th>Quiz Title</th>
                                                    <th>No of Questions</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    quizData.map((item) => {

                                                        return (
                                                            <tr key={item.itemID}>
                                                                <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                                    <Input type="checkbox" checked={selectedQuiz[item.itemID]} onChange={(e) => {
                                                                        setSelectedQuiz((prev) => ({ ...prev, [item.itemID]: !prev[item.itemID] }))
                                                                    }} /></td>
                                                                <td>{item.title}</td>
                                                                <td>{item.numberOfQuestions}</td>
                                                                <td style={{ minWidth: '70px' }}>₹ {item.price}</td>
                                                            </tr>

                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </Table>
                                    </Col>

                                    <Col sm={3} >
                                        <h5 className="title">No of Questions</h5>
                                        <br/>

                                        <Input value={noOfQuestions} type='text' placeholder="No Of Questions" 
                                        onChange={(event) => {
                                            const re = /^[0-9\b]+$/;
                                            if (event.target.value === '' || re.test(event.target.value)) {
                                                setNoOfQuestions(event.target.value);
                                            }
                                        }}/>
                                        <br/>
                                        <h5 className="title">Shifts</h5>

                                        <ReactMultiselectCheckboxes
                                            styles={customStyles}
                                            value={shiftsSelected}
                                            getDropdownButtonLabel={
                                            ({placeholderButtonLabel,value})=> {
                                                if (value && value.length !== 0) {
                                                return `${value?.length} selected`;
                                                } else {
                                                return `Select`;
                                                }
                                            }
                                            }
                                            onChange={(option,e) => {
                                                setShiftsSelected(option)
                                            // document.querySelector("#loading-indicator").style.display = "flex";
                                            // batchSelect(e.option.value, e.action, user._uniqueID, option)
                                            }}
                                            options={[
                                                {label: 'Morning', value: 'Morning' },
                                                {label: 'Afternoon', value: 'Afternoon' },
                                                {label: 'Evening', value: 'Evening' },
                                            ]}
                                            />
                                       
                                    </Col>
                                </Row>
                                <br></br>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                {selectedBookCount.length !== 0 || selectedQuizCount.length !== 0 || noOfQuestions.length !== 0 || true
                    ?
                    <Row style={{ position: 'fixed', bottom: '0', minHeight: '120px', display: 'flex', width: '100%', justifyContent: 'flex-end', left: '-10', backgroundColor: '#272a3d' }}>
                        <Form style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',fontSize:'13px' }}
                            onSubmit={handleSubmit(onSubmit)}
                        >

                            <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between',margin:0 }}>
                                <Col sm={10} style={{paddingRight: 0}}>
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col sm={1} style={{ textAlign: 'end', minWidth: '125px' }}>
                                            <label style={{ color: 'white' }}>Package Title</label>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>

                                                <Input
                                                    defaultValue={currentPackage && currentPackage.title}
                                                    innerRef={register({ required: true })}
                                                    name='packageTitle'
                                                    placeholder="Enter Package Title"
                                                    type="text"
                                                // required
                                                />
                                                {errors.packageTitle && errors.packageTitle.type === "required" && notify("Package title field can't be empty", true)}

                                            </FormGroup>

                                        </Col>

                                        <Col sm={2} style={{ textAlign: 'end', maxWidth: '40px', paddingLeft: 0 }}>
                                            <label style={{ color: 'white' }}>Price</label>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>

                                                <Input
                                                    defaultValue={currentPackage ? currentPackage.price : price}
                                                    innerRef={register({ required: true })}
                                                    name='price'
                                                    placeholder="Price"
                                                    type="text"
                                                    onChange={(event) => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (event.target.value === '' || re.test(event.target.value)) {
                                                            setPrice(event.target.value);
                                                        }
                                                    }}
                                                // required
                                                />
                                                {errors.price && errors.price.type === "required" && notify("Price field can't be empty", true)}

                                            </FormGroup>

                                        </Col>
                                        <Col sm={1} style={{ textAlign: 'end', maxWidth:'75px' }}>
                                            <label style={{ color: 'white' }}>Rating</label>
                                        </Col>
                                        <Col sm={2} >
                                            <FormGroup>
                                                <Input
                                                    defaultValue={currentPackage ? currentPackage.rating : rating}
                                                    innerRef={register({ required: true,min:0,max:5 })}
                                                    name='rating'
                                                    placeholder="max 5"
                                                    type="text"
                                                    onChange={(event) => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (event.target.value === '' || re.test(event.target.value)) {
                                                            setRating(event.target.value);
                                                        }
                                                    }}
                                                    // required
                                                />
                                                {errors.rating && errors.rating.type === "required" && notify("Rating field can't be empty",true)}
                                                {errors.rating && errors.rating.type === "max" && notify('Rating should be out of 5',true)}
                
                                            </FormGroup>

                                        </Col>
                                        <Col sm={2} style={{top:3, marginLeft: 25, paddingRight: 0}}>
                                            <FormGroup>
                                                <Input 
                                                    id='onlineClasses'
                                                    type ="checkbox"
                                                    checked={withClasses}
                                                    onChange={(e) => {
                                                        setWithClasses(e.target.checked)
                                                        console.log(e.target.checked)
                                                    }}
                                                />
                                                <label htmlFor='onlineClasses' style={{ color: 'white' }}>Online Classes</label>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm={1} style={{ textAlign: 'end', minWidth:'135px' }}>
                                            <label htmlFor='onlineClasses' style={{ color: 'white' }}>Online Classes</label>
                                        </Col> */}
                                        <Col sm={1} style={{ marginTop: 15}}>
                                            <label htmlFor='uploadCover' style={{ color: 'white' }}> Thumbnail </label>
                                        </Col>
                                        <Col sm={1}>
                                            <FormGroup style={{ display: 'flex' }}>
                                                <label htmlFor='uploadCover' style={{ cursor: 'pointer', color: 'white', fontSize: 16, marginBottom: 0 }}>{packageCover === undefined ? <Button style={{margin: 0}} ><CloudUploadIcon /></Button> : <Button style={{margin: 0}}><CloudDoneIcon /></Button>}</label>
                                                <Input
                                                id='uploadCover'
                                                style={{ cursor: 'pointer' }}
                                                innerRef={register({ required: false, accept: '.jpeg,.png' })}
                                                name='packagecover'
                                                placeholder='Upload'
                                                onChange={selectPackageCover}
                                                type='file'
                                                accept='.jpeg ,.png'
                                                />
                                            </FormGroup>
                                        </Col>
                                        
                                        { packageImage && 
                                            <Col sm={1}>
                                                <img src={packageImage} alt="" style={{ width: 40, height: 55, marginTop: 5, marginLeft:20 }} />
                                            </Col>
                                        }
                                        <Col sm={2} style={{ textAlign: 'end', minWidth: '150px' }}>
                                            <label style={{ color: 'white' }}>Package Expiry</label>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>

                                                <Input
                                                    defaultValue={currentPackage && currentPackage.packageExpiry}
                                                    innerRef={register({ required: true })}
                                                    name='packageExpiry'
                                                    placeholder="Enter Expiry Date"
                                                    type="date"
                                                    min={format(new Date(),"yyyy-MM-dd")}
                                                // required
                                                />
                                                {errors.packageExpiry && errors.packageExpiry.type === "required" && notify("Package Expiry field can't be empty", true)}

                                            </FormGroup>

                                        </Col>
                                        <Col sm={2} style={{top:3, marginLeft: 25, paddingRight: 0}}>
                                            <FormGroup>
                                                <Input 
                                                    id='query'
                                                    type ="checkbox"
                                                    checked={onlyQuery}
                                                    onChange={(e) => {
                                                        setOnlyQuery(e.target.checked)
                                                        console.log(e.target.checked)
                                                    }}
                                                />
                                                <label style={{color:'#fff'}} htmlFor='query'>Only for Query</label>
                                            </FormGroup>
                                            {/* <Col sm={1} style={{ textAlign: 'end', minWidth:'135px' }}> */}
                                            {/* </Col> */}
                                        </Col>
                                       
                                        
                                    </Row>
                                </Col>
                                <Col sm={2}>
                                    <Button color='info' type='submit'>Submit</Button>

                                </Col>
                            </Row>
                        </Form>

                    </Row>
                    :
                    <div></div>
                }
            </div>
        </div >
    )
}
export default PackageDialog