import React, { useEffect, useState, useRef } from 'react'
import NotificationAlert from "react-notification-alert";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    Progress,
    Button,
    ButtonGroup,
    Input
} from "reactstrap";
import {format} from "date-fns";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';

function EnquiryTable() {
    
    const [limit,setLimit] = useState(10);
    const notification = useRef();
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)
    const [enquiryData, setEnquiryData] = useState([])
    const [enquiryDataSpecial, setEnquiryDataSpecial] = useState([])
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(0)
    const [startIndexSpecial, setStartIndexSpecial] = useState(1)
    const [endIndexSpecial, setEndIndexSpecial] = useState(0)
    const [active, setactive] = useState(false)

    const notify = (msg, err) => {
        let color = err === true ? "danger" : "success"
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
                    </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }

    const getEnquiry = () => {
        setProgressValue(99)
        document.querySelector("#loading-indicator").style.display = "flex";
        db.collection('enquiry').orderBy('createdOn', 'desc').limit(limit).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            
            data = data.map(item => {
                const time = item.createdOn.seconds * 1000;

                return ({
                    id: item.id,
                    email: item.email,
                    message: item.message,
                    name: item.name,
                    createdOn: time,
                    createdAt: item.createdOn,
                    phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 
                })

            })
            console.log(data);
            setProgressVisible(false)

            setEnquiryData(data)
            setEndIndex(data.length)
            document.querySelector("#loading-indicator").style.display = "none";
        })
    }

    const nextSet = () => {
        if (enquiryData.length === limit) {
        // setClicked(true)
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('enquiry')
            .orderBy('createdOn','desc').startAfter(enquiryData[enquiryData.length-1].createdAt).limit(limit).get()
            .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

                return doc.data()
            })
            console.log('dataaaa', data)
            if (data.length !== 0) {
                // console.log("should go", data[2].title)
                document.querySelector("#loading-indicator").style.display = "none";
                
                data = data.map(item => {
                    const time = item.createdOn.seconds * 1000;

                    return ({
                        id: item.id,
                        email: item.email,
                        message: item.message,
                        name: item.name,
                        createdOn: time,
                        createdAt: item.createdOn,
                        phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 

                    })

                })
                setEnquiryData(data)
                setStartIndex(endIndex + 1)
                setEndIndex(prev => prev + data.length)
                // console.log('my user data is ', data)

            } else {
            document.querySelector("#loading-indicator").style.display = "none";

                notify('End Of the list', true)
            }

            }).catch(err=>{
                                    
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
            })
        } else {
          notify('End Of the list', true)
        }
    }

    const prevSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('enquiry')
        .orderBy('createdOn')
        .startAfter(enquiryData[0].createdAt).limit(limit).get()
        .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

            return doc.data()
            })
            if (data.length !== 0) {
                document.querySelector("#loading-indicator").style.display = "none";
                data = data.map(item => {
                    const time = item.createdOn.seconds * 1000;

                    return ({
                        id: item.id,
                        email: item.email,
                        message: item.message,
                        name: item.name,
                        createdOn: time,
                        createdAt: item.createdOn,
                        phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 
                })

                })
                data = data.reverse()

                setStartIndex(prev => prev - data.length)
                setEndIndex(prev => prev - enquiryData.length)
                setEnquiryData(data)
                console.log('my user data is ', data)
            } else {
                document.querySelector("#loading-indicator").style.display = "none";
                console.log('Can not go back')
            }
        }).catch(err=>{
                                    
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
        })
    }

    const nextSetSpecial = () => {
        if (enquiryDataSpecial.length === limit) {
        // setClicked(true)
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('enquirySpecial')
            .orderBy('createdOn','desc').startAfter(enquiryDataSpecial[enquiryDataSpecial.length-1].createdAt).limit(limit).get()
            .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

                return doc.data()
            })
            console.log('dataaaa', data)
            if (data.length !== 0) {
                // console.log("should go", data[2].title)
                document.querySelector("#loading-indicator").style.display = "none";
                
                data = data.map(item => {
                    const time = item.createdOn.seconds * 1000;

                    return ({
                        id: item.id,
                        email: item.email,
                        message: item.message,
                        name: item.name,
                        createdOn: time,
                        createdAt: item.createdOn,
                        enquiryFor: item.enquiryFor,
                        phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 

                    })

                })
                setEnquiryDataSpecial(data)
                setStartIndexSpecial(endIndex + 1)
                setEndIndexSpecial(prev => prev + data.length)
                // console.log('my user data is ', data)

            } else {
            document.querySelector("#loading-indicator").style.display = "none";

                notify('End Of the list', true)
            }

            }).catch(err=>{
                                    
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
            })
        } else {
          notify('End Of the list', true)
        }
    }

    const prevSetSpecial = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('enquirySpecial')
        .orderBy('createdOn')
        .startAfter(enquiryDataSpecial[0].createdAt).limit(limit).get()
        .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {

            return doc.data()
            })
            if (data.length !== 0) {
                document.querySelector("#loading-indicator").style.display = "none";
                data = data.map(item => {
                    const time = item.createdOn.seconds * 1000;

                    return ({
                        id: item.id,
                        email: item.email,
                        message: item.message,
                        name: item.name,
                        createdOn: time,
                        enquiryFor: item.enquiryFor,
                        createdAt: item.createdOn,
                        phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 
                })

                })
                data = data.reverse()

                setStartIndexSpecial(prev => prev - data.length)
                setEndIndexSpecial(prev => prev - enquiryData.length)
                setEnquiryDataSpecial(data)
                console.log('my user data is ', data)
            } else {
                document.querySelector("#loading-indicator").style.display = "none";
                console.log('Can not go back')
            }
        }).catch(err=>{
                                    
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
        })
    }

    const getEnquirySpecial = () => {
        setProgressValue(99)
        document.querySelector("#loading-indicator").style.display = "flex";
        db.collection('enquirySpecial').orderBy('createdOn', 'desc').limit(limit).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            
            data = data.map(item => {
                const time = item.createdOn.seconds * 1000

                return ({
                    id: item.id,
                    email: item.email,
                    message: item.message,
                    name: item.name,
                    createdOn: time,
                    enquiryFor: item.enquiryFor,
                    createdAt: item.createdOn,
                    phone:item.phone !== undefined && item.phone !== '' ? item.phone : 'N/A' 
                })

            })
            console.log(data);
            setProgressVisible(false)

            setEnquiryDataSpecial(data)
            setEndIndexSpecial(data.length)
            document.querySelector("#loading-indicator").style.display = "none";
        })
    }
    
    useEffect(() => {
        if(active){
            getEnquiry()
        } else {
            getEnquirySpecial()
        }
    }, [limit])

    return(
        <>
            {progressVisible ? (
                <>
                    <Progress color='info' value={progressValue} style={{ height: 4 }} />
                    <div className="content">
                    </div>
                </>
            ) : (
                <>
                    <div className="content">
                        <div className="react-notification-alert-container">
                            <NotificationAlert ref={notification} />
                        </div>

                        <Row>
                            <Col md="12">
                                <Card >
                                    <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                        <Col >
                                            <ButtonGroup>
                                                <Button
                                                    className={active ? "data" : "btn-simple data"}
                                                    color="info"
                                                    size="sm"
                                                    onClick={() => {
                                                        setactive(prev => !prev)
                                                        console.log(active)
                                                        getEnquiry()

                                                    }}
                                                >

                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        ENQUIRY
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-single-02" />
                                                    </span>
                                                </Button>
                                                <Button
                                                    className={active ? "btn-simple data" : "data"}

                                                    color="info"
                                                    size="sm"
                                                    onClick={() => {
                                                        setactive(prev => !prev)
                                                        console.log(active)
                                                        getEnquirySpecial()
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                        SPECIAL ENQUIRY
                                                    </span>
                                                    <span className="d-block d-sm-none">
                                                        <i className="tim-icons icon-gift-2" />
                                                    </span>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                        </CardHeader>
                                    <CardBody>
                                        <Row style={{ justifyContent: 'flex-end', width: '100%', paddingBottom: '8px' }}>
                                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                                            <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                                                {active ? startIndex <= 1 ? 1 : startIndex :startIndexSpecial <= 1 ? 1 : startIndexSpecial} - {active ? endIndex : endIndexSpecial}
                                            </p>
                                            <div style={{ position: 'relative', top: '-5px', marginRight: '5px' }}>
                                            {active ?
                                                 startIndex > 1 &&
                                                <IconButton style={{ outline: 'none' }}
                                                onClick={() => 
                                                    active ?
                                                    prevSet() :
                                                    prevSetSpecial()
                                                }
                                                >
                                                <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                                    
                                                />
                                                </IconButton>
                                                :
                                                startIndexSpecial > 1 &&
                                                <IconButton style={{ outline: 'none' }}
                                                onClick={() => 
                                                    active ?
                                                    prevSet() :
                                                    prevSetSpecial()
                                                }
                                                >
                                                <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                                    
                                                />
                                                </IconButton>
                                                
                                            }
                                                <IconButton style={{ outline: 'none' }}
                                                onClick={() => active ? nextSet() :nextSetSpecial()}
                                                >
                                                <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                                                </IconButton>
                                            </div>
                                            
                                            <Input
                                                style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                                                type="select"
                                                value={limit}
                                                onChange={(e)=>setLimit(parseInt(e.target.value))}
                                                // required
                                            >
                                                <option value='10'>10</option>
                                                <option value='50'>50</option>
                                                <option value='100'>100</option>
                                            </Input>
                                            </Col>

                                        </Row>
                                            {active ?
                                                <Table className="tablesorter" >
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Student Name</th>
                                                            <th style={{textAlign: "center"}}>Email</th>
                                                            <th style={{textAlign: "center"}}>Mobile No</th>
                                                            <th style={{textAlign: "center"}}>Submitted At</th>
                                                            <th style={{textAlign: "center"}}>Query</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {enquiryData.map(item => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td style={{ minWidth:110 }}>{item.name}</td>
                                                                    <td style={{textAlign: "center"}}>{item.email}</td>
                                                                    <td style={{textAlign: "center"}}>{item.phone}</td>
                                                                    <td style={{ minWidth:110, textAlign: "center" }}>{item.createdOn ? format(new Date(item.createdOn), "dd/MM/yy hh:mm aa") : 'N/A'}</td>
                                                                    <td style={{textAlign: "center"}}>{item.message}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                                :
                                                <Table className="tablesorter" >
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Student Name</th>
                                                            <th style={{textAlign: "center"}}>Email</th>
                                                            <th style={{textAlign: "center"}}>Mobile No</th>
                                                            <th style={{textAlign: "center"}}>Submitted At</th>
                                                            <th style={{textAlign: "center"}}>Package</th>
                                                            <th style={{textAlign: "center"}}>Query</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {enquiryDataSpecial.map(item => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td style={{ minWidth:110 }}>{item.name}</td>
                                                                    <td style={{textAlign: "center"}}>{item.email}</td>
                                                                    <td style={{textAlign: "center"}}>{item.phone}</td>
                                                                    <td style={{ minWidth:110, textAlign: "center" }}>{item.createdOn ? format(new Date(item.createdOn), "dd/MM/yy hh:mm aa") : 'N/A'}</td>
                                                                    <td style={{textAlign: "center"}}>{item.enquiryFor}</td>
                                                                    <td style={{textAlign: "center"}}>{item.message}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </>
    )
}

export default EnquiryTable;