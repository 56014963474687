import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	Table,
	Row,
	Col,
	Modal,
	ModalBody,
	Progress,
} from "reactstrap";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import NotificationAlert from "react-notification-alert";
import MediaDialog from "./MediaDialog";
import Dialog from "@material-ui/core/Dialog";
import {compareAsc, format} from "date-fns";
import { Delete, Edit } from "@material-ui/icons";
import DeleteModal from "./DeleteModal";
import api from '../../Utilities/api';

function MediaCenter() {
	const [progressVisible, setProgressVisible] = useState(true);
	const [progressValue, setProgressValue] = useState(8);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [active, setactive] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [currentData, setCurrentData] = useState('');
  const [fileName, setFileName] = useState(null);

	const notifi = useRef();
	const notify = (msg, err) => {
		// console.log('notify')
		let color = err == false ? "success" : "danger";
		let options = {
			type: color,
			place: "tr",
			message: (
				<div>
					<div>
						<b>{err == false ? "Success" : "Error"}</b> - {msg}.
					</div>
				</div>
			),
			icon: "tim-icons icon-bell-55",
			autoDismiss: 6,
		};
		if (notifi.current) {
			notifi.current.notificationAlert(options);
		}
	};

  const getActiveLink = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('MediaCenter')
      .orderBy('createdAt', 'desc').get().then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => doc.data())
        setMediaData(data)
        setProgressVisible(false);
        document.querySelector("#loading-indicator").style.display = "none";
      })
  }

  const deleteFile = (fileName) => {
    if(!fileName) return;
    let fileData = fileName.split("/");
   
  const data ={
    filename:fileData[1]
  }
  api({ path: '/upload/deleteFile', data: data }).then(res => {
      console.log("Media delete", res?.data?.file)
    
      })
      .catch(err =>{ console.log("Media Error",err)} );
    
    };

  useEffect(() => {
    if(!isModalVisible)
    setProgressValue(99);
    getActiveLink();
	}, [isModalVisible]);

  const deleteMedia = () => {
    deleteFile(fileName);
    db.collection('MediaCenter').doc(currentData).delete().then(res => {
      setMediaData(prev=>prev.filter(item => item.itemID !== currentData))
      setIsDeleteModalVisible(false)
    }).catch(err=>{
      console.log(err)
      setIsDeleteModalVisible(false)
    })
  }
	return (
		<>
			{progressVisible ? (
				<Progress color="info" value={progressValue} style={{ height: 4 }} />
			) : (
				<div className="content">
					<div className="react-notification-alert-container">
						<NotificationAlert ref={notifi} />
					</div>
          <Modal isOpen={isDeleteModalVisible} color='primary' style={{ display: 'flex', flex: 1, top: -40, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
            <ModalBody size='sm'>                
              <DeleteModal setIsDeleteModalVisible={setIsDeleteModalVisible} deleteMedia={deleteMedia}/>
            </ModalBody>
          </Modal>
                        
          <Dialog fullScreen open={isModalVisible} onClose={setIsModalVisible}>
            <MediaDialog notify={notify} currentData={currentData} handleClose={setIsModalVisible} setMediaData={setMediaData}  />
          </Dialog>

					<Row>
						<Col>
							<Card>
								<CardHeader
									style={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									<Col>
										<ButtonGroup>
											<Button
												className={active ? "data" : "btn-simple data"}
												color="info"
												size="sm"
												onClick={() => setactive((prev) => !prev)}
											>
												<span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
													Active
												</span>
												<span className="d-block d-sm-none">
													<i className="tim-icons icon-single-02" />
												</span>
											</Button>
											<Button
												className={active ? "btn-simple data" : "data"}
												color="info"
												size="sm"
												onClick={() => setactive((prev) => !prev)}
											>
												<span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
													History
												</span>
												<span className="d-block d-sm-none">
													<i className="tim-icons icon-single-02" />
												</span>
											</Button>
										</ButtonGroup>
									</Col>
                  {active ? (
                    <Button color="info" onClick={() => (setIsModalVisible(true),setCurrentData(''))}>Add New</Button>
                  ) : null}
								</CardHeader>
                {active ? (
                  <>
                    <CardBody>
                      <Table >
                        <thead className="text-primary">
                          <tr>
                            <th style={{ textAlign: "left", width:"50%" }} >Meeting Name</th>
                            <th style={{ textAlign: "left" }}>
                              Lecture Date
                            </th>
                            <th style={{ textAlign: "left" }}>
                              Expiry Date
                            </th>
                           
                            <th style={{ textAlign: "left" }}>
                              Batches
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {mediaData.length === 0 && (
                            <tr>
                              <td></td>
                              <td></td>
                              <td >
                                No Data Found
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                          {mediaData.map((item) => {
                            
                            let batchdetails = item.batch
                            let result = compareAsc(new Date(item.expiryDate), new Date())
                            return (
                              result === 1 ?
                                <tr key={item.itemID} >
                                  <td>{item.title}</td>
                                  <td style={{ textAlign: "left" }}>{item?.lectureDate && format(new Date(item.lectureDate), "dd/MM/yyyy")}</td>
                                  <td style={{ textAlign: "left" }}>{format(new Date(item.expiryDate), "dd/MM/yy hh:mm aa")}</td>
                                  
                                  <td style={{ textAlign: "left" }}>{batchdetails.map((data, index) => {
                                    return(
                                    <li key={index}>
                                      {data.batchName}
                                    </li>
                                    )
                                  })}</td>
                                  <td style={{ cursor: 'pointer', textAlign: "center" }}>
                                    <Edit style={{color:'#247cf7'}}
                                      onClick={() => {
                                        setCurrentData(item)
                                        setIsModalVisible(true)
                                      }}
                                    />
                                    <Delete onClick={() => {
                                      setCurrentData(item.itemID)
                                      setIsDeleteModalVisible(true)
                                      setFileName(item?.fileUrl)
                                    }}
                                      style={{ color: 'red' }}
                                    />
                                  </td>
                                </tr>
                              :
                                null
                          
                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th style={{ textAlign: "left", width:"50%" }}>Meeting Name</th>
                            <th style={{ textAlign: "left" }}>
                              Lecture date
                            </th>
                            <th style={{ textAlign: "left" }}>
                              Expiry Date
                            </th>
                            
                            <th style={{ textAlign: "left" }}>
                              Batches
                            </th>
                            <th style={{ textAlign: "center"}}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {mediaData.length === 0 && (
                            <tr>
                              <td></td>
                              <td></td>
                              <td >
                                No Data Found
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                          {mediaData.map((item, index) => {
                            let batchdetails = item.batch
                            // console.log("yahi h:",item?.lectureDate);
                            let result = compareAsc(new Date(item.expiryDate), new Date())
                            return (
                              result === -1 ?
                              <>
                                <tr key={index}>
                                  
                                  <td>{item.title}</td>
                                  <td style={{ textAlign: "left" }}>{item?.lectureDate && format(new Date(item.lectureDate), "dd/MM/yyyy")}</td>
                                  <td style={{ textAlign: "left" }}>{format(new Date(item.expiryDate), "dd/MM/yy hh:mm aa")}</td>
                                 
                                  <td style={{ textAlign: "left" }}>{batchdetails.map((data, index) => {
                                    return(
                                    <li key={index}>
                                      {data.batchName}
                                    </li>
                                    )
                                  })}
                                  </td>
                                  <td style={{ textAlign: "center", cursor: "pointer"}}>
                                    <Delete onClick={() => {
                                      setCurrentData(item.itemID)
                                      setIsDeleteModalVisible(true)
                                      setFileName(item?.fileUrl)
                                    }}
                                      style={{ color: 'red' }}
                                    />
                                  </td>
                                </tr>
                              </>
                              :
                              null
                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </>
                )}
							</Card>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
}

export default MediaCenter;
