import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  Row,
  Table,
  Col,
  Progress,
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import NotificationAlert from "react-notification-alert";
import firebase from "firebase";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";


const useStyles = makeStyles((theme) => ({
  root: {
    "& :hover": {
        // backgroundColor:'white'
    },
    color: "#FFF",
  },
  dialog:{
    "& .MuiDialogTitle-root":{
        backgroundColor:"#FFF",
        color:'#333333',
        width:215,
        height:80
    },
    "& .MuiDialogActions-root":{
        backgroundColor:"#FFF",
        color:'#333333',
        width:215,
        height:62
    },
    "& .MuiDialog-paper":{
      position: 'absolute',
      top:20
    },
    
   
    // left: "30vw",
    // top: 0,
  },
  dialogBtn:{
      display:"flex",
      justifyContent:'space-around',
      backgroundColor:'#FFF',
      paddingLeft:24,
      paddingRight:24
  },
  title:{
    "& .MuiDialogTitle-root":{
      color:'#333333'
    }
    
  }
}));

const ConfirmDialog = ({ isOpen, setOpen, onSubmit }) => {
 const classes = useStyles();
  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={setOpen} classes={{root:classes.dialog}} >
      <DialogTitle id="alert-dialog-title" classes={{root:classes.title}} >
       <p style={{color:"#222a4299", fontWeight:300, fontSize:18}}>Do you want to send notification?</p>
      </DialogTitle>
      <DialogActions className={classes.dialogBtn}  >
        <Button onClick={onSubmit} color="info" style={{paddingLeft:30,paddingRight:30}} >
          Yes
        </Button>
        <Button onClick={()=>setOpen(false)}   style={{backgroundColor:"#304067", paddingLeft:30,paddingRight:30}} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
  };

function NotificationDialog({ handleClose, notifi }) {
  const notification = useRef();
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [type, setType] = useState("All");
  const classes = useStyles();
  const [batchData, setBatchData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  // const [endIndex, setEndIndex] = useState();
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [message, setMessage] = useState("");
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [batchSearchValue, setBatchSearchValue] = useState("");
  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const studentSearchIndex = searchClient.initIndex("Student_Search");
  const batchSearchIndex = searchClient.initIndex("Batch_Search");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [batchStudents, setBatchStudents] = useState([]);
  var data = [];
  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };

    if (notifi.current) {
      notification.current.notificationAlert(options);
    }
  };

  const removeBatch = (batchId) => {
    console.log("BATCH2",batchId );
    setBatchStudents(batchStudents.filter(batch => (batch.id !=batchId )))
  }

  const getBatchStudents = (batchId) => {
    db.collection("zSystemStudents")
    .where('batch','==',batchId).get().then((query) => {
      (query.docs.map((doc) => data.push(doc.data())));
      setBatchStudents([...batchStudents,{id:batchId,data}]);
      
    }
    )
  }
  const studentSearch = () => {
    if (studentSearchValue == "") {
      notify("Search field can not be empty", true);
    } else if (studentSearchValue.length < 3) {
      notify("Search atleast 4 words", true);
    } else {
      document.querySelector("#loading-indicator").style.display = "flex";

      studentSearchIndex
        .search(studentSearchValue)
        .then((result) => {
          if (result.hits.length === 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            notify("No results found", true);
          } else {
            document.querySelector("#loading-indicator").style.display = "none";
            setStudentsData(result.hits);
            setReload(true);
          }
        })
        .catch((err) => {
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
        });
    }
  };

  const batchSearch = () => {
    if (batchSearchValue == "") {
      notify("Search field can not be empty", true);
    } else if (batchSearchValue.length < 3) {
      notify("Search atleast 4 words", true);
    } else {
      document.querySelector("#loading-indicator").style.display = "flex";

      batchSearchIndex
        .search(batchSearchValue)
        .then((result) => {
          if (result.hits.length === 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            notify("No results found", true);
          } else {
            document.querySelector("#loading-indicator").style.display = "none";

            setBatchData(result.hits);
            setReload(true);
          }
        })
        .catch((err) => {
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
        });
    }
  };

  const emptyCall = () => {
    if (reload) {
      if (type === "Single") {
        if (studentSearchValue.length >= 1 && studentSearchValue.length < 4) {
          getUsers();
          setReload(false);
        }
      }
      if (type === "Batch") {
        if (studentSearchValue.length >= 1 &&  batchSearchValue.length < 4) {
          getBatchData();
          setReload(false);
        }
      }
    }
  };

  const emptyCharCall = () => {
      if (type === "Single") {
          getUsers();
          setReload(false);
      }
      if (type === "Batch") {
          getBatchData();
          setReload(false);
      }
    
  };

  const getBatchData = () => {
    db.collection("Batch")
      .where("type", "==", "batch")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => doc.data());
        setBatchData(data);
        setProgressVisible(false);
      });
  };

  const getUsers = () => {
    setProgressValue(99);
    db.collection("zSystemStudents")
      .orderBy("createdAt", "desc")
      .limit(20)
      .onSnapshot(function (querySnapshot) {
        var students = [];
        querySnapshot.forEach(function (doc) {
          students.push(doc.data());
        });
        document.querySelector("#loading-indicator").style.display = "none";
        setStudentsData(students);
        setProgressVisible(false);
        // setEndIndex(students.length);
      });
  };

  const onSubmit = () => {
   
    let finalData = {};
    let res;
    res = db.collection('notifications').doc()
    if (type === "All") {
      finalData = {
        type: type,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        message: message,
        itemID: res.id
      };
    }
    if (type === "Single") {
      finalData = {
        type: type,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        message: message,
        stuId: selectedStudent,
        itemID: res.id
      };
    }
    if (type === "Batch") {
      finalData = {
        type: type,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        message: message,
        batchId: selectedBatch,
        itemID: res.id
      };
    }

      res.set(finalData)
      .then((res) => {
        notify("Notification submitted");
        handleClose(false);
        setConfirmDialog(false);
      })
      .catch((err) => notify(err.toString, true));
  };

  useEffect(() => {
    if (type === "Batch") {
      setSelectedStudent([]);
      getBatchData();
    }
    if (type === "Single") {
      setSelectedBatch([]);
      getUsers();
    }
  }, [type]);

  return (
    <div
      style={{
        backgroundColor: "#1f1e2e",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      {progressVisible && (
        <Progress color="info" value={progressValue} style={{ height: 4 }} />
      )}

      <div className="react-notification-alert-container">
        <NotificationAlert ref={notification} />
      </div>

      <div style={{ padding: "5px 20px" }}>
        <Row style={{ justifyContent: "flex-end" }}>
          <IconButton
            edge="start"
            style={{ color: "white" }}
            onClick={() => handleClose(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Row>

        <Row>
          <Col md="12" style={{ marginBottom: "100px" }}>
            <Card style={{ margin: 0 }}>
              <CardBody>
                <Row>
                  <Col
                    sm={{ size: 6 }}
                    style={{
                      display: "flex",
                      height: "75vh",
                      flexDirection: "column",
                      borderRight: "1px solid #3d4356",
                      paddingLeft: "6%",
                      paddingRight: "6%",
                      paddingTop: "5%",
                    }}
                  >
                    <div style={{ paddingBottom: "20px" }}>
                      <h5 className="title">Type</h5>
                      <Input
                        type="select"
                        name="sendType"
                        onChange={(e) => {setBatchStudents([]); setType(e.target.value)}}
                        defaultValue="All"
                        required
                      >
                        <option value="All">All</option>
                        <option value="Batch">Batch</option>
                        <option value="Single">Single</option>
                      </Input>
                    </div>
                    <div style={{ paddingBottom: "20px" }}>
                      <h5 className="title"  >Message</h5>

                      <Input
                        type="textarea"
                        name="message"
                        rows={4}
                        placeholder="Please Enter Message"
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        style={{paddingLeft:7}}
                      />
                    </div>
                    {/* {batchStudents.length !== 0 &&  
                    <Input type="select" name="batchStudents" multiple style={{marginBottom:10}} >
                    { batchStudents.map((data) => (
                      data.data.map((student) =><option disabled>{student.firstname} {student.lastname}</option> )
                       
                    ))}  
                  </Input>
                  } */}
                    {type === "All" ||
                    selectedBatch.length ||
                    selectedStudent.length ? (
                      <Button
                        onClick={(e) => ( message.length === 0 ? notify("Please enter message", true) : setConfirmDialog(true))}
                        color="info"
                      >
                        Send{" "}
                      </Button>
                    ) : null}
                  </Col>
                  <Col
                    sm={{ size: 6 }}
                    style={{ borderRight: "1px solid #3d4356" }}
                  >
                    {type === "Batch" && (
                      <div style={{ paddingLeft: 50 }}>
                        <h5 className="title">{type}</h5>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Input
                              type="text"
                              name="search"
                              placeholder="Search"
                              style={{ marginRight: 0, minWidth: "250px" }}
                              onInput={(e) => {
                                e.target.value.length === 0 && emptyCharCall();
                                setBatchSearchValue(e.target.value);
                                emptyCall();
                              }}
                            />
                          </div>
                          <div style={{ paddingLeft: "0px" }}>
                            <Button
                              style={{
                                margin: 0,
                                marginLeft: "10px",
                                padding: "6px 10px",
                              }}
                              color="info"
                              onClick={batchSearch}
                            >
                              <SearchIcon />
                            </Button>
                          </div>
                        </div>
                        <div style={{ height: "70vh", overflowY: "scroll" }}>
                          <Table className="tablesorter">
                            <thead className="text-primary">
                              <tr>
                                <th style={{ paddingLeft: "30px" }}>Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {batchData.map((item) => {
                                return (
                                  <tr key={item.itemID}>
                                    <td style={{paddingLeft: 12}}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="checkBox"
                                            color="primary"
                                            classes={{ root: classes.root }}
                                            onChange={(e) =>
                                              e.target.checked
                                                ? (setSelectedBatch([
                                                    ...selectedBatch,
                                                    item.itemID,
                                                  ]), getBatchStudents(item.itemID) )
                                                : (setSelectedBatch(
                                                    selectedBatch.filter(
                                                      (id) => id !== item.itemID
                                                    )
                                                  ), removeBatch(item.itemID))
                                            }
                                          />
                                        }
                                        label={item.batchName}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    {type === "Single" && (
                      <div style={{ paddingLeft: 50 }}>
                        <h5 className="title">{type}</h5>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Input
                              type="text"
                              name="search"
                              placeholder="Search"
                              style={{ marginRight: 0 }}
                              onChange={(e) => {
                                e.target.value.length === 0 && emptyCharCall();
                                setStudentSearchValue(e.target.value);
                                emptyCall();
                              }}
                            />
                          </div>
                          <div style={{ paddingLeft: "0px" }}>
                            <Button
                              style={{
                                margin: 0,
                                marginLeft: "10px",
                                padding: "6px 10px",
                              }}
                              color="info"
                              onClick={studentSearch}
                            >
                              <SearchIcon />
                            </Button>
                          </div>
                        </div>
                        <div style={{ height: "70vh", overflowY: "scroll" }}>
                          <Table className="tablesorter">
                            <thead className="text-primary">
                              <tr>
                                <th style={{ paddingLeft: "30px" }}>Name</th>
                                <th>Enroll. No</th>
                                <th>Batch</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentsData.map((item) => {
                                console.log('item.batchName',item.batchName)
                                return (
                                  item.firstname && item.lastname ?
                                  <tr key={item._uniqueID}>
                                    <td style={{paddingLeft: 12}}>
                                      <FormControlLabel
                                        classes={{ label: classes.label}}
                                        control={
                                          <Checkbox
                                            name="checkBox"
                                            color="primary"
                                            classes={{ root: classes.root }}
                                            onChange={(e) =>
                                              e.target.checked
                                                ? setSelectedStudent([
                                                    ...selectedStudent,
                                                    item._uniqueID,
                                                  ])
                                                : setSelectedStudent(
                                                    selectedStudent.filter(
                                                      (id) =>
                                                        id !== item._uniqueID
                                                    )
                                                  )
                                            }
                                          />
                                        }
                                        label={
                                          item.firstname + " " + item.lastname
                                        }
                                      />
                                    </td>
                                    <td>{item.enrollmentNO}</td>
                                    <td>{item.batchName ? item.batchName.map(item => <li>{item.label}</li>) : "N/A"}</td>1
                                  </tr>
                                  :
                                  null
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ConfirmDialog
          isOpen={confirmDialog}
          setOpen={setConfirmDialog}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}
export default NotificationDialog;
