import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import firebase from 'firebase';
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import format from "date-fns/format";

function CampaignDiscountModal({ notify, setModal,id,setCampaignData, currentCampaignData }) {

  console.log(id);
  const [visible, setVisible] = useState(false);
  const [forIndividual, setForIndividual] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [error, setError] = useState("");
  const [fromDate, setFromDate] = useState("");
  const { register, handleSubmit, errors, control, getValues } = useForm();
  // console.log("this is from  form as props", enroll)
  

  const onSubmit = (data) => {    
    let res;
    if(currentCampaignData){
      res = db.collection('DiscountCoupons').doc(currentCampaignData.itemID)
    }else {
      res = db.collection('DiscountCoupons').doc()
    }

    let finalData = {}
    
    if(currentCampaignData){
      finalData = {
        itemID: res.id,
        couponCode: data.couponCode,
        validFor: data.validFor,
        discountAmount: parseFloat(data.discountAmount),
        discountIn: data.discountIn,
        validFrom: data.validFrom,
        validTill: data.validTill,
        // remarks: data.remarks,
        used: false,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        type: 'Campaign'
      }
    }else {
      finalData = {
        itemID: res.id,
        couponCode: data.couponCode,
        validFor: data.validFor,
        discountAmount: parseFloat(data.discountAmount),
        discountIn: data.discountIn,
        validFrom: data.validFrom,
        validTill: data.validTill,
        // remarks: data.remarks,
        used: false,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        type: 'Campaign'
      }
    }

    res.set(finalData).then((res) => {
      if(currentCampaignData){
        notify("Coupon Updated Successfuly", false)
      }else {
        notify("Coupon Created Successfuly", false);
      }

      if(currentCampaignData){
        setCampaignData(prev => prev.map((item) => item.itemID == currentCampaignData.itemID ? ({ ...finalData }) : item))
      }else {
        setCampaignData(prev => [finalData , ...prev])
      }
      setModal()
    }).catch(err => {
      notify(err.toString(), true)
    })

  };
  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row>
          <Col md="8">
            <Card style={{ border: "2px solid white" }}>
              <CardHeader>
                <h5 className="title">Add Campaign Code</h5>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Coupon Code</label>
                        <Input
                          innerRef={register({ required: true, maxLength: 10, minLength: 10, pattern: {
                            value: /^(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&]{8,}$/
                          }
                         })}
                          defaultValue={currentCampaignData ? `${currentCampaignData.couponCode}` :`CAM${id.toUpperCase()}`}
                          name="couponCode"
                          placeholder="Coupon Code"
                          type="text"
                          style={{marginBottom:0}}
                        />
                        {errors.couponCode && errors.couponCode.type === "minLength" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Minimum length for couponcode is 10
                          </span>
                        )}
                         {errors.couponCode && errors.couponCode.type === "maxLength" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Maximum length for couponcode is 10
                          </span>
                        )}
                        {errors.couponCode && errors.couponCode.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                        {errors.couponCode && errors.couponCode.type === "pattern" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Please enter valid Code
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>For</label>
                        <Input
                          defaultValue={currentCampaignData && currentCampaignData.validFor}
                          innerRef={register({ required: true })}
                          type="select"
                          name="validFor"
                          style={{marginBottom:0}}
                        >
                          <option value={""}>Select discount for</option>
                          <option value={"All"}>ALL</option>
                          <option value={"Package"}>Package</option>
                          <option value={"Book"}>Book</option>
                          <option value={"Quiz"}>Quiz</option>
                        </Input>
                        {errors.validFor && errors.validFor.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Valid From</label>
                        <Input
                          defaultValue = {currentCampaignData && currentCampaignData.validFrom}
                          innerRef={register({ required: true })}
                          name="validFrom"
                          placeholder="Valid From"
                          type="datetime-local"
                          style={{marginBottom:0}}
                          onChange={(e) => setFromDate(e.target.value)}
                          min={currentCampaignData && currentCampaignData.validFrom != null || "" ? false : format(new Date(),"yyyy-MM-dd'T'00:00")}
                        />
                        {errors.validFrom && errors.validFrom.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Valid Till</label>
                        <Input
                          defaultValue={currentCampaignData && currentCampaignData.validTill}
                          innerRef={register({ required: true,min:currentCampaignData && currentCampaignData.validTill != null || "" ? false : !fromDate == "" ? format(new Date(fromDate),"yyyy-MM-dd'T'HH:mm") :format(new Date(),"yyyy-MM-dd'T'HH:mm")  })}
                          name="validTill"
                          placeholder="Valid Till"
                          type="datetime-local"
                          style={{marginBottom:0}}
                          min={!fromDate == "" ? format(new Date(fromDate),"yyyy-MM-dd'T'00:00") : format(new Date(),"yyyy-MM-dd'T'00:00") }
                        />
                        {errors.validTill && errors.validTill.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                         {errors.validTill && errors.validTill.type === "min" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            End datetime should be greater than from datetime
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Discount Of</label>
                        <Row> 
                          <Col md="4">
                            <Input
                              defaultValue={currentCampaignData && currentCampaignData.discountIn}
                              innerRef={register({ required: true })}
                              type="select"
                              name="discountIn"
                              required
                              style={{paddingLeft : 9,marginBottom:0}}
                            >
                              <option value={"Rupees"}>Rupees</option>
                              <option value={"Percentage"}>Percent</option>
                            </Input>
                            {errors.discountIn && errors.discountIn.type === "required" && (
                              <span style={{fontSize: 12, color: "red"}}>
                                This field is required
                              </span>
                            )}
                          </Col>
                          <Col md="8">
                            <Controller
                            
                            render={(field) => {
                              return <Input
                                defaultValue={currentCampaignData && currentCampaignData.discountAmount}
                                {...field}
                                innerRef={register({ required: true })}
                                name="discountAmount"
                                placeholder="Discount OF"
                                type="number"
                                onChange={(e) => {
                                  if(getValues().discountIn == 'Percentage' && parseFloat(e.target.value || 0) <= 100){
                                    field.onChange(e.target.value);
                                  }
                                }}
                              />
                            }}
                            name="discountAmount"
                            control={control}
                             />
                          {errors.discountAmount && errors.discountAmount.type === "required" && (
                            <span style={{fontSize: 12, color: "red"}}>
                              This field is required
                            </span>
                          )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    {/* <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Remarks</label>
                        <Input
                          defaultValue={currentCampaignData && currentCampaignData.remarks}
                          innerRef={register({ required: false })}
                          name="remarks"
                          placeholder="Remarks"
                          type="textarea"
                          style={{marginBottom:0}}
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <br></br>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button color="info" type="submit">
                      Submit
                    </Button>
                    <Button onClick={() => setModal(false)}>Back</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CampaignDiscountModal;
