import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
    ModalBody,
    Modal,
} from "reactstrap";
import firebase from 'firebase';
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { Dialog, Slide } from "@material-ui/core";
import AddClassesTime from "./AddClassesTime";
import AddClassesTimeDialog from "./AddClassesTimeDialog";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Add_Edit_Batch({handleClose,notify, setBatchData, currentBatch}) {
    const weekOptions = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ];

    const { register, handleSubmit, errors } = useForm();
    
    const [weekName, setWeekName] = React.useState(currentBatch.weekName || []);
    const [active, setActive] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [weekdayError, setWeekdayError] = useState("");
    const [batchErr, setBatchErr] = useState(false);
    const [dateError, setDateError] = useState('')

    const classesOn = useState([]);

    const handleChange = (event) => {
        setWeekdayError("");
        setWeekName(event.target.value);
    };
    
    
    const submitData = async (data) => {
        if(data.startDate >= data.endDate) {
            setDateError('End date cannot be less than start date')
            return
        }     
        if(!classesOn[0] || (classesOn[0] && classesOn[0].length === 0)) {
            setBatchErr(true)
            notify('Please select week of the day', true)
            return
        }

        setActive(false);
        let res;
        if (currentBatch) {

            res = db.collection('Batch').doc(currentBatch.itemID)

        } else {

            res = db.collection('Batch').doc()
        }

        let finalData = {}

        if(currentBatch) {
            finalData = {
                itemID: res.id,
                batchName: data.batchName,
                startDate:data.startDate,
                endDate:data.endDate,
                maxStudent:parseInt(data.maxStudent),
                classesOn:classesOn[0],
                latUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                type:"batch"
            }

        } else {

            finalData = {
                itemID: res.id,
                batchName: data.batchName,
                maxStudent:parseInt(data.maxStudent),
                startDate:data.startDate,
                endDate:data.endDate,
                weekDays: weekName,
                classesOn:classesOn[0],
                createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                type:"batch"
            }

        }
        res.set(finalData, {merge:true}).then(res => {
        
            if (currentBatch) {

                notify('Batch updated Successfully', false)

            } else {

                notify('Batch has been submitted', false)
            
            }
            if (currentBatch) {

                setBatchData(prev => prev.map((item) => item.itemID == currentBatch.itemID ? ({ ...finalData }) : item))

            } else {

                setBatchData(prev => [ finalData, ...prev])

            }

            handleClose()
        }).catch(err => {
            notify(err.toString(), true)
            notify(err.toString(), true)
            setActive(true);


        })
       
    }

    useEffect(()=>{
        if(currentBatch) classesOn[1](currentBatch?.classesOn !== undefined ? currentBatch?.classesOn : [])
    },[])
	
    return (
        <>
        {/* <Modal isOpen={modalOpen} style={{ display: 'flex', flex: 1,  minWidth: '100%' }}>
            <ModalBody style={{
            width: '100%', display: 'inline-block',top:'50px', padding: '0px !important', position: 'absolute',
            border: '1px solid white !important',
            }} size='lg'>
                <AddClassesTime classesOn={classesOn} setModalOpen={setModalOpen}/>
            </ModalBody>
        </Modal> */}
        <Dialog fullScreen open={modalOpen}  TransitionComponent={Transition}>
            <AddClassesTimeDialog classesOn={classesOn} setModalOpen={setModalOpen}/>
        </Dialog>

		<div className="content">
			<Row>
				<Col md="12">
					<Card style={{ margin: 0 }}>
                        <CardHeader>
                            <h5 className="title">Create Batch</h5>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit(submitData)}>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label>Batch Name</label>
                                            <Input
                                            defaultValue = {currentBatch && currentBatch.batchName}
                                            innerRef={register({ required: true })}
                                            name='batchName'
                                            placeholder="Batch Name"
                                            type="text"
                                            />
                                            {errors.batchName && errors.batchName.type === "required" && (
                                                <span style={{fontSize: 12, color: "red", top: -20, position: "relative"}}>
                                                    Please enter the batch Name
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Maximum Students</label>
                                            <Input
                                            defaultValue = {currentBatch && currentBatch.maxStudent}
                                            innerRef={register({ required: true })}
                                            name='maxStudent'
                                            placeholder="Maximum Students"
                                            type="number"
                                            />
                                            {errors.maxStudent && errors.maxStudent.type === "required" && (
                                                <span style={{fontSize: 12, color: "red", top: -20, position: "relative"}}>
                                                    Please enter Maximum number of students
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="6">
                                        <FormGroup>
                                            <label>Professor Name</label>
                                            <Input
                                            defaultValue = {currentBatch && currentBatch.professorName}
                                            innerRef={register({ required: false })}
                                            name='professorName'
                                            placeholder="Professor Name"
                                            type="text"
                                            />
                                        </FormGroup>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label>Batch Start From</label>
                                            <Input
                                            defaultValue = {currentBatch && currentBatch.startDate}
                                            innerRef={register({ required: true })}
                                            name='startDate'
                                            placeholder="Start From"
                                            type="date"
                                            />
                                            {errors.startDate && errors.startDate.type === "required" && (
                                                <span style={{fontSize: 12, color: "red", top: -20, position: "relative"}}>
                                                    Please enter start date
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Batch End At</label>
                                            <Input
                                            defaultValue = {currentBatch && currentBatch.endDate}
                                            innerRef={register({ required: true })}
                                            name='endDate'
                                            placeholder="End At"
                                            type="date"
                                            />
                                            {errors.endDate && errors.endDate.type === "required" && (
                                                <span style={{fontSize: 12, color: "red", top: -20, position: "relative"}}>
                                                    Please enter end date
                                                </span>
                                            )}
                                            {dateError && dateError!=='' && (
                                                <span style={{fontSize: 12, color: "red", top: -20, position: "relative"}}>
                                                    {dateError}
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup>
                                            <label>Week of the day</label>
                                            <div style={{
                                                display: 'flex',
                                                border: '1px solid #393f5d',
                                                height: 36,
                                                marginRight: 11,
                                                borderRadius: 6,
                                                fontSize:12,
                                                alignItems:'center',
                                                paddingLeft: 16,
                                                cursor:'pointer'
                                            }}
                                            onClick={()=>{
                                                setModalOpen(prev => !prev)
                                            }}
                                            >
                                                {classesOn[0] && classesOn[0].length !== 0 ? `${classesOn[0].length} classes selected` : '  Please Select'}
                                            </div>
                                            {batchErr && classesOn[0] && classesOn[0].length === 0 && (
                                                <span style={{fontSize: 12, color: "red", top: 0, position: "relative"}}>
                                                    Please select week of the day
                                                </span>
                                            )}
                                         </FormGroup>
                                    </Col>
                                   
                                </Row>
                                <div>
                                    {active ? 
                                    <Button color='info' type='submit'>Submit</Button>
                                    :
                                    <Button color='info' disabled>Submit</Button>
                                    }
                                    <Button onClick={handleClose}>Back</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
				</Col>
			</Row>
		</div>
        </>
	);
}

export default Add_Edit_Batch;
