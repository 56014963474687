import React from 'react'
import {Button} from "reactstrap";

const CampaignDelete = ({deleteCapmaign, setCampaignDeleteModal}) => {
    const deleteRecord = () =>{
        deleteCapmaign();
        setCampaignDeleteModal(false)
    }

    const onclick = () =>{
        setCampaignDeleteModal(false)
    }

    return (
        <div>
            <p >Do you want to delete this Coupon</p>
            <div style={{display:'flex',flexDirection:'row'}}>
            <Button color='info' onClick={deleteRecord}>Yes</Button>
            <Button  onClick={onclick}>No</Button>
            </div>
        </div>
    )
}

export default CampaignDelete
