import { ImportExport } from "@material-ui/icons";
import React, { useState } from "react";
import { useForm } from 'react-hook-form'

import * as firebase from 'firebase'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import { db } from '../MY_LOGIN_AND_SIGNUP/db/config'

const functions = firebase.functions()

function Add_Student({ notify, setModal, setUserData }) {
  const [visible, setVisible] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  const [sendSMS, setSendSMS] = useState(false)
  const [shift, setShift] = useState(false)
  const [error, setError] = useState('')
  const { register, handleSubmit, errors } = useForm()
  // console.log("this is from  form as props", enroll)
  // console.log(typeof (enroll))

  console.log("shift:", shift);


  const onSubmit= (data) => {

    setVisible(true)
    console.log(data);

    if (data.mobile < 4000000000) {
      setError('invalid mobile number')
    } else {
      setError('')
      db.collection('zSystemStudents')
      .where("mobile", "==", data.mobile)
      .get()
      .then(doc => {
        if(doc.docs.length > 0){
          notify("Mobile No is already exist" , true)
        }else{
          db.collection('zSystemStudents')
          .where("secondaryEmail", "==", data.secondaryEmail)
          .get()
          .then(query => {
            if(query.docs.length > 0){
              notify("This Secondary email address is already exist" , true)
            }else{
              
            db.collection('zSystemStudents')
            .where("enrollmentNO", "==", data.enrollment)
            .get()
            .then(query => {
              if(query.docs.length > 0){
                notify("This Enrollment No is already exist" , true)
              }else{
                
                const studentData = {
                  firstname: data.firstname,
                  lastname: data.lastname,
                  mobile: data.mobile,
                  enrollmentNO: data.enrollment,
                  address: data.address,
                  email: data.email,
                  password:data.password,
                  sendEmail:sendEmail,
                  forceResetPassword: true,
                  sendSMS:sendSMS,
                  profilePic:'',
                  shift:data.shift,
                  secondaryEmail:data.secondaryEmail,
                  status: 'Active',
                  disabled:false,
          
                }
                const addStudentUser = functions.httpsCallable('addStudentUser');
                document.querySelector("#loading-indicator").style.display = "flex";
                addStudentUser(studentData).then(result => {
          
                  if(result.data.hasError){
                    document.querySelector("#loading-indicator").style.display = "none";
                    notify(result.data.message.errorInfo.message, true)
                  }else{
                    const _uniqueID = result.data.user.uid;
                    let userData = {}
                    userData = {
                      firstname: data.firstname,
                      lastname: data.lastname,
                      mobile: data.mobile,
                      enrollmentNO: data.enrollment,
                      address: data.address,
                      secondaryEmail:data.secondaryEmail,
                      email: data.email,
                      shift:data.shift,
                      password:data.password,
                      sendEmail:sendEmail,
                      sendSMS:sendSMS,
                      forceResetPassword: true,
                      createdFrom:'Admin',
                      profilePic:'',
                      status: 'Active',
                      _uniqueID,
                      disabled:false,
                      createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                      lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                      }
                    db.collection('zSystemStudents').doc(_uniqueID.toString()).set(userData).then(function () {
                      notify("Student created successfully", false)
                      document.querySelector("#loading-indicator").style.display = "none";
                      setUserData(prev => [userData, ...prev])
                      setModal(false);
                    }).catch(function (error) {
                      notify(error.toString(), true)
                      console.log(error.toString())
                      document.querySelector("#loading-indicator").style.display = "none";
                    })
                    console.log(result);
                }})
                }
              }).catch((err) => {
                notify(err.toString(), true);
                document.querySelector("#loading-indicator").style.display = "none";
                console.log(err.toString())
              })
            }
          })
        }
      })  
    }
  }
  



  return (
    <>
      <div className="content" style={{ width: 1150, }}>
        <Row style={{}}>
          <Col md="8">
            <Card style={{ border: '2px solid white' }}>
              <CardHeader>
                <h5 className="title">Add Student</h5>
              </CardHeader>
              <CardBody>
                <Form style={{ paddingRight: 10 }} onSubmit={handleSubmit(onSubmit)}
                  
                >
                  <Row>

                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='firstname'
                          placeholder="First Name"
                          type="text"
                          
                        />
                        {errors.firstname && errors.firstname.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter first name</span>
                      }
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          innerRef={register({required:true})}
                          name='lastname'
                          placeholder="Last Name"
                          type="text"

                        />
                        {errors.lastname && errors.lastname.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter last name</span>
                      }
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  {/* <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                          innerRef={register({required:true,})}
                            name='email'
                            placeholder="user@user.com"
                            type="email"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            innerRef={register({required:true,minLength:6})}
                            required
                            name='password'
                            type="password"
                            placeholder='Password'
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                 
                  <Row>

                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='email'
                          placeholder="Email"
                          type="email"
                          
                        />
                        {errors.email && errors.email.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter email</span>
                      }
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          innerRef={register({ required: true, minLength: 8 })}
                          name='password'
                          placeholder="Password"
                          type="password"

                        />
                        {errors.password && errors.password.type === 'minLength' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}} >Password should be atleast 8 digits.</span>
                      )}
                      {errors.password && errors.password.type === 'required' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}} >Please enter password.</span>
                      )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Enrollment number</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='enrollment'
                          placeholder="Enrollment number"
                          type='text'
                          
                        />
                        
                      {errors.enrollment && errors.enrollment.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter enrollment number</span>
                      }
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Mobile</label>
                        <Input
                          innerRef={register({ minLength: 10, maxLength: 10, required: true, })}
                          name='mobile'
                          placeholder="Mobile"
                          type='number'
                          
                        />
                        {error &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >{error}</span>
                      }
                      {errors.mobile && errors.mobile.type !== 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Valid Mobile No.</span>
                      }
                       {errors.mobile && errors.mobile.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Mobile No.</span>
                      }
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Secondary Email</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='secondaryEmail'
                          placeholder="Email"
                          type="email"
                      
                        />
                        {errors.secondaryEmail && errors.secondaryEmail.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter secondary email</span>
                      }
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          innerRef={register({ required: true, })}
                          name='address'
                          placeholder="Address"
                          type='textarea'
                          
                        />
                        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                        {errors.address && errors.address.type === 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please enter address</span>
                      }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                          <label>Shift</label>
                          <Input
                            innerRef={register({required:true,})}
                            name='shift'
                            type="select"
                            required
                          > 
                            <option value="">Select Shift</option>
                            <option value="morningShift">Morning Shift</option>
                            <option value="afternoonShift">Afternoon Shift</option>
                            <option value="eveningShift">Evening Shift</option>
                          </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col className="pr-md-1" md="6" style={{paddingLeft: "70px"}}>
                      <div >
                        <input 
                          name="sendEmail"
                          type="checkbox"
                          value={sendEmail}
                          onChange={(e) => setSendEmail(!sendEmail)}
                          style={{marginRight:"10px"}}
                        />
                        <span style={{fontSize: "15px", color: "rgba(255,255,255,0.6)"}}>Send Invitation mail</span>
                      </div>
                    </Col>

                    <Col className="pr-md-1" md="6" style={{paddingLeft: "70px"}}>
                      <div>
                        <input 
                          name="sendSMS"
                          type="checkbox"
                          value={sendSMS}
                          onChange={(e) => setSendSMS(!sendSMS)}
                          style={{marginRight:"10px"}}
                        />
                        <span style={{fontSize: "15px", color: "rgba(255,255,255,0.6)"}}>Send Invitation SMS</span>
                      </div>
                    </Col>
                  </Row>
                  <br></br>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {/* {visible===false? <Button className="btn-fill" color="info" type="submit">
                    Submit
                  </Button> : <Button className="btn-fill" color="info" onClick={onClick}>Done</Button>}
                  {visible===false && <Button className="btn-fill"  onClick={onClick}>
                    Cancel
                  </Button>} */}
                    <Button color='info' type='submit'>Submit</Button>
                    <Button onClick={()=> setModal(false)}>Back</Button>
                  </div>
                </Form>
              </CardBody>

            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}


export default Add_Student;
