import * as firebase from 'firebase'
import React,{useEffect} from 'react'
import {db} from '../MY_LOGIN_AND_SIGNUP/db/config'
import {useHistory} from 'react-router-dom'



const GetUser = () => {

const pathOptions = [
    {
        value:"User_Management",    
        path: "/tables",

    },{
        value:"Student_Management",
        path: "/student",

    },{
        
        value:"Book_Management",
        path: "/book",


    },{
        
        value:"Quiz_Management",
        path: "/quiz",

    },{
        
        value:"Package_Management",
        path: "/package",

    },
    // {
        
    //     value:"Transaction_Management",
    //     path: "/transaction",

    // },
    {
        
        value:"Chat",
        path: "/chat",

    },{
        
        value:"Reports",
        path:'/',
    },{
        
        value:"Feedback",
        path: "/feedback",

    },{
        
        value:"Enquiry",
        path: "/enquiry",

    },{
        
        value:"Discount_Management",
        path: "/discountManagement",

    }]
    
const history = useHistory()

useEffect(() => {
    
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            // User is signed in.
            const uid = user.uid;
            localStorage.setItem("uid", uid);
            db.collection('zSystemUsers').doc(uid)
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.data()

                let currentPermission = pathOptions.filter((item)=> data.permissions.find(v => v.value == item.value));
                console.log('currentPermission',currentPermission)
                if(currentPermission.length !== 0){

                    history.push(`/admin${currentPermission[0].path}`)
                }
                console.log('current User login',user)
            })

        }
        else{
            console.log('user not login')
            history.push('/login')
            localStorage.removeItem("uid");
        }
    })
   
}, [])

    return(
        <div></div>
    )

} 
export default GetUser




