/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
import { Edit, Delete, Add } from '@material-ui/icons'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import SecurityIcon from '@material-ui/icons/Security';
import * as Firebase from 'firebase'

//import { Modal } from '@material-ui/core';
import { db } from '../MY_LOGIN_AND_SIGNUP/db/config'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Progress,
  UncontrolledDropdown,Dropdown, DropdownToggle, DropdownMenu, DropdownItem

} from "reactstrap";
import NotificationAlert from "react-notification-alert";


import SignUp from '../MY_LOGIN_AND_SIGNUP/SIGN_UP_MODAL/Sign_up_form'
import Delete_User_Modal from "MY_LOGIN_AND_SIGNUP/SIGN_UP_MODAL/Delete_USer_Modal";
import Edit_User from "MY_LOGIN_AND_SIGNUP/SIGN_UP_MODAL/Edit_User_modal";

//added this file
import styles from './tableList.module.css'

function Tables() {
  const [progressVisible, setProgressVisible] = useState(true)
  const [progressValue, setProgressValue] = useState(8)
  const [userData, setUserData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [myUser, setMyUser] = useState()
  const [reload, setreload] = useState(true)




  const getUsers = () => {
    setProgressValue(98);
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('zSystemUsers')
      .get()
      .then(querySnapshot => {
        let data = querySnapshot.docs.map(doc => doc.data())
        console.log(data)
        setUserData(data)
        setProgressVisible(false);
        document.querySelector("#loading-indicator").style.display = "none";
        // console.log('my user data is ', data)
        // console.log('userdata........', data)
      }).catch(err => {
        notify(err, true);
        document.querySelector("#loading-indicator").style.display = "none";
      })
  }

  // console.log('state tablelist', userData)

  useEffect(() => {
    getUsers()
  }, [])


  const notifi = useRef();
  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? 'success' : 'danger'
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6
    };
    if(notifi.current){
      notifi.current.notificationAlert(options)
    }
    // notification(options);
  }


  return (
    <>
      {/* change for the modal border */}
      {progressVisible ? <Progress value={progressValue} color='info' style={{ height: 5 }} /> : <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>

        <Modal isOpen={isModalVisible} style={{ display: 'flex', flex: 1, top: -100 }} size='lg'>
          <ModalBody className={styles.create__user_modal_body} size='sm'>
            <SignUp
              setUserData={setUserData}
              notify={notify}

              onClick={() => {
                setIsModalVisible(false)

              }}></SignUp>
          </ModalBody>
        </Modal>
        <Modal isOpen={deleteVisible} color='primary' style={{ display: 'flex', flex: 1, top: 0, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
          <ModalBody style={{ width: '100%' }} size='sm'>
            <Delete_User_Modal uniqueId={myUser !== undefined && myUser._uniqueID}
              myUser={myUser !== undefined && myUser}
              setUserData={setUserData}
              notify={notify}
              onClick={() => {
                // console.log("this is my email ", myUser.email)
                setDeleteVisible(false)

              }}></Delete_User_Modal>
          </ModalBody>
        </Modal>
        <Modal isOpen={editVisible} style={{ display: 'flex', flex: 1, top: -100 }} size='lg'>
          <ModalBody className={styles.create__user_modal_body} >
            <Edit_User
              myUser={myUser !== undefined && myUser}
              setUserData={setUserData}
              notify={notify}
              onClick={() => {
                setEditVisible(false)
                // setreload(false)
                // console.log('should false', reload)
              }}></Edit_User>
          </ModalBody>
        </Modal>


        <Row>
          <Col md="12">
            <Card >
              <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end' }}>
                <CardTitle tag="h4" style={{ marginRight: 'auto', marginLeft:'auto', fontWeight: 300 }}>USER MANAGEMENT</CardTitle>
                <Button color='info' onClick={() => setIsModalVisible(true)}>Create User <Add /></Button>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" >
                  <thead className="text-primary">
                    <tr>
                      <th>User Name</th>
                      <th style={{textAlign: "center"}}>Email</th>
                      <th style={{textAlign: "center"}}>Mobile</th>
                      <th style={{textAlign: "center"}}>Permission</th>
                      <th style={{textAlign: "center"}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.map(user => {
                      console.log("userData:", user)
                      // let status = user.status && [];
                      return (< tr key={user.username} >
                        <td>{user.username}</td>
                        <td style={{textAlign: "center"}}>{user.email}</td>
                        <td style={{textAlign: "center"}}>{user.mobile}</td>
                        <td style={{textAlign: "center"}}>
                          <UncontrolledDropdown style={{display:'flex', justifyContent:'center'}}>

                            {/* <Dropdown isOpen={dropdownOpen} toggle={toggle} > */}
                            <DropdownToggle style={{ margin: '0px', padding: '6px 10px' }} className="nav-link" color='info' caret>
                              <SecurityIcon />
                            </DropdownToggle>
                            <DropdownMenu style={{left: 40}}>
                              <DropdownItem style={{ color: 'black', fontSize: "0.8rem" }} header>User Permissions</DropdownItem>
                              {user?.permissions?.map(data =>
                                // <p><Input type='checkbox' checked disabled />{data.label}</p>
                                <DropdownItem style={{ color: 'black' }} >{data.label}</DropdownItem>
                              )}



                            </DropdownMenu>
                            {/* </Dropdown> */}
                          </UncontrolledDropdown>
                          {/* {user?.permissions?.map(data => <p><Input type='checkbox' checked disabled />{data.label}</p>)} */}
                          {/*status.map( data => <p><Input type='checkbox' checked disabled/>{data.label} ,</p>)*/}


                          {/* // console.log('options selected', data) */}

                        </td>
                        <td style={{ cursor: 'pointer', textAlign: "center" }}>
                          <Edit style={{color:'#247cf7'}} onClick={() => {
                            setMyUser(user)
                            setEditVisible(true)
                          }} />


                          {user.disabled === false ?
                            (<Delete style={{ color: 'red' }} onClick={() => {
                              // console.log("this is user deleteed".user)
                              setMyUser(user)
                              setDeleteVisible(true)
                            }} />)
                            :
                            (<PersonAddDisabledIcon style={{ color: 'red' }}
                              onClick={() => {
                                // console.log("this is user deleteed".user)
                                setMyUser(user)
                                setDeleteVisible(true)

                              }}
                            />)

                          }

                          {/* <Delete style={{ color: user.status === 'disable' ? '#ffb3b3' : 'red' }} onClick={() => {
                            console.log("this is user deleteed".user)
                            setMyUser(user)
                            setDeleteVisible(true)
                          }} /> */}

                        </td>
                      </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>}
    </>
  );
}


export default Tables;
