import React, { useEffect, useState, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Form,
  Modal,
	ModalBody,
  FormGroup,
  Table,
  Progress,
} from "reactstrap";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import NotificationDialog from "./NotificationDialog";
import Dialog from "@material-ui/core/Dialog";
import { Delete } from "@material-ui/icons";
import DeleteNotification from "./DeleteNotification";


function NotificationList() {
  const [limit,setLimit] = useState(10);

  const notification = useRef();
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [notificationData, setNotificationData] = useState([]);
  const [isfilter, setIsFilter] = useState(false);
  const { handleSubmit, register } = useForm();
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [open, setOpen] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState('');

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const getNotifications = () => {
    setProgressValue(99);
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection("notifications")
      .orderBy("createdAt", "desc")
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        data = data.map((item) => {
          let time = new Date(item.createdAt.seconds * 1000).toLocaleString();
          return {
            createAtActual:item.createdAt,
            createdAt: format(new Date(time),"dd/LL/yyyy hh:mm aa"),
            message: item.message,
            type: item.type,
            itemID: item.itemID
          };
        });
        setProgressVisible(false);
          setNotificationData(data);
        setEndIndex(data.length);
        document.querySelector("#loading-indicator").style.display = "none";
      });
  };

  useEffect(() => {
    getNotifications();
  }, [isfilter,open,limit]);

  const onSubmit = (data) => {
    document.querySelector("#loading-indicator").style.display = "flex";

    let fromDate = new Date(data.fromDate);
    let toDate = new Date(data.toDate);
    db.collection("notifications")
      .orderBy("createdAt", "desc")
      .where('createdAt', '>=', fromDate).where('createdAt', '<=', toDate)
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        data = data.map((item) => {
          let time = new Date(item.createdAt.seconds * 1000).toLocaleString();

          return {
            createdAt: time,
            message: item.message,
            type: item.type,

          };
        });
        document.querySelector("#loading-indicator").style.display = "none";

        setNotificationData(data);
      })
      .catch((err) => {
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString());
      });
  };

  const nextSet = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection("notifications")
    .orderBy("createdAt", "desc").startAfter(notificationData[notificationData.length - 1].createAtActual)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        data = data.map((item) => {
          let time = new Date(item.createdAt.seconds * 1000).toLocaleString();

          return {
            createAtActual: item.createdAt,
            createdAt: time,
            message: item.message,
            type: item.type,
          };
        });
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          setNotificationData(data);
          setStartIndex(endIndex + 1);
          setEndIndex((prev) => prev + data.length);
        } else {
          document.querySelector("#loading-indicator").style.display = "none";

          notify("End Of the list", true);
        }
      })
      .catch((err) => {
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString());
      });
  };

  const prevSet = () => {
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection("notifications")
      .orderBy("createdAt")
      .startAfter(notificationData[0].createAtActual)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        data = data.map((item) => {
          let time = new Date(item.createdAt.seconds * 1000).toLocaleString();

          return {
            createAtActual: item.createdAt,
            createdAt: time,
            message: item.message,
            type: item.type,
          };
        });
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          data = data.reverse();
          setStartIndex((prev) => prev - data.length);
          setEndIndex((prev) => prev - notificationData.length);
          setNotificationData(data);
        } else {
          document.querySelector("#loading-indicator").style.display = "none";
        }
      })
      .catch((err) => {
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString());
      });
  };

  const deleteNotification = () =>{
    console.log("ye wala");
    db.collection('notifications').doc(currentData).delete().then(res => {
      setNotificationData(prev=>prev.filter(item => item.itemID !== currentData))
      setIsDeleteModalVisible(false)
    }).catch(err=>{
      console.log(err)
      setIsDeleteModalVisible(false)
    })
  }

  return (
    <>
      {progressVisible ? (
        <Progress color="info" value={progressValue} style={{ height: 4 }} />
      ) : (
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notification} />
          </div>
          <Modal isOpen={isDeleteModalVisible} color='primary' style={{ display: 'flex', flex: 1, top: -40, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
            <ModalBody size='sm'>                
              <DeleteNotification setIsDeleteModalVisible={setIsDeleteModalVisible} deleteNotification={deleteNotification}/>
            </ModalBody>
          </Modal>
          <Dialog fullScreen open={open} onClose={setOpen}>
            <NotificationDialog notifi={notification} handleClose={setOpen} />
          </Dialog>
          <Card>
            <Row>
              <Col sm={12}>
                <CardHeader
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Col sm={9} style={{ textAlign: "center" }}>
                    <CardTitle tag="h4" style={{ fontWeight: 300 }}>
                      NOTIFICATION MANAGEMENT
                    </CardTitle>
                  </Col>
                  <Col sm={3}>
                    <Button
                      color="info"
                      style={{ float: "right" }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      New Notification
                    </Button>
                  </Col>
                </CardHeader>
              </Col>
            </Row>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup
                      row
                      style={{ justifyContent: "flex-end", height: "50px" }}
                    >
                      {isfilter ? (
                        <>
                          <Col sm={1}>
                            <Label style={{ minWidth: "70px" }} for="fromData">
                              {" "}
                              From Date
                            </Label>
                          </Col>
                          <Col sm={3}>
                            <Input
                              innerRef={register({ required: true })}
                              type="date"
                              name="fromDate"
                              className="dateclass placeholderclass"
                              max={new Date(
                                new Date().setHours(new Date().getHours() + 6)
                              )
                                .toISOString()
                                .substr(0, 10)}
                                placeholder="From Date"
                            />
                          </Col>
                          <Col sm={1}>
                            <Label for="toData"> To Date</Label>
                          </Col>
                          <Col sm={3}>
                            <Input
                              innerRef={register({ required: true })}
                              type="date"
                              name="toDate"
                              max={new Date(
                                new Date().setHours(new Date().getHours() + 6)
                              )
                                .toISOString()
                                .substr(0, 10)}
                              className="dateclass placeholderclass"
                              placeholder="To Date"
                            />
                          </Col>
                          <Col style={{ paddingLeft: "0px" }} sm={3}>
                            <Button
                              style={{ margin: "0px", padding: "6px 10px" }}
                              color="info"
                              type="submit"
                            >
                              <SearchIcon />
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          sm={6}
                        >
                          <p
                            style={{
                              position: "relative",
                              top: "8px",
                              marginRight: "15px",
                            }}
                          >
                            {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                          </p>
                          <div
                            style={{
                              position: "relative",
                              top: "-5px",
                              marginRight: "0px",
                            }}
                          >
                            {startIndex > 1 && (
                              <IconButton style={{ outline: "none" }}>
                                <KeyboardArrowLeftSharpIcon
                                  style={{ color: "white" }}
                                  onClick={() => {
                                    prevSet();
                                  }}
                                />
                              </IconButton>
                            )}
                            <IconButton
                              style={{ outline: "none" }}
                              onClick={() => nextSet()}
                            >
                              <KeyboardArrowRightSharpIcon
                                style={{ color: "white" }}
                              />
                            </IconButton>
                          </div>
                          
                          <Input
                            style={{width:81, marginRight:10, position:'relative', top:3}}
                            type="select"
                            value={limit}
                            onChange={(e)=>setLimit(parseInt(e.target.value))}
                            // required
                          >
                            <option value='10'>10</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                          </Input>
                        </Col>
                      )}
                      <Col style={{ paddingRight: "0px" }} sm={1}>
                        <Button
                          style={{ margin: "0px", padding: "6px 10px" }}
                          color={isfilter && "info"}
                          onClick={() => setIsFilter(!isfilter)}
                        >
                          <FilterListIcon />
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th width="25%">Type</th>
                    <th width="50%" style={{ textAlign: "start" }}>
                      Notification Message
                    </th>
                    <th width="15%" style={{ textAlign: "center" }}>
                      Created At
                    </th>
                    <th width="10%" style={{ textAlign: "center"}}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notificationData.length === 0 && (
                    <tr>
                      <td></td>

                      <td >
                        No Data Found
                      </td>
                      <td></td>
                    </tr>
                  )}
                  {notificationData.map((item, index) => {
                    console.log("id:",item.itemID);
                    console.log("id:",item);
                    return (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td
                          style={{
                            textAlign: "start",
                            textJustify: "auto",
                            overflowWrap:'anywhere'
                          }}
                        >
                          {item.message}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.createdAt}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Delete onClick={() => {
                            setCurrentData(item.itemID)
                            setIsDeleteModalVisible(true)
                          }}
                            style={{ color: 'crimson',cursor:'pointer' }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}

export default NotificationList;
