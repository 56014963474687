import { Add } from "@material-ui/icons";
import React,{useState} from "react";
import {useForm} from 'react-hook-form'
import {Route,Redirect,withRouter, NavLink} from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress
} from "reactstrap";
import Book_Edit_Step1 from "./Book_Add_Step/Book_Edit_Step1";
import Book_List from "./Book_List";
 //import {db} from '../MY_LOGIN_AND_SIGNUP/db/config'

function Book_Add_Page(){
    const [headFootVisible,setHeadFootVisible] = useState(true)
    const [visible,setVisible] = useState(false)
    const [progressVisible,setProgressVisible] = useState(true)
    const [progressValue,setProgressValue] = useState(5)
    const [error,setError] = useState('')
    const {register,handleSubmit,errors} = useForm()  
    
    return (
      <>
      {progressVisible && <Progress color='info' value={progressValue} style={{height:4}}/>}
        <div className="content" style={{objectFit:'fill',overflow:'hidden'}}>
           {/* <Row style={{}}>
            <Col md="8">
              <Card style={{}}>
                <CardHeader>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <h4 className="title" style={{marginRight:300,fontWeight:450}}>BOOK MANAGEMENT</h4>
                  <Button color='info' onClick={()=>{

                  }}><NavLink to='/admin/book-step-1' style={{color:'white'}}> Add Book </NavLink></Button>
                 </div>
                </CardHeader>
                <CardBody style={{}}>
                </CardBody>
               </Card>
            </Col>
           </Row>  */}
           <Row style={{objectFit:'fill',overflow:'hidden'}}>
             <Col md="12">
             <div>
            <Card style={{}}>
            {visible ===false &&<>
              <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: "flex-end", alignItem: "center" }}>
                <CardTitle tag="h4" style={{marginRight: "auto", marginLeft: "auto", fontWeight: 300}}>BOOK MANAGEMENT</CardTitle>
                <NavLink to={{
                  pathname:'/admin/book-step-1',
                  setVisible:setVisible
                }
                  } exact style={{color:'white'}}> <Button color='info'>Add Book <Add /></Button> </NavLink>
              </CardHeader>
            </> }
            
              { visible === false && <CardHeader></CardHeader>}
               <CardBody style={{paddingTop:'0px'}}> 
                 <Col>
                 <Row>
                  <Book_List setProgressVisible={setProgressVisible} setVisible={setVisible} 
                    setProgressValue={setProgressValue} >
                  </Book_List>

                 </Row>
                 </Col>
               </CardBody> 
               {visible === false && <CardFooter></CardFooter>}
              </Card>
              
              
        </div>
             </Col>
           </Row>
        </div>
      </>
    );
  }
export default Book_Add_Page;