import React from 'react'
import {Button} from "reactstrap";


function DeleteQuizModal({setDeleteQuizModal,deleteQuiz}) {

    const deleteRecord= ()=>{
        // console.log('data...',data,'id',data)
        
        deleteQuiz()
        setDeleteQuizModal(prev=>!prev)
    }

    return (
    <div>
        <p >Do you want to delete this Quiz</p>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Button color='info' onClick={deleteRecord}>Yes</Button>
        <Button  onClick={()=>setDeleteQuizModal(prev=>!prev)}>No</Button>
        </div>
    </div>
    )
}

export default DeleteQuizModal
