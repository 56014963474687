import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';

import TextField from '@material-ui/core/TextField';
import {useHistory} from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useForm} from 'react-hook-form'
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {db} from './db/config'
import * as firebase from 'firebase'
import { ErrorOutlineSharp } from '@material-ui/icons';
import * as yup from 'yup'
import {Input,FormGroup,Form,} from 'reactstrap'
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  body: {
    width: "50%",
    height: "100%",
    minHeight: "100vh",
  },
  root: {
    marginLeft: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {

  
const pathOptions = [
  {
      value:"Dashboard",
      path: "/dashboard",
  },{
      value:"User_Management",    
      path: "/tables",

  },{
      value:"Student_Management",
      path: "/student",

  },{
      
      value:"Book_Management",
      path: "/book",


  },{
      
      value:"Quiz_Management",
      path: "/quiz",

  },{
      
      value:"Package_Management",
      path: "/package",

  },
  // {
      
  //     value:"Transaction_Management",
  //     path: "/transaction",

  // },
  {
      
      value:"Chat",
      path: "/chat",

  },{
      
      value:"Reports",
      path:'/',
  },]
  

  let loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
  });
  
  const classes = useStyles();
  const {register,handleSubmit,errors} = useForm()  
  const [emailError,setEmailError] = useState('')
  const [passwordError,setPasswordError] = useState('');
  const [visible, setVisible] = useState(false);
  const history = useHistory()
    

    //  firebase.auth().onAuthStateChanged(function(user){
    //    if(user){
    //      console.log("my user is ",user)
    //      history.push('/admin/dashboard')
    //      return
    //    }
    //  })
   
    return (
      
      
      <div style={{ overflow:'hidden',height:window.innerHeight,width:window.innerWidth,background: `url(${'https://crimsonbeans-my.sharepoint.com/personal/honey_yadav_crimsonbeans_co_in/Documents/Microsoft%20Teams%20Chat%20Files/back.jpg'})`}}>
        <Container  className={classes.root} component='main' maxWidth='xs' style={{margin: "auto", marginTop: 150}}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> 
          <Typography component="h1" variant="h2" style={{color:'black'}}>
            Sign in
          </Typography>
          <Form className={classes.form} noValidate onSubmit={handleSubmit(data=> {
            console.log("hnadle submit executed")
             let email = data.email
             let password = data.password
             if(email.indexOf('@')===-1||email.indexOf('.')===-1){
               setEmailError('')
               setPasswordError("Email is not in valid format")
               return
             }
             
              else{
                   setPasswordError('')
                firebase.auth().signInWithEmailAndPassword(email, password).then(function(user) {
                  db.collection('zSystemUsers').doc(user.user.uid)
                    .get()
                    .then(querySnapshot => {
                      let data = querySnapshot.data()

                      let currentPermission = pathOptions.filter((item)=> data.permissions.find(v => v.value == item.value));
                      console.log('currentPermission',currentPermission)
                      if(currentPermission.length !== 0){

                         props.history.replace(`/admin${currentPermission[0].path}`)
                      }
                      console.log("user is sign inned")
                    }).catch(err=>console.log(err))
                }).catch(function(error){
                 setEmailError('Wrong email or password')
                 console.log(error.code);
                 console.log(error.message);
                 
               });
              }                           
          })}>
  
        
        <Grid container style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
              <Grid item xs>
               
                {emailError!=='' && <p style={{color:"#ff0066",textAlign:'center',marginTop:20}}>{emailError}</p> }
              </Grid>
              
            </Grid>
            <FormGroup style={{marginBottom:35,marginTop:emailError===""?25:5}}>
            <Input  style={{marginTop:20}}
                   name='email'
                   placeholder="Email"
                  type='email'
                  innerRef={register({required:true,})} 
                  style={{color:'black', background: "#fff"}}
                  
                  />
                  {passwordError!=="" && <p style={{color:'#ff0066',textAlign:'center'}}>{passwordError}</p>}
                  {/* {emailError!==" "&&<p style={{color:'red'}}>{emailError}</p>} */}
                  </FormGroup>
            <FormGroup>
            <Input style={{marginTop:50}} name="password"
            placeholder="Password"
            type={visible ? 'text' : 'password'}
            innerRef={register({required:true,})}
            style={{color:'black', background: "#fff"}}
            />
            {
              visible ? 
              <VisibilityIcon style={{position:'absolute',right:'0px',margin:'10px',cursor:'pointer', top: -3}} onClick={()=>setVisible(prev=>!prev)} />
              :
              <VisibilityOffIcon style={{position:'absolute',right:'0px',margin:'10px',cursor:'pointer', top: -3}} onClick={()=>setVisible(prev=>!prev)} />
            }
            {/* {passwordError!=="" && <p style={{color:'red'}}>{passwordError}</p>} */}
            </FormGroup>
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </Form>
        </div>
        
      </Container>
      </div>
    )
      
   }
{/* <Box mt={8}>
        <Copyright />
        </Box> */}
 {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}


   {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
  
 {/* <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({required:true,})}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={errors.email?true:false}
              
            /> */}
            {/* {errors.email && errors.email.message} */}
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputRef={register({required:true,minLength:6})}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password?true:false}
              />
  
            {errors.password&&<p>{errors.password}</p>} */}
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}