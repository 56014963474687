/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useForm } from 'react-hook-form'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import Select from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';


import '../db/config'
import * as firebase from 'firebase';
import { db } from "../db/config";
// import styles from './sign_up_form.module.css'
const functions = firebase.functions();

function SignUp({ onClick, setUserData, notify }) {
  const { register, handleSubmit, errors } = useForm()
  const [visible, setVisible] = useState(false)
  const [mobileError, setMobileError] = useState('')

  //added this for the options 
  const options = [
    // { label: 'Dashboard', value: 'Dashboard' },
    { label: 'User Management', value: 'User_Management' },
    { label: 'Student Management', value: 'Student_Management' },
    { label: 'Book Management', value: 'Book_Management' },
    { label: 'Quiz Management', value: 'Quiz_Management' },
    { label: 'Package Management', value: 'Package_Management' },
    // { label: 'Transaction Management', value: 'Transaction_Management' },
    { label: 'Batch Management', value: 'Batch_Management' },
    { label: 'Enquiry', value: 'Enquiry' },
    { label: 'Feedback', value: 'Feedback' },
    { label: 'Chat', value: 'Chat' },
    { label: 'Media Center', value: 'Media_Center'},
    { label: 'Discount Management', value: 'Discount_Management'},
    { label: 'Notification Management', value: 'Notification_Management'},
    { label: 'Invoice Management', value: 'Invoice_Management'},
    // { label: 'Reports', value: 'Reports' },


  ];
  //added this for the state to pass to firebase
  const [selectedPermission, setselectedPermission] = useState([])
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#272a3d' : 'black',
      padding: 10,
    }),
    control: () => ({

      display: 'none'
    }),

  }

  return (

    <div className="content" >
      <Row >
        <Col md="12">
          <Card style={{ margin: 0 }}>
            <CardHeader>
              <h5 className="title">User Registration</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(data => {
                setVisible(true)
                // console.log(data.email,data.password)
                let email = data.email
                let password = data.password
                // console.log(data)

                if (data.mobile < 6000000000) {
                  setMobileError('invalid mobile number')
                }
                else {

                  // onClick()
                  // setMobileError('')

                  const userData = {
                    firstname: data.firstname,
                    lastname: data.lastname,
                    mobile: data.mobile,
                    isAdmin: true,
                    disabled: false,
                    email: data.email,
                    permissions: selectedPermission,//added this element to the firebase
                    password: data.password,
                    username: data.username,
                  }

                  const addSystemUser = functions.httpsCallable('addSystemUser');
                  addSystemUser(userData).then(result => {
                    if(result.data.hasError === false){
                      
                      const _uniqueID = result.data.user.uid;
                      const finalData = { ...userData, _uniqueID }
                      db.collection('zSystemUsers').doc(_uniqueID.toString()).set(finalData).then(function () {
                        setUserData((prev) => [...prev, finalData])
                        onClick()
                        notify("User created successfully",false)
  
                      }).catch(function (error) {
                        console.log('this is error', error)
                        notify(error.toString(),true)
                      })
                      console.log(result);
                    }else{
                      notify(result.data.message.errorInfo.message,true)

                    }
                    }).catch((err) => {
                      notify(err.message !== undefined ? err.message.toString() : err.toString() || 'Something went wrong' ,true)
  
                    })

                  // firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                  //   // console.log(user)
                  //   // console.log(user.user.uid)
                  //   let _uniqueID = user.user.uid
                  //   const finalData={
                  //     firstname: data.firstname,
                  //     lastname: data.lastname,
                  //     mobile: data.mobile,
                  //     role: 'admin',
                  //     status: 'enable',
                  //     email: data.email,
                  //     permissions: selectedPermission,//added this element to the firebase
                  //     password: data.password,
                  //     username: data.username,
                  //     _uniqueID,
                  //   }
                  //   db.collection('zSystemUsers').doc(_uniqueID.toString()).set(finalData).then(function () {
                  //     // console.log('documnet successfully inserted')
                  //     notify("User Created Successfully")
                  //     setUserData((prev)=>[...prev,finalData])
                  //   }).catch(function (error) {
                  //     console.log('this is error', error)
                  //   })
                  // }).catch(function (error) {
                  //   var errorCode = error.code;
                  //   var errorMessage = error.Message;
                  // })

                }

              }
              )
              }
              >
                <Row className='lg' md='1'>

                  <Col className="lg" md="3">
                    <FormGroup>
                      <label>First Name</label>
                      <Input
                        innerRef={register({ required: true })}
                        name='firstname'
                        placeholder="First Name"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="3">
                    <FormGroup>
                      <label>Last Name</label>
                      <Input
                        innerRef={register}
                        name='lastname'
                        placeholder="Last Name"
                        type="text"

                      />
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label>
                        Username
                          </label>
                      <Input placeholder="username" type="text" name='username' required
                        innerRef={register({ required: true, minLength: 6 })} />

                      {errors.username && errors.username.type === 'minLength' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Username should be atleast 6 digits.</span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        innerRef={register({ required: true, })}
                        name='email'
                        placeholder="user@user.com"
                        type="email"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label>Password</label>
                      <Input
                        innerRef={register({ required: true, minLength: 6 })}
                        required
                        name='password'
                        type="password"
                        placeholder='Password'
                      />

                      {errors.password && errors.password.type === 'minLength' && (
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}} >Password should be atleast 6 digits.</span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label>Mobile</label>
                      <Input
                        innerRef={register({required: true, minLength: 10, maxLength: 10,  min: 6000000000 })}
                        name='mobile'
                        placeholder="Mobile"
                        type="number"
                        required
                      />
                      {errors.mobile && errors.mobile.type !== 'required' &&
                        <span style={{fontSize:'10px',position:'absolute',bottom:'-20px',color:'red'}}  >Please Enter Valid Mobile No.</span>
                      }

                      {/* {errors !== '' ? <p style={{ color: 'red' }}>{mobileError}</p> : ''} */}
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup className="permissions-multiselect">

                      <label>Account Permission</label>
                      <ReactMultiSelectCheckboxes
                        styles={customStyles}
                        onChange={(option) => {
                          setselectedPermission(option)
                        }}
                        options={options} />
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  {/* {visible === false ? <Button className="btn-fill" color="info" type="submit">
                    Save
                  </Button> : <Button onClick={onClick} className="btn-fill" color="info">Done</Button>}
                  {visible === false && <Button className="btn-fill" color="info" onClick={onClick}>Back</Button>} */}
                  <Button color='info' type='submit'>Submit</Button>
                  <Button onClick={onClick}>Back</Button>
                </div>
              </Form>
            </CardBody>

          </Card>
        </Col>

      </Row>
    </div >
  );
}


export default SignUp;
