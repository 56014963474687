import React, { useEffect, useState, useRef } from 'react'
import NotificationAlert from "react-notification-alert";
import {
    Button,
    Card,
    Input,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    Table,
    Progress,

} from "reactstrap";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { useForm } from "react-hook-form";
import { CSVLink } from "react-csv";
import {format} from "date-fns";


import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';


function TransactionTable() {
    const limit = 10;

    const notification = useRef();
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)

    const [transactionData, setTransactionData] = useState([])
    const [isfilter, setIsFilter] = useState(false)

    const { handleSubmit, register, errors, reset } = useForm();

    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(0)

    const [clicked, setClicked] = useState(false)


    const headers = [
        { label: "From", key: "from" },
        { label: "To", key: "to" },
        { label: "Reference No.", key: "referenceNo" },
        { label: "Time", key: "createdAt" },
        { label: "By", key: "userName" },
        { label: "Amount", key: "amount" },
        { label: "Status", key: "status" },


    ];

    const notify = (msg, err) => {
        let color = err === true ? "danger" : "success"
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
                    </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }

    const getTransaction = () => {
        setProgressValue(99)

        db.collection('transaction').orderBy('createdAt', 'desc').limit(limit).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            
            data = data.map(item => {
                let time = new Date(item.createdAt.seconds * 1000).toLocaleString()

                return ({
                    createdAtActual: item.createdAt,

                    id: item.id,
                    from: item.email,
                    createdAt: time,
                    referenceNo: item.referenceNo,
                    amount: item.amount,
                    userID: item.userID,
                    userName: item.userName,

                    status: item.status
                })

            })
            setProgressVisible(false)

            setTransactionData(data)
            setEndIndex(data.length)

        })
    }

    useEffect(() => {
        getTransaction()
    }, [isfilter])

    const onSubmit = (data) => {
        document.querySelector("#loading-indicator").style.display = "flex";

        let fromDate = new Date(data.fromDate)
        let toDate = new Date(data.toDate)
        db.collection('transaction').orderBy('createdAt', 'desc').where('createdAt', '>=', fromDate).where('createdAt', '<=', toDate).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            data = data.map(item => {
                let time = new Date(item.createdAt.seconds * 1000).toLocaleString()

                return ({
                    createdAtActual: item.createdAt,

                    id: item.id,
                    from: item.email,
                    createdAt: time,
                    referenceNo: item.referenceNo,
                    amount: item.amount,
                    userID: item.userID,
                    userName: item.userName,
                    status: item.status,
                })

            })
            document.querySelector("#loading-indicator").style.display = "none";

            setTransactionData(data)
        }).catch(err=>{
                                
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
            console.log(err.toString())
          })

    }



    const nextSet = () => {
            document.querySelector("#loading-indicator").style.display = "flex";

        // if (transactionData.length === limit) {
        //     setClicked(true)
            db.collection('transaction').orderBy('createdAt', 'desc').startAfter(transactionData[transactionData.length-1].createdAtActual).limit(limit).get()
                .then(querySnapshot => {
                    let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                    data = data.map(item => {
                        let time = new Date(item.createdAt.seconds * 1000).toLocaleString()

                        return ({
                            createdAtActual: item.createdAt,

                            id: item.id,
                           
                            from: item.email,

                            createdAt: time,
                            referenceNo: item.referenceNo,
                            amount: item.amount,
                            userID: item.userID,
                            userName: item.userName,
                            status: item.status,
                        })

                    })
                    if (data.length !== 0) {
                        document.querySelector("#loading-indicator").style.display = "none";

                        setTransactionData(data)
                        setStartIndex(endIndex + 1)
                        setEndIndex(prev => prev + data.length)

                    } else {
                        document.querySelector("#loading-indicator").style.display = "none";

                        notify('End Of the list', true)
                    }

                }).catch(err=>{
                                
                    document.querySelector("#loading-indicator").style.display = "none";
                    notify(err.toString(), true);
                    console.log(err.toString())
                  })
        // } else {
        //     notify('End Of the list', true)
        // }
    }

    const prevSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('transaction').orderBy('createdAt')
            .startAfter(transactionData[0].createdAtActual).limit(limit).get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                

                data = data.map(item => {
                    let time = new Date(item.createdAt.seconds * 1000).toLocaleString()

                    return ({
                        createdAtActual: item.createdAt,

                        id: item.id,
                        from: item.email,
                        createdAt: time,
                        referenceNo: item.referenceNo,
                        amount: item.amount,
                        userID: item.userID,
                        userName: item.userName,
                        status: item.status,
                    })

                })
                if (data.length !== 0) {
                    document.querySelector("#loading-indicator").style.display = "none";

                    data = data.reverse()
                    setStartIndex(prev => prev - data.length)
                    setEndIndex(prev => prev - transactionData.length)
                    setTransactionData(data)
                } else {
                    document.querySelector("#loading-indicator").style.display = "none";

                    // setClicked(false)

                    console.log('Can not go back')
                }
            }).catch(err=>{
                                
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(), true);
                console.log(err.toString())
              })
    }


    return (
        <>
      {progressVisible ? <Progress color='info' value={progressValue} style={{ height: 4 }} />
        :
        <div className="content">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>

            <Row>
                <Col md="12">
                    <Card >
                        <CardHeader style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                            <CardTitle tag="h4" >TRANSACTION MANAGEMENT</CardTitle>
                            {/* <Button color='info' onClick={() => setIsModalVisible(true)}>Create User <Add /></Button> */}
                        </CardHeader>
                        <CardBody>

                            <Row>
                                <Col sm={11}>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <FormGroup row style={{ justifyContent: "flex-end",height:'50px' }}>
                                            {
                                                isfilter ? (
                                                    <>
                                                        <Col sm={1}>
                                                            <Label style={{ minWidth: '70px' }} for="fromData"> From Date</Label>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Input
                                                                innerRef={register({ required: true })}
                                                                type="date"
                                                                name="fromDate"
                                                                max={new Date(new Date().setHours(new Date().getHours() + 6)).toISOString().substr(0,10)}

                                                                placeholder="From Date "
                                                            />
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Label for="toData"> To Date</Label>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Input
                                                                innerRef={register({ required: true })}
                                                                type="date"
                                                                name="toDate"
                                                                max={new Date(new Date().setHours(new Date().getHours() + 6)).toISOString().substr(0,10)}
                                                                placeholder="From Date"
                                                            />
                                                        </Col>
                                                        <Col style={{ paddingLeft: '0px' }} sm={3}>
                                                            <Button style={{ margin: "0px", padding: '6px 10px' }} color="info" type='submit'><SearchIcon /></Button>

                                                        </Col>
                                                    </>
                                                ) : (

                                                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                                                            <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                                                                {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                                                            </p>
                                                            <div style={{ position: 'relative', top: '-5px', marginRight: '0px' }}>
                                                            {
                                                                startIndex > 1  &&
                                                                <IconButton style={{ outline: 'none' }}>
                                                                    <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                                                        onClick={() => {

                                                                            prevSet()
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            }
                                                                <IconButton style={{ outline: 'none' }}
                                                                    onClick={() => nextSet()}
                                                                >
                                                                    <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                                                                </IconButton>
                                                            </div>
                                                        </Col>
                                                    )

                                            }
                                            <Col style={{ paddingRight: '0px' }} sm={1}>
                                                <Button style={{ margin: "0px", padding: '6px 10px' }} color={isfilter && "info"} onClick={() => setIsFilter(!isfilter)}>
                                                    <FilterListIcon />
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>

                                <Col style={{ paddingLeft: '0px' }} sm={1}>

                                    <Button style={{ margin: "0px", padding: '6px 10px' }} >
                                        <CSVLink
                                            data={transactionData}
                                            headers={headers}
                                            filename={`transactions ${new Date().toLocaleString()}.csv`}
                                            asyncOnClick={true}
                                        >
                                            <GetAppIcon style={{ color: 'white' }} />

                                        </CSVLink>
                                    </Button>
                                </Col>
                            </Row>
                            <Table className="tablesorter" >
                                <thead className="text-primary">
                                    <tr>
                                        <th>Email</th>
                                        {/* <th>To</th> */}
                                        <th style={{textAlign: "center"}}>Time</th>
                                        <th style={{textAlign: "center"}}>Reference No</th>
                                        {/* <th>By User</th> */}

                                        <th style={{textAlign: "center"}}>Amount</th>
                                        <th style={{textAlign: "center"}}>Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                     transactionData.length === 0 && <tr> 
                                         <td></td>
                                         <td></td>
                                         <td style={{position:'relative', left:'10%'}}>No Data Found</td>
                                         <td></td>
                                         <td></td>
                                     </tr>   
                                    }
                                    {transactionData.map(item => {
                                        // let time = new Date(item.createdAt.seconds * 1000).toString().slice(0, 21)
                                        // let status = user.status && [];
                                        console.log('date', item);
                                        return (

                                            < tr key={item.id} >
                                                <td>{item.from}</td>
                                                {/* <td>{item.to}</td> */}
                                                <td style={{textAlign: "center"}}>{ format(new Date(item.createdAt), "dd-MM-yy hh:mm aa")}</td>
                                                <td style={{textAlign: "center"}}>{item.referenceNo}</td>
                                                <td style={{fontFamily: 'Roboto, sans-serif', textAlign: "center"}}>₹ {item.amount}</td>

                                                <td style={{textAlign: "center"}}>
                                                    {
                                                        item.status === 'success' ? (

                                                            <CheckCircleIcon style={{ fontSize: '35px', color: '#17de3e' }} />
                                                        ) : (

                                                                <ErrorIcon style={{ fontSize: '35px', color: '#f51d1d' }} />
                                                            )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        }
        </>
    )
}

export default TransactionTable
