import React from 'react';
import {Button} from "reactstrap";

const DeclineDiscount = ({setModal, declineRequest}) => {

    const onclick= ()=>{
        setModal(false)

    }

    const decline = () => {
        declineRequest()
        setModal(false)
    }

    return (
        <div>
            <p >Do you want to Decline this request ?</p>
            <div style={{display:'flex',flexDirection:'row'}}>
            <Button color='info' onClick={decline}>Yes</Button>
            <Button  onClick={onclick}>No</Button>
            </div>
        </div>
    )
}

export default DeclineDiscount
