import axios from "axios";

const api = async ({ method = "POST", path, data = {} }) => {
  const basePath = "https://mailer.tncollege.online";
  var config = {
    method,
    url: `${basePath}${path}`,
    data: data,
  };

  return axios(config);
};

export default api;
