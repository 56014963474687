
import React, { useState, useEffect, useRef } from "react";
// import { Document, Page } from "react-pdf/dist/entry.webpack";

import { PDFReader } from 'reactjs-pdf-reader';

import { MobilePDFReader } from 'reactjs-pdf-reader';
import { IconButton } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import RemoveIcon from '@material-ui/icons/Remove';

// import examplePdf from "./sample.pdf";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { useLocation, useHistory, Link } from "react-router-dom";

import './PDF.css'
// 'https://firebasestorage.googleapis.com/v0/b/tn-edu.appspot.com/o/sample.pdf?alt=media&token=9ed0b888-87a8-4291-ae0f-b4388b644bd0'

const PdfViewer = ({ page, clickedFile, scale, currentFile, currentFileMob, setTotalPage }) => {

  const [bookURL, setBookURL] = useState('');
  console.log('url,,,,,', bookURL)

  useEffect(() => {
    if (currentFile) {
      console.log('current file type', currentFile.type)
      if (currentFile.type === 'application/pdf' || currentFileMob.type === 'application/pdf') {

        if (clickedFile === 'web') {
          if (currentFile.type === 'application/pdf') {

            let bookURLWeb = URL.createObjectURL(currentFile);
            setBookURL(bookURLWeb)
            console.log('currentFileurl ', bookURLWeb)
          } else {
            setBookURL(currentFile)

          }

        } else if (clickedFile === 'mob') {
          if (currentFileMob.type === 'application/pdf') {

            let bookURLMob = URL.createObjectURL(currentFileMob);
            setBookURL(bookURLMob)
            console.log('currentFileurl mob', bookURLMob)
          } else {
            setBookURL(currentFileMob)

          }

        }
      } else {
        if (clickedFile === 'web') {

          // let bookURLWeb = URL.createObjectURL(currentFile);
          setBookURL(currentFile)
          console.log('currentFileurl ', currentFile)

        } else if (clickedFile === 'mob') {
          // let bookURLMob = URL.createObjectURL(currentFileMob);
          setBookURL(currentFileMob)
          console.log('currentFileurl mob', currentFileMob)

        }
      }


    }
  }, [])

  return (
    <>
      {bookURL &&

        <PDFReader url={bookURL}
          page={page}
          scale={scale}
          onDocumentComplete={(e) => {
            setTotalPage(e)
            console.log('complete', e)
          }}
        />
      }
    </>
  )
}

const PDF = ({ handleClose, currentFile, currentFileMob, clickedFile, editBook }) => {
  const [page, setPage] = useState(1)
  const [scale, setScale] = useState(1)

  const [totalPage, setTotalPage] = useState(1)


  let currentFileurl;

  const minsPage = () => {
    let pr = page
    if (pr > 1) {

      setPage(prev => prev - 1)
    }

  }

  const plusPage = () => {
    let total = page
    if (total < totalPage) {

      setPage(prev => prev + 1)
    }

  }


  const minsScale = () => {
    
    if (scale > 1) {
      setScale(prev => prev - 1)

    }

  }

  const plusScale = () => {
    // let totalsc=page
    // if(totalsc<totalPage){
    console.log(scale)
    if(scale < 3){
      setScale(prev => prev + 1)

    }
    // }

  }


  return (

    // <FullScreen handle={handle}>
    <div className='PDF_container' >

      <div className='PDF_header' >
        <IconButton onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>

        {editBook ?
          <h4 style={{ color: 'black', textTransform: 'uppercase' }}>{editBook}</h4>

          : <>{
            clickedFile === 'web' ?
              <h4 style={{ color: 'black', textTransform: 'uppercase' }}>{currentFile.name.slice(0, -4)}</h4>
              :
              <h4 style={{ color: 'black', textTransform: 'uppercase' }}>{currentFileMob.name.slice(0, -4)}</h4>

          }
          </>
        }
        <div className='PDF_header_zoom'>
          <IconButton style={{ borderRadius: '2px', border: '1px solid', maxHeight: '40px' }}
            onClick={plusScale}
          >
            <AddIcon />
          </IconButton>

          <IconButton style={{ borderRadius: '2px', border: '1px solid', marginRight: '20px', maxHeight: '40px' }}
            onClick={minsScale}
          >
            <RemoveIcon />
          </IconButton>


        </div>

      </div>

      <div style={{ overflow: 'scroll', height: '94vh' }}>
        <PdfViewer scale={scale} bookURL={currentFileurl} currentFileMob={currentFileMob} clickedFile={clickedFile} currentFile={currentFile} page={page} setTotalPage={setTotalPage} />
        {/* <PDFReader url='https://upload.wikimedia.org/wikipedia/commons/9/9d/Biuletyn_1_2008.pdf'
          page={page}
          // isShowHeader={false}
          // isShowFooter={false}
          scale={scale}
          onDocumentComplete={(e)=>{
            setTotalPage(e)
            console.log('complete',e)}}
          /> */}
      </div>

      <div className='PDF_footer'>
        <div className='PDF_footer_page'>

          <IconButton style={{ border: '1px solid', marginRight: '20px', maxHeight: '45px', maxWidth: '45px' }}>
            <ArrowBackIosIcon style={{ position: 'relative', left: '4px', fontSize: '1.6rem' }}
              onClick={minsPage}
            />

          </IconButton >
          <div className='PDF_footer_page_no'>
            <h2 style={{ color: 'black' }}>{page} </h2><h1 style={{ color: 'black' }}>/</h1><span style={{ color: 'black' }}>{totalPage}</span>
          </div>
          <IconButton style={{ border: '1px solid', marginRight: '20px', maxHeight: '45px', maxWidth: '45px' }}>
            <ArrowForwardIosIcon onClick={plusPage} />

          </IconButton>
        </div>



      </div>
    </div>
    // </FullScreen>
  );
}

export default PDF;

