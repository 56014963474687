import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
  } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import firebase from 'firebase';
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";

const AcceptDiscount = ({setModal, id, currentRequestData, notify, setRequestData}) =>{
    const { register, handleSubmit, errors, control, getValues } = useForm();

    const addDiscountNotification = (finalData) => {
      db.collection("notifications")
      .doc()
      .set(finalData)
      .then((res) => {
        notify("Notification submitted",false);
        setModal(false);
      })
      .catch((err) => notify(err.toString, true));
    }

    const onSubmit = (data) =>{
        console.log("data:",data);
        let res_one = db.collection('DiscountCoupons').doc()
        let res_two = db.collection('DocumentRequest').doc(currentRequestData.itemID)

        let finalData_one = {}
        let finalData_two = {}
        let finalNotificationData = {}
        finalData_one = {
            itemID: res_one.id,
            couponCode: data.couponCode,
            discountType: currentRequestData.reqType,
            discountAmount: parseFloat(data.discountAmount),
            discountIn: data.discountIn,
            validFrom: data.validFrom,
            validTill: data.validTill,
            couponFor: {
              stuID: currentRequestData.studentId,
              stuName: currentRequestData.studentName
            },
            used: false,
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
            type: 'Special'
        }

        finalData_two = {
            status: 'Approved',
            couponCode: data.couponCode,
            used: false
        }

        finalNotificationData = {
          type: "Single",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          message: "Your coupon request is accepted.Please see coupon in profile section",
          stuId:[currentRequestData.studentId],
        }
        res_one.set(finalData_one).then(() => console.log("data sent to database"))
            .catch(err => notify(err.toString(), true))
        
        res_two.set(finalData_two, {merge: true}).then(() => {
          notify('Coupon Accepted', false)
          setRequestData(prev => prev.map((item) => item.itemID == currentRequestData.itemID ? ({ ...item, status: "Approved" }) : item))
          addDiscountNotification(finalNotificationData)
        })
        .catch(err => notify(err.toString(), true))
        
        
    }

    return (
        <>
      <div className="content" style={{ width: 1150 }}>
        <Row>
          <Col md="8">
            <Card style={{ border: "2px solid white" }}>
              <CardHeader>
                <h5 className="title">Accept Discount Request</h5>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Coupon Code</label>
                        <Input
                          innerRef={register({ required: true, maxLength: 10, minLength: 10, pattern: {
                            value: /^(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&]{8,}$/
                          }})}
                          defaultValue={`SPE${id.toUpperCase()}`}
                          name="couponCode"
                          placeholder="Coupon Code"
                          type="text"
                          required
                        />
                        {errors.couponCode && errors.couponCode.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                          {errors.couponCode && errors.couponCode.type === "minLength" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Minimum length for couponcode is 10
                          </span>
                        )}
                         {errors.couponCode && errors.couponCode.type === "maxLength" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Maximum length for couponcode is 10
                          </span>
                        )}
                        {errors.couponCode && errors.couponCode.type === "pattern" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Please enter valid code
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Type</label>
                        <Input
                          defaultValue = {currentRequestData.reqType}
                          innerRef={register({ required: true })}
                          type="text"
                          name="DiscountType"
                          required
                          disabled
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Valid From</label>
                        <Input
                        //   defaultValue={currentSpecialData && currentSpecialData.validFrom}
                          innerRef={register({ required: true })}
                          name="validFrom"
                          placeholder="Valid From"
                          type="datetime-local"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Valid Till</label>
                        <Input
                        //   defaultValue = {currentSpecialData && currentSpecialData.validTill}
                          innerRef={register({register: true})}
                          name="validTill"
                          placeholder="Valid Till"
                          type="datetime-local"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Discount Of</label>
                        <Row>
                          <Col md="4">
                            <Input
                            //   defaultValue ={currentSpecialData && currentSpecialData.discountIn}
                              innerRef={register({ required: true })}
                              type="select"
                              name="discountIn"
                              required
                            >
                              <option value={"Rupees"}>Rupees</option>
                              <option value={"Percentage"}>Percentage</option>
                            </Input>
                          </Col>
                          <Col md="8">
                            <Controller render={(field) => {
                              return <Input
                                // defaultValue={currentSpecialData && currentSpecialData.discountAmount}
                                {...field}
                                innerRef={register({ required: true })}
                                name="discountAmount"
                                placeholder="Discount OF"
                                type="number"
                                onChange={(e) => {
                                  if(getValues().discountIn == 'Percentage' && parseFloat(e.target.value || 0) <= 100){
                                    field.onChange(e.target.value);
                                  }
                                }}
                              />
                            }}
                            name="discountAmount"
                            control={control}
                             />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Student Name</label>
                        <Input 
                          name="studentName"
                          defaultValue={currentRequestData.studentName}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Remarks</label>
                        <Input
                          innerRef={register({ required: true })}
                          name="remarks"
                          placeholder="Remarks"
                          type="textarea"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <br></br>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button color="info" type="submit">
                      Submit
                    </Button>
                    <Button onClick={() => setModal(false)}>Back</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
    )
}

export default AcceptDiscount;