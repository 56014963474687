/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Book_Add_Page from "Book_Management_Module/Book_Add_page";
import Book_Add_Step1 from "Book_Management_Module/Book_Add_Step/Book_Add_Step1";
import Book_List from "Book_Management_Module/Book_List";
import Students_Table from "STUDENT_MODULE/student_info_table";
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import EnquiryTable from "components/Enquiry/EnquiryTable";
import FeedbackTable from "components/Feedback/FeedbackTable"
import Quiz_Management from './components/Quiz_Management/Quiz_Management'
import TransactionTable from "components/TransactionManagement/TransactionTable";
import ChatDialog from './components/Chat/ChatDialog'
import PackageManagement from './components/PackageManagement/PackageManagement'
import DiscountManagement from "components/DiscountManagement/DiscountManagement";
import BatchList from "components/Batch/BatchList";
import NotificationList from "components/NotificationCenter/NotificationList";
import MediaCenter from "components/MediaCenter/MediaCenter";
import InvoceList from "components/InvoiceManagement/InvoceList";


//changed all the names to match with the firebase data

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   id: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/map",
  //   name: "Map",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   component: Map,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  {
    path: "/tables",
    name: "User Management",
    id: "User_Management",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-02",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/student",
    name: "Student Management",
    id: "Student_Management",

    rtlName: "قائمة الجدول",
    icon: "fas fa-users",
    component: Students_Table,
    layout: "/admin"
  },
  {
    path: "/batch",
    name: "Batch Management",
    id: "Batch_Management",

    rtlName: "قائمة الجدول",
    icon: "fas fa-users",
    component: BatchList,
    layout: "/admin"
  },
  {
    path: "/book",
    name: "Book Management",
    id: "Book_Management",

    rtlName: "طباعة",
    icon: "tim-icons icon-book-bookmark",
    component: Book_Add_Page,
    // component:Book_List,
    layout: "/admin"
  },

  {
    path: "/transaction",
    name: "Transaction Management",
    id: "Transaction_Management",
    //   rtlName: "ار تي ال",
    icon: "fas fa-rupee-sign",
    component: TransactionTable,
    layout: "/admin"
  },
  {
    //   path: "/icons",
    name: "Reports",
    id: "Reports",

    //   rtlName: "الرموز",
    icon: "tim-icons icon-align-center",
    //   component: Icons,
    //   layout: "/admin"
  },
  {
    path: "/book-step-1",
    // name: "Book Management",
    // rtlName: "طباعة",
    // icon: "tim-icons icon-align-center",  
    component: Book_Add_Step1,
    layout: "/admin"
  },
  {
    path: "/quiz",
    name: "Quiz Management",
    id: "Quiz_Management",
    icon: "fas fa-gamepad",
    component: Quiz_Management,
    layout: "/admin"
  },
  {
    path: "/enquiry",
    name: "Enquiry",
    id: "Enquiry",
    //   rtlName: "ار تي ال",
    icon: "fas fa-question-circle",
    component: EnquiryTable,
    layout: "/admin"
  },
  {
    path: "/feedback",
    name: "Feedback",
    id: "Feedback",
    //   rtlName: "ار تي ال",
    icon: "fas fa-question-circle",
    component: FeedbackTable,
    layout: "/admin"
  },
  {
    path: "/chat",
    name: "Chat",
    id: "Chat",
    icon: "fas fa-comments",
    component: ChatDialog,
    layout: "/admin"
  },
  {
    path: "/package",
    name: "Package Management",
    id: "Package_Management",
    icon: "fas fa-briefcase",
    component: PackageManagement,
    layout: "/admin"
  },
  {
    path: "/discountManagement",
    name: "Discount Management",
    id: "Discount_Management",
    icon: "fas fa-briefcase",
    component: DiscountManagement,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notification Management",
    id: "Notification_Management",
    icon: "far fa-bell",
    component: NotificationList,
    layout: "/admin"
  },
  {
    path: "/mediaCenter",
    name: "Media Center",
    id: "Media_Center",
    icon: "fab fa-medium",
    component: MediaCenter,
    layout: "/admin"
  },
  {
    path: "/invoices",
    name: "Invoice Management",
    id: "Invoice_Management",
    icon: "fas fa-bell",
    component: InvoceList,
    layout: "/admin"
  },
];
export default routes;
