import React, { useState, useEffect, useRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Table,
    Col,
    Progress,

    CustomInput,
} from "reactstrap";
import Switch from '@material-ui/core/Switch'
import { useForm } from "react-hook-form";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import NotificationAlert from "react-notification-alert";

import firebase from 'firebase'

import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import algoliasearch from 'algoliasearch/lite'
import { algoliaAppID, algoliaSearchID } from '../../Utilities/algoliaKeys'


function QuizDialog({currentQuiz,startIndex, setQuizesData, handleClose, notifi }) {

    // console.log('current Quiz',currentQuiz)
    
    const searchClient = algoliasearch(algoliaAppID,algoliaSearchID);
    const index = searchClient.initIndex('Questions_Search');
    
	const chunkSize = 10;
    const notification = useRef();

    const[searchValue,setSearchValue] = useState('')

    const [questionsData, setQuestionsData] = useState([])
    const [selectedQues, setSelectedQues] = useState({})
    const [selectedSubjects, setselectedSubjects] = useState([])
    const [selectedDifficulty, setselectedDifficulty] = useState([])
    const [selectedQuesCount, setSelectedQuesCount] = useState([])

    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)
    const [reload, setreload]=useState(false)
    const [iswithoutTime, setIswithoutTime]=useState(false)
    const [quizCover, setQuizCover] = useState();    
    const [imageUrl, setimageUrl] = useState('');
    const [quizImage, setQuizImage] = useState();
    const [mockTest, setMockTest] = useState(false)
    const [alert, setalert] = useState(false)
    const { handleSubmit, register, errors, reset } = useForm();

    const subjectOptions = [
        { label: 'Science', value: 'Science' },
        { label: 'Math', value: 'Math' },
        { label: 'English', value: 'English' },
        // { label: '', value: 'Book_Management' },
        // { label: 'Transaction Management', value: 'Transaction_Management' },
        // { label: 'Reports', value: 'Reports' },
        // { label: 'Quiz_Management', value: 'Quiz_Management' }


    ];

    const difficultyOptions = [
        { label: 'Easy', value: 1 },
        { label: 'Medium', value: 2 },
        { label: 'Difficult', value: 3 },
        // { label: 'Level 4', value: 4 },
        // { label: 'Level 5', value: 5 },

    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#272a3d' : 'black',
            padding: 10,
        }),
        control: () => ({

            display: 'none'
        }),

    }

    const notify = (msg,err) => {
        let color = err === true ?'danger':'success'
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ?'Error':'Success'}</b> -{msg}.
              </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notifi.current){
            notification.current.notificationAlert(options)
        }
    }

    const getQuestions = () => {

        db.collection('Questions').orderBy('questionTitle')
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })
                setProgressVisible(false)
                document.querySelector("#loading-indicator").style.display = "none";

                setQuestionsData(data)
                // setEndIndex(data.length)
                console.log('my user data is ', data)
            }).catch(err=>{
                document.querySelector("#loading-indicator").style.display = "none";

                console.log('err',err)
                notify(err.toString(),true)

            })
    }


    const getEditQuestions=()=>{
        let currentQuesArray = currentQuiz.questions
        console.log('in get EDit Questions')
        if (currentQuesArray.length > 10) {
            let ifdata = [];
            const arr = currentQuesArray.map((e, i) => {
                return i % chunkSize === 0 ? currentQuesArray.slice(i, i + chunkSize) : null;
            }).filter(e => { return e; });
            arr.map(item => {
                db.collection("Questions").where("__name__", "in", item).get().then(query => {

                    ifdata = query.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                    setQuestionsData(prev => [...prev, ...ifdata])

                }).catch(err=>{
                document.querySelector("#loading-indicator").style.display = "none";

                notify(err.toString(),true)
                
                console.log('err',err)

                })
            })
            setProgressVisible(false)

            document.querySelector("#loading-indicator").style.display = "none";
        } else {
            
        db.collection('Questions').orderBy('questionTitle') 
            .get()
            .then(querySnapshot => {
                let data = querySnapshot.docs.map(doc => {

                    return { ...doc.data(), id: doc.id }
                })
                let currentQuesArray= {};
                currentQuiz.questions.forEach((item, i) => {
                    currentQuesArray[item] = true
                })

                console.log('current Ques Array',currentQuesArray)
                setProgressVisible(false)
                document.querySelector("#loading-indicator").style.display = "none";

                setSelectedQues(currentQuesArray)
                setQuestionsData(data)
                // setEndIndex(data.length)
                console.log('my user data is ', data)
            }).catch(err=>{
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(),true)
                
                console.log('err',err)

            })
        }
    }

    const selectQuizCover = (event) => {
        console.log(event.target.files[0])
        console.log("my image name", event.target.files[0].name)
        if (!event.target.files[0]) {
          return
        }
        if(event.target.files[0].type=== 'image/jpeg' ||event.target.files[0].type=== 'image/png' ){
    
          try {
            let date = new Date().valueOf();
    
            var uploadTask = firebase.storage().ref().child(`quizCover/${date}${event.target.files[0].name}`).put(event.target.files[0])
            uploadTask.on('state_changed', function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log('upload is paused')
                  break;
                case firebase.storage.TaskState.RUNNING:
                  console.log('upload is running')
                  break
              }
            }, function (error) {
              console.log('this is error', error)
            }, function () {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                notify('Quiz Cover Uploaded Successfully', false)
      
                console.log('File available at ', downloadURL)
                setimageUrl(downloadURL)
                setQuizImage(downloadURL)
              }).catch(err => {
                notify(err.toString(), true)
      
              })
            })
      
          } catch (error) {
            notify(error.toString(), true)
      
            console.log('my error', error)
          }
          setQuizCover(event.target.files[0].name)
        }else {
          notify('Please select only .jpeg/.png file', true)
    
        }
    }

    useEffect(()=>{
        setProgressValue(99)

        if(currentQuiz.questions){

            if(currentQuiz.questions.length > 10){
                let currentQuesArray= {};
                currentQuiz.questions.forEach((item, i) => {
                    currentQuesArray[item] = true
                })
                setSelectedQues(currentQuesArray)
    
            }
        }

    },[questionsData])

    useEffect(() => {
        if(currentQuiz){
            getEditQuestions()
            
        }else{
            getQuestions()
        }


    }, [])


    useEffect(() => {
        if(currentQuiz) setMockTest(currentQuiz.mockTest || false)
    }, [currentQuiz])



    const filterData = (item) => {
        console.log(item)
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('Questions')
            // .where('difficulty','in',[1, 2])
            .where('subject', 'in', item).limit(100).get()
            .then((querySnapshot) => {
                let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                if (selectedDifficulty.length > 0) {
                    let difficulty = selectedDifficulty.map((item) => item.value)
                    data = data.filter((item) => (difficulty).find(element => element === item.difficulty))
                    console.log('static', data)
                    setQuestionsData(data)
                    document.querySelector("#loading-indicator").style.display = "none";

                }
                else {
                    document.querySelector("#loading-indicator").style.display = "none";

                    setQuestionsData(data)

                }

            }).catch(err=>{
                                
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(), true);
                console.log(err.toString())
              })
            
        // db.collection('Questions').where('subject', '==', data).get()
        //     .then((querySnapshot) => {
        //         let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))

        //         if (index === 0) {
        //             console.log('append', '1')
        //             setQuestionsData(data)
        //         } else {
        //             data.map(item => {

        //                 setQuestionsData(prev => [...prev, item])
        //             })

        //             console.log('append', '2')
        //         }

        //         console.log(" => ", data);

        //     }
        //     )
        //     .catch(function (error) {
        //         console.log("Error getting documents: ", error);
        //     });

    }

    const filterDifficulty = (item) => {
        console.log(item)
        document.querySelector("#loading-indicator").style.display = "flex";

        db.collection('Questions')
            .where('difficulty', 'in', item).limit(100).get()
            .then((querySnapshot) => {
                let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                if (selectedSubjects.length > 0) {
                    let subject = selectedSubjects.map((item) => item.value)
                    data = data.filter((item) => (subject).find(element => element === item.subject))
                    console.log('static', data)
                    setQuestionsData(data)
                    document.querySelector("#loading-indicator").style.display = "none";

                }
                else {
                    document.querySelector("#loading-indicator").style.display = "none";

                    setQuestionsData(data)

                }

            }).catch(err=>{
                                
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(), true);
                console.log(err.toString())
              })



        // db.collection('Questions').where('difficulty', '==', data).get()
        //     .then((querySnapshot) => {
        //         let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))

        //         if (index === 0) {
        //             setQuestionsData(data)

        //         } else {
        //             data.map(item => {

        //                 setQuestionsData(prev => [...prev, item])
        //             })

        //         }

        //         console.log(" => ", data);

        //     }
        //     )
        //     .catch(function (error) {
        //         console.log("Error getting documents: ", error);
        //     });
    }

    const onsubmit = () => {
        console.log('submit', selectedSubjects, selectedDifficulty)

        if (selectedSubjects.length !== 0) {

            let a = selectedSubjects.map((item) => item.value)
            console.log('selected.....', a)
            filterData(a)
        } else if (selectedDifficulty.length !== 0) {
            let b = selectedDifficulty.map((item) => item.value)
            console.log('selected.....', b)
            filterDifficulty(b)
        } else {
            if(currentQuiz){
                document.querySelector("#loading-indicator").style.display = "flex";

                getEditQuestions()
                
            }else{
                document.querySelector("#loading-indicator").style.display = "flex";

                getQuestions()
            }
        }
        // selectedDifficulty.map((item, index) => {

        //     filterDifficulty(item.value, index)

        // })
    }
    // console.log('questuion data',questionsData)

    let count = Object.entries(selectedQues).map(([k, v], i) => {
        //    let a= {item[i],item[i+1}

        // console.log(k,v,i)
        return { id: k, value: v }
    }
    )

    useEffect(() => {
        count = count.filter((item) => (item.value === true))
        setSelectedQuesCount(count)

        console.log('selected COunt', count)
    }, [selectedQues])

    const submitQuiz = async(data) => {
        console.log('onsubmit', data)
        
        if (selectedQuesCount.length !== 0) {
            document.querySelector("#loading-indicator").style.display = "flex";

            let finalSelectedQuestion = selectedQuesCount.map(item=>item.id)
            // console.log('in if')
            let res;
            if(currentQuiz){
            
                res=db.collection('zSystemStore').doc(currentQuiz.id)

            }else{

                 res=db.collection('zSystemStore').doc()
            }

            let finalData = {}
            let time =iswithoutTime ? 10: parseInt(data.time || 10)
            if(currentQuiz){

                finalData = {
                    itemID:res.id,
                    title: data.quizTitle,
                    price: parseInt(data.price),
                    questions: finalSelectedQuestion,
                    numberOfQuestions: selectedQuesCount.length,
                    timePerQues: time,
                    rating:parseInt(data.rating),
                    quizCover: imageUrl,
                    type: 'quiz',
                    withoutTimer:iswithoutTime,
                    lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                    mockTest: data.mockTest,
                    totalTime: parseInt(data.totalTime)
    
                }
            }else{
                
                finalData = {
                    itemID:res.id,
                    title: data.quizTitle,
                    price: parseInt(data.price),
                    questions: finalSelectedQuestion,
                    numberOfQuestions: selectedQuesCount.length,
                    timePerQues: time,
                    rating:parseInt(data.rating),
                    type: 'quiz',
                    withoutTimer:iswithoutTime,
                    quizCover: imageUrl,
                    createdAt:firebase.firestore.FieldValue.serverTimestamp(),
                    lastUpdatedAt:firebase.firestore.FieldValue.serverTimestamp(),
                    mockTest: data.mockTest,
                    totalTime: parseInt(data.totalTime)

                }
            }
            console.log('onsubmit Final', finalData)
            // return
            res.set(finalData,{merge: true}).then(() => {
                console.log('res',res, res.id)
                // db.collection('zSystemStore').limit(100)
                //     .get()
                //     .then(querySnapshot => {
                // let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                // console.log('sussceesfully submitted',data)
                // data = data.filter(item => item.type === 'quiz')
                if(currentQuiz){
                    notify('Quiz updated succesfully ',false)
                    notifi('Quiz updated succesfully ')
                } else {
                    notify('Quiz created succesfully ',false)
                    notifi('Quiz created succesfully ')
                }
                if(currentQuiz){

                    setQuizesData(prev=> prev.map((item) => item.id == currentQuiz.id ? ({ ...finalData}) : item))
                
                }else if(startIndex <= 1){

                    setQuizesData(prev => ([{ ...finalData, itemID: res.id ,id:res.id}, ...prev]))
                }
                document.querySelector("#loading-indicator").style.display = "none";

                handleClose()
                // })

            }).catch((err) =>{
                notify(err.toString(),true)
                if(document.querySelector("#loading-indicator") !== null)
                    document.querySelector("#loading-indicator").style.display = "none";

                console.log('err', err)
            } 
            )
        } else {
            console.log('in else')

            notify('Somthing went wrong, Please try again', true)
        }

    }

    
    const search=()=>{
        console.log('search',searchValue)
        
        if(searchValue==''){
            console.log('field Empty')
            notify('Search field can not be empty',true)
            
        }else if(searchValue.length<3){

            console.log('below 3')
            notify('Search atleast 4 words',true)

            
        }else{
            document.querySelector("#loading-indicator").style.display = "flex";

            index.search(searchValue).then(result=>{

                console.log('result',result.hits)
                
                if(result.hits.length===0){
                    document.querySelector("#loading-indicator").style.display = "none";

                    notify('Search exact question title ',true)

                }else{
                    document.querySelector("#loading-indicator").style.display = "none";

                    setQuestionsData(result.hits)
                    setreload(true)
                }
            }).catch(err=>{
                document.querySelector("#loading-indicator").style.display = "none";
                notify(err.toString(), true);
                console.log(err.toString())
              })
                                

            
            // db.collection('Questions').orderBy('questionTitle').startAt(searchValue).endAt(searchValue+'\uf8ff').get()
            // .then(querySnapshot => {
            //     let data = querySnapshot.docs.map(doc => {
    
            //         return { ...doc.data(), id: doc.id }
            //     })
                // if(data.hits.length===0){
                //     document.querySelector("#loading-indicator").style.display = "none";

                //     notify('Search exact Question Title ',true)

                // }else{
                //     document.querySelector("#loading-indicator").style.display = "none";

                //     setQuestionsData(data.hits)
                //     setreload(true)
                // }
            //     // data=data.filter((item)=>item.type==='quiz')
            //     // setQuizesData(data)
            //     // setEndIndex(data.length)
            //     console.log('Search data is ', data)
            // }).catch(err=>{
                                
            //     document.querySelector("#loading-indicator").style.display = "none";
            //     notify(err.toString(), true);
            //     console.log(err.toString())
            //   })
        }
    }
    const emptyCall=()=>{

        if(reload){
            if(searchValue.length >= 1 && searchValue.length < 4){
                getQuestions()
                setreload(false)
            }
        }
    }

    return (
        
        <div style={{ backgroundColor: '#1f1e2e', height: '100%', overflowY: 'scroll' }} >
            {progressVisible && <Progress color='info' value={progressValue} style={{ height: 4 }} />}
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notification} />
            </div>

            <div style={{ padding: '5px 20px' }}>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <IconButton edge="start" style={{ color: 'white' }} onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                </Row>

                <Row >
                    <Col md="12" style={{marginBottom:'100px'}}>
                        <Card style={{ margin: 0 }}>
                            <CardHeader>
                                <h5 className="title">Quiz Management</h5>
                            </CardHeader>
                            <CardBody>
                                <Row style={{ alignItems: 'flex-end' }}>

                                    <Col md="4">
                                        <label style={{ color: 'white' }}>Question Title</label>
                                        <Input
                                            name='searchTitle'
                                            placeholder="Enter Question Title"
                                            type="text"
                                            // innerRef={register({ required: true,minLength:4 })}

                                            onChange={(e)=>{
                                                setSearchValue(e.target.value)
                                                emptyCall()
                                                // console.log('event',e)
                                            }} 
                                        //     onKeyDown={(e)=>{
                                        //         // console.log('key down',e)
                                        //         if (e.keyCode === 8) { 
                                        //             console.log('delete');
                                        //             // console.log(`Value after clearing input: "${e.target.value}"`);
                                        //             if(e.target.value.length>3)
                                        //             {
                                        //                 getQuestions()

                                        //             }
                                        //         }
                                        // }}                                
                                        />
                                {/* {errors.searchTitle && errors.searchTitle.type === "required" && notify("Search field can't be empty",true)} */}
                                {/* {errors.searchTitle && errors.searchTitle.type === "minLength" && notify("Search field should have at least 4 words",true)} */}

                                    </Col>
                                    <Col style={{ paddingLeft: '0px' }} md='1'>
                                        <Button style={{ margin: "0px", padding: '6px 10px' }} color="info" onClick={search}><SearchIcon /></Button>

                                    </Col>
                                        
                                    <Col md='1'></Col>

                                    <Col md="2" className="quiz-multiselect">
                                        <label style={{ color: 'white' }}>Subjects</label>

                                        <ReactMultiSelectCheckboxes
                                            // className={styles.signUp_multiSelect}
                                            styles={customStyles}
                                            onChange={(option) => {
                                                setselectedSubjects(option)
                                                console.log(`Option selected:`, option);
                                            }}
                                            options={subjectOptions} />

                                    </Col>
                                    <Col md="2" className="quiz-multiselect">
                                        <label style={{ color: 'white' }}>
                                            Difficulty Level
                                        </label>

                                        <ReactMultiSelectCheckboxes
                                            // className={styles.signUp_multiSelect}
                                            styles={customStyles}

                                            onChange={(option) => {
                                                setselectedDifficulty(option)
                                                console.log(`Option selected:`, option);
                                            }}
                                            options={difficultyOptions} />

                                    </Col>
                                    <Col md='1'>
                                        <Button color='info' style={{ margin: "0px" }} onClick={onsubmit}>Filter</Button>

                                    </Col>

                                </Row>

                                <br></br>
                                <Table className="tablesorter" >
                                    <thead className="text-primary">
                                        <tr>
                                            <th> </th>
                                            <th>Question Title</th>
                                            <th>Subject</th>
                                            <th style={{textAlign:'center',minWidth:'130px'}}>Difficulty Level</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            questionsData.map((item) => {

                                                return (
                                                    <tr key={item.id}>
                                                        <td style={{ display: 'flex', paddingLeft: '20px' }} >
                                                            <Input type="checkbox" checked={selectedQues[item.id]} onChange={(e) => {
                                                                setSelectedQues((prev) => ({ ...prev, [item.id]: !prev[item.id] }))
                                                            }} /></td>
                                                        <td>{item.questionTitle}</td>
                                                        <td>{item.subject}</td>
                                                        <td style={{textAlign:'center'}}>{item.difficulty}</td>
                                                    </tr>

                                                )
                                            })
                                        }

                                    </tbody>

                                </Table>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
            {selectedQuesCount.length !== 0
                &&
                <Row style={{ position: 'fixed', bottom: '0',marginLeft:'0px',marginRight:'0px', minHeight: '120px', display: 'flex', width: '100%', justifyContent: 'flex-end', left: '-10', backgroundColor: '#272a3d' }}>
                    <Form style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onSubmit={handleSubmit(submitQuiz)}>

                        <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Col sm={9}>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col sm={1} style={{minWidth: "115px"}}>
                                        <label style={{ color: 'white', maxWidth:'125px' }}>{selectedQuesCount.length} Selected</label>
                                    </Col>

                                    <Col sm={2} style={{ textAlign: 'end', maxWidth:'100px' }}>
                                        <label style={{ color: 'white' }}>Quiz Title</label>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>

                                            <Input
                                                defaultValue={currentQuiz && currentQuiz.title }
                                                innerRef={register({ required: true })}
                                                name='quizTitle'
                                                placeholder="Enter Quiz Title"
                                                type="text"
                                                // required
                                            />
                                        {errors.quizTitle && errors.quizTitle.type === "required" && notify("Quiz title field can't be empty",true)}

                                        </FormGroup>

                                    </Col>
                                    <Col sm={1} style={{ textAlign: 'end', maxWidth:'70px' }}>
                                        <label style={{ color: 'white' }}>Price</label>
                                    </Col>
                                    <Col sm={2} >
                                        <FormGroup>

                                            <Input
                                                innerRef={register({ required: true })}
                                                defaultValue={currentQuiz && currentQuiz.price }
                                                name='price'
                                                placeholder="Price"
                                                type="number"
                                                // required
                                            />
                                        {errors.price && errors.price.type === "required" && notify("Price field can't be empty",true)}

                                        </FormGroup>

                                    </Col>
                                    {mockTest ? 
                                        <>
                                            <Col sm={1} style={{ textAlign: 'end', minWidth:'115px' }}>
                                            <label style={{ color: 'white' }}>Total Time</label>
                                            </Col>  
                                            
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Input
                                                        defaultValue={currentQuiz && currentQuiz.totalTime }
                                                        innerRef={register({ required: true })}
                                                        name='totalTime'
                                                        placeholder="min"
                                                        type="number"
                                                        // required
                                                    />
                                                </FormGroup>
                                                {errors.totalTime && errors.totalTime.type === "required" && notify("Total Time field can't be empty",true)}

                                            </Col>
                                        </>
                                     :
                                        <>
                                            <Col sm={1} style={{ textAlign: 'end', maxWidth:'135px' }}>
                                            <label style={{ color: 'white' }}>Time/Question</label>
                                            </Col>  
                                                
                                            {
                                                iswithoutTime===false ?(

                                                <Col sm={2}>
                                                    <FormGroup>
                                                        <Input
                                                            defaultValue={currentQuiz && currentQuiz.timePerQues }
                                                            innerRef={register({ required: true })}
                                                            name='time'
                                                            placeholder="sec"
                                                            type="number"
                                                            // required
                                                        />
                                                    </FormGroup>
                                                    {errors.time && errors.time.type === "required" && notify("Time/Question field can't be empty",true)}

                                                </Col>
                                                ):(
                                                    <Col sm={2} style={{ textAlign: 'end', maxWidth:'135px' }}>
                                                        <label style={{color:'grey',border:'1px solid #38385d',borderRadius:'5px',padding:'8px 10px',fontSize:'12px'}} >Unlimited</label>
                                                    </Col>  
                                                )

                                            }
                                            
                                            <Col sm={1} >
                                                {/* <label for='timer'> </label> */}
                                                <Switch
                                                    color='info'
                                                    checked={iswithoutTime}
                                                    onChange={(e)=>{
                                                    setIswithoutTime(e.target.checked)
                                                    console.log(e.target.checked)}}/>
                                            </Col>  
                                        </>
                                
                                    }
                                    <Col sm={3} style={{ display:'flex', alignItems:'center' }}>
                                        <label style={{ color: 'white', marginRight: 15}}>Rating</label>
                                    {/* </Col>
                                    <Col sm={2} > */}
                                        <FormGroup>
                                            <Input
                                                defaultValue={currentQuiz && currentQuiz.rating }
                                                innerRef={register({ required: true,min:0,max:5 })}
                                                name='rating'
                                                placeholder="max 5"
                                                type="number"
                                                // required
                                            />
                                            {errors.rating && errors.rating.type === "required" && notify("Rating field can't be empty",true)}
                                            {errors.rating && errors.rating.type === "max" && notify('Rating should be out of 5',true)}
            
                                        </FormGroup>

                                    </Col>
                                    <Col sm={2} style={{ marginTop: 15}}>
                                        <label style={{ color: 'white', marginBottom:15 }}> Upload Thumbnail </label>
                                    </Col>
                                    <Col sm={2} >
                                        <FormGroup style={{ display: 'flex' }}>
                                            <label htmlFor='bookcover' style={{ cursor: 'pointer', color: 'white', fontSize: 16, marginBottom: 0 }}>{quizCover === undefined ? <Button style={{margin: 0}} ><CloudUploadIcon /></Button> : <Button style={{margin: 0}}><CloudDoneIcon /></Button>}</label>
                                            <Input
                                            style={{ cursor: 'pointer' }}
                                            innerRef={register({ required: false, accept: '.jpeg,.png' })}
                                            name='bookcover'
                                            id='bookcover'
                                            placeholder='Upload'
                                            onChange={selectQuizCover}
                                            type='file'
                                            accept='.jpeg ,.png'
                                            // required
                                            />
                                        </FormGroup>
                                    </Col>
                                    
                                    {quizImage &&
                                        <Col>
                                            <img src={quizImage} alt="" style={{ width: 40, height: 55, marginTop: 5, marginLeft:20 }} />
                                        </Col>
                                    }
                                    <Col sm={2} style={{marginLeft: 30}} >
                                    <FormGroup>
                                            <Input
                                                style={{marginTop: 12}}
                                                defaultChecked={currentQuiz && currentQuiz.mockTest }
                                                innerRef={register({ required: false})}
                                                name='mockTest'
                                                type="checkbox"
                                                onChange={(e)=>setMockTest(e.target.checked)}
                                                // required
                                            />
                                        </FormGroup>
                                        <label style={{ color: 'white' }}>Mock Test</label>
                                    </Col>
                                    {/* <Col sm={2} >
                                        <FormGroup>
                                            <Input
                                                defaultValue={currentQuiz && currentQuiz.rating }
                                                innerRef={register({ required: true,min:0,max:5 })}
                                                name='rating'
                                                placeholder="max 5"
                                                type="number"
                                                // required
                                            />
                                            {errors.rating && errors.rating.type === "required" && notify("Rating field can't be empty",true)}
                                            {errors.rating && errors.rating.type === "max" && notify('Rating should be out of 5',true)}
            
                                        </FormGroup>

                                    </Col> */}
                                   
                                </Row>
                            </Col>
                            <Col sm={2} style={{ marginLeft: '20px' }}>
                                <Button color='info' type='submit'>Submit</Button>

                            </Col>
                        </Row>
                    </Form>

                </Row>
            }

        </div >
    )
}
export default QuizDialog