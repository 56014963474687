import React from 'react'
import {Button} from "reactstrap";

const Delete_Quiz_Question_Modal=({deleteQuestion,data,setDeleteModal})=> {
    
    const deleteRecord= ()=>{
        // console.log('data...',data,'id',data)
        setDeleteModal(false)
        deleteQuestion()
    
    }

    const onclick= ()=>{
        setDeleteModal(false)

    }
    return (
    <div>
        <p >Do you want to delete this Question</p>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Button color='info' onClick={deleteRecord}>Yes</Button>
        <Button  onClick={onclick}>No</Button>
        </div>
    </div>
    )
}

export default Delete_Quiz_Question_Modal
