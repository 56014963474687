/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import * as firebase from 'firebase'
import { Redirect } from "react-router-dom";

import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// import { get } from '../../Utilities/GetUser'
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      login: true,
      user: {},
      userData: 'Profile'
    };
  }

  getUsers = () => {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        let uid = user.uid;
        db.collection('zSystemUsers')
          .get()
          .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => doc.data())
            let currentUser = data.filter((filterData) => filterData._uniqueID === uid)
            this.setState({ userData: currentUser[0]?.username });
           
            // console.log(data[0])
            // this.setState({ userData: data })    
            // console.log("sidebarrrrrr data", this.state)
            // console.log('dataaaa', data)
          })

        
      } else {
        // User is signed out.
        // ...
      }
    })
  }

  componentDidMount() {
    this.getUsers()
    window.addEventListener("resize", this.updateColor);

  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  logOut = () => {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      this.setState({ login: false });

      // console.log('user sign out', this.state.login)
    }).catch(function (error) {
      // An error happened.

      console.log('user sign out error', error)
    });


  }

  render() {
    // console.log('tureeeee', this.state.login)
    if (this.state.login === true) {
      return (
        <>
          <Navbar
            className={classNames("navbar-absolute", this.state.color)}
            expand="lg"
          >
            <Container fluid>
              <div className="navbar-wrapper">
                <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: this.props.sidebarOpened
                  })}
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.props.toggleSidebar}
                  >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
                <NavbarBrand >
                  <p style={{color:"#FFF" }}>{this.props.brandText}</p>
                </NavbarBrand>
              </div>
              <button
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler"
                data-target="#navigation"
                data-toggle="collapse"
                id="navigation"
                type="button"
                onClick={this.toggleCollapse}
              >
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>
              <Collapse navbar isOpen={this.state.collapseOpen}>
                <Nav className="ml-auto" navbar>
                  {/* {/* <InputGroup className="search-bar">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button> 
                </InputGroup> */}
                  
                  {/* <UncontrolledDropdown nav>
                    <DropdownToggle
                      caret
                      color="default"
                      data-toggle="dropdown"
                      nav
                    >
                      <div className="notification d-none d-lg-block d-xl-block" />
                      <i className="tim-icons icon-sound-wave" />
                      <p className="d-lg-none">Notifications</p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      <NavLink tag="li">
                        <DropdownItem className="nav-item">
                          Mike John responded to your email
                      </DropdownItem>
                      </NavLink>
                      <NavLink tag="li">
                        <DropdownItem className="nav-item">
                          You have 5 more tasks
                      </DropdownItem>
                      </NavLink>
                      <NavLink tag="li">
                        <DropdownItem className="nav-item">
                          Your friend Michael is in town
                      </DropdownItem>
                      </NavLink>
                      <NavLink tag="li">
                        <DropdownItem className="nav-item">
                          Another notification
                      </DropdownItem>
                      </NavLink>
                      <NavLink tag="li">
                        <DropdownItem className="nav-item">
                          Another one
                      </DropdownItem>
                      </NavLink>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      caret
                      color="default"
                      data-toggle="dropdown"
                      nav
                      onClick={e => e.preventDefault()}
                    >
                      <div className="photo">
                        <img alt="..." src={require("assets/img/anime3.png")} />
                      </div>
                      <b className="caret d-none d-lg-block d-xl-block" />
                      <p className="d-lg-none" onClick={this.logOut}>Log out</p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      <NavLink tag="li">
                        <DropdownItem className="nav-item" style={{ color: 'black', fontWeight: 600 }}>{this.state.userData}</DropdownItem>
                      </NavLink>
                      {/* <NavLink tag="li">
                        <DropdownItem className="nav-item">Settings</DropdownItem>
                      </NavLink> */}
                      {/* <DropdownItem divider tag="li" /> */}
                      <NavLink tag="li">
                        <DropdownItem className="nav-item" onClick={this.logOut}>Log out</DropdownItem>
                      </NavLink>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <li className="separator d-lg-none" />
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
          <Modal
            modalClassName="modal-search"
            isOpen={this.state.modalSearch}
            toggle={this.toggleModalSearch}
          >
            <div className="modal-header">
              <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalSearch}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
            </div>
          </Modal>
        </>
      );
    }
    else {
      return < Redirect to='/login' />

    }
  }
}

export default AdminNavbar;
