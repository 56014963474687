import React, { useEffect, useState, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Progress,
} from "reactstrap";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import DescriptionIcon from "@material-ui/icons/Description";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";
import algoliasearch from "algoliasearch/lite";
import format from "date-fns/format";


function InvoceList() {
  const [limit,setLimit] = useState(10);

  const notification = useRef();
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceSearchValue, setInvoiceSearchValue] = useState("");
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const invoiceSearchIndex = searchClient.initIndex("Invoice_Search");
  const [search, setSearch] = useState(false);
  
  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const getInvoiceList = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('invoices')
    .orderBy("created_at", "desc")
    .limit(limit)
    .get()
    .then(querySnapshot=>{
      let data = querySnapshot.docs.map(doc => doc.data())
      setInvoiceList(data);
      setEndIndex(data.length);
      setProgressVisible(false);
      document.querySelector("#loading-indicator").style.display = "none";
    })
  }

  const nextSet = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection("invoices")
      .orderBy("created_at", "desc")
      .startAfter(invoiceList[invoiceList.length - 1].created_at)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
          let data = querySnapshot.docs.map(doc => doc.data())

        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";
          setInvoiceList(data);
          setStartIndex(endIndex + 1);
          setEndIndex((prev) => prev + data.length);
        } else {
          document.querySelector("#loading-indicator").style.display = "none";
          notify("End Of the list", true);
        }
      })
      .catch((err) => {
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString());
      });
  };

  const prevSet = () => {
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection("invoices")
      .orderBy("created_at")
      .startAfter(invoiceList[0].created_at)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
          let data = querySnapshot.docs.map(doc => doc.data())
         
          setInvoiceList(data)

        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          data = data.reverse();
          setStartIndex((prev) => prev - data.length);
          setEndIndex((prev) => prev - invoiceList.length);
          setInvoiceList(data);
        } else {
          document.querySelector("#loading-indicator").style.display = "none";
        }
      })
      .catch((err) => {
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString());
      });
  };

  const invoiceSearch = () => {

    if (invoiceSearchValue == "") {
      notify("Search field can not be empty", true);
    // } else if (invoiceSearchValue.length < 4) {
    //   notify("Search atleast 4 words", true);
    } else {
      setSearch(true);
      document.querySelector("#loading-indicator").style.display = "flex";

      invoiceSearchIndex
        .search(invoiceSearchValue)
        .then((result) => {
          if (result.hits.length === 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            notify("No results found", true);
          } else {
            document.querySelector("#loading-indicator").style.display = "none";

            setInvoiceList(result.hits);
            // setReload(true);
          }
        })
        .catch((err) => {
          if(document.querySelector("#loading-indicator") !== null)
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
        });
    }
  };

  const emptyCall = () => {
      getInvoiceList();
      setSearch(false);
  };

  useEffect(() => {
    setProgressValue(99);
    getInvoiceList();
  }, [limit]);

  return (
    <>
      {progressVisible ? (
        <Progress color="info" value={progressValue} style={{ height: 4 }} />
      ) : (
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notification} />
          </div>

          <Card>
            <Row>
              <Col sm={12}>
                <CardHeader
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <CardTitle tag="h4" style={{ fontWeight: 300 }}>
                      INVOICE MANAGEMENT
                    </CardTitle>
                  </Col>
                </CardHeader>
              </Col>
            </Row>
            <CardBody>
              <Row>
                <Col sm={4}>
                  <Input
                    type="text"
                    name="searchInvoice"
                    placeholder="Search Invoice"
                    onInput={(e) => {
                      setInvoiceSearchValue(e.target.value);
                      e.target.value.length ===0 && emptyCall();
                    }}
                  />
                </Col>
                <Col style={{ paddingLeft: "0px" }} sm={2}>
                  <Button
                    style={{ margin: "0px", padding: "6px 10px" }}
                    color="info"
                    onClick={invoiceSearch}
                  >
                    <SearchIcon  />
                  </Button>
                </Col>

            { !search && <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  sm={6}
                >
                  <p
                    style={{
                      position: "relative",
                      top: "8px",
                      marginRight: "15px",
                    }}
                  >
                    {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                  </p>
                  <div
                    style={{
                      position: "relative",
                      top: "-5px",
                      marginRight: "0px",
                    }}
                  >
                    {startIndex > 1 && (
                      <IconButton style={{ outline: "none" }}>
                        <KeyboardArrowLeftSharpIcon
                          style={{ color: "white" }}
                          onClick={() => {
                            prevSet();
                          }}
                        />
                      </IconButton>
                    )}
                    <IconButton
                      style={{ outline: "none" }}
                      onClick={() => nextSet()}
                    >
                      <KeyboardArrowRightSharpIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                  
                  <Input
                    style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                    type="select"
                    value={limit}
                    onChange={(e)=>setLimit(parseInt(e.target.value))}
                    // required
                  >
                    <option value='10'>10</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </Input>
                </Col>}
              </Row>
              <Table className="tablesorter">
                <thead className="text-primary">
                  <tr>
                    <th style={{ textAlign: "start", width: "10%" }}>Enroll. No</th>
                    <th style={{ textAlign: "start", width: "20%" }}>Student Name</th>
                    <th style={{ textAlign: "start", width: "30%" }}>
                      Invoice No
                    </th>
                    <th style={{ textAlign: "center", width: "10%" }}>
                      Document
                    </th>
                    <th style={{ textAlign: "center", width: "20%" }}>
                      Invoice Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceList.length === 0 && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td>No Data Found</td>
                      <td></td>
                    </tr>
                  )}
                  {invoiceList.map((item, index) => {
                    let formatTime = item.created_at !== undefined && item.created_at.seconds !== undefined ? format(new Date(item.created_at.seconds * 1000),'dd/MM/yyyy, hh:mm aa'): item.created_at !== undefined && item.created_at._seconds !== undefined ? format(new Date(item.created_at._seconds * 1000),'dd/MM/yyyy, hh:mm aa') : '';
                    // console.log('time: ', formatTime);
                    // console.log("time2:",item.created_at);
                    // let time = item?.created_at?.seconds ? new Date(item.created_at.seconds * 1000).toLocaleString() : new Date(item.created_at._seconds * 1000).toLocaleString();
                    return (
                      <tr key={index}>
                        <td>{item.enrollmentNO}</td>
                        <td
                          style={{
                            textAlign: "start",
                            textJustify: "auto",
                          }}
                        >
                          {item?.name}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            textJustify: "auto",
                          }}
                        >
                          {item.invoiceNo}
                        </td>
                        <td
                          style={{ textAlign: "center", cursor: "pointer" }}
                          onClick={() =>{

                            let pdfWindow = window.open("")
                            pdfWindow.document.write("<button style='float:right; margin-bottom:5px;'><a href='data:application/pdf;base64,"+encodeURI(item.invoiceFile)+"' download='invoice.pdf'>Download</a></button><br>");
                            pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(item.invoiceFile) + "'></iframe>"
                            )
                          }
                            
                          }
                        >
                          <DescriptionIcon />
                        </td>
                        <td style={{ textAlign: "center" }}>{formatTime}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}

export default InvoceList;
