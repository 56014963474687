/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Checkbox, ListItemText, MenuItem, Select, Input as MInput, withStyles, Menu } from "@material-ui/core";

//import { Modal } from '@material-ui/core';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Input,

  UncontrolledDropdown,Dropdown, DropdownToggle, DropdownMenu, DropdownItem

} from "reactstrap";
import { Edit, Delete, Add, Tune } from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';

import Add_Student from "./student_add_and_edit_form";
import { db } from '../MY_LOGIN_AND_SIGNUP/db/config'
import Edit_Student from "./Student_Edit._Form";
import Delete_modal from "./Delete_Modal";
import NotificationAlert from "react-notification-alert";
import firebase from 'firebase'

import { CSVLink } from "react-csv";

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AddMaterialStudent from "./AddMaterialStudent";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import  algoliasearch  from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../Utilities/algoliaKeys";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


function Students_Table() {
  const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#272a3d' : 'black',
        padding: 10,
    }),
    control: () => ({
  
        display: 'none'
    }),
  
  }
  const [limit,setLimit] = useState(10);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setFilter(0)
    setAnchorEl(null);
  };

  const handleCloseSort = () => {
    setFilter(0)
    setAnchorElSort(null);
  };


  const [progressVisible, setProgressVisible] = useState(true)
  const [progressValue, setProgressValue] = useState(8)
  const [userData, setUserData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  // const [enroll, setEnroll] = useState(null)
  const [editUser, setEditUser] = useState()
  const [deleteUserVisible, setDeleteUserVisible] = useState(false)
  const [deletedUser, setDeletedUser] = useState()

  const [batchList, setBatchList] = useState([]);
  const [startIndex, setStartIndex] = useState(1)
  const [endIndex, setEndIndex] = useState(0);
  const [filter, setFilter] = useState(0);
  const [fullDialogOpen, setFullDialogOpen] = useState(false)
  const [currentStuId, setcurrentStuId] = useState('')
  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID)
  const studentSearchIndex = searchClient.initIndex("Student_Search");
  const [studentSearchValue, setStudentSearchValue] = useState('')
  const [csvData, setCsvData] = useState([]);
  const [search, setSearch] = useState(false);
  const [sortByErollment, setSortByErollment] = useState(false);


  
  const handlefullToggle = () => {
    setFullDialogOpen(prev => !prev);
  };
  const headers = [
    { label: "Enrollment No.", key: "enrollment" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Mobile No.", key: "mobile" },
    { label: "Address", key: "address" },
    { label: "Batch", key: "batch" },
    { label: "Shift", key: "shift" },
    { label: "Status", key: "status" },

  ];

  const notifi = useRef();
  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? 'success' : 'danger'
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6
    };
    if(notifi.current){
      notifi.current.notificationAlert(options)
    }
    // notification(options);
  }

  const studentSearch = () => {
    if (studentSearchValue == "") {
      notify("Search field can not be empty", true);
    } else if (studentSearchValue.length <= 3) {
      notify("Search atleast 4 words", true);
    } else {
      setSearch(true);
      document.querySelector("#loading-indicator").style.display = "flex";

      studentSearchIndex
        .search(studentSearchValue)
        .then((result) => {
          if (result.hits.length === 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            notify("No results found", true);
          } else {
            document.querySelector("#loading-indicator").style.display = "none";
            setUserData(result.hits);
            downloadUserData(result.hits);
          }
        })
        .catch((err) => {
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
        });
    }
  }

  const getUsers = (orderBy) => {
    setProgressValue(99);
    document.querySelector("#loading-indicator").style.display = "flex";
    if(orderBy){
      db.collection('zSystemStudents').orderBy('enrollmentNO', orderBy).limit(limit)
        .get()
        .then(function (querySnapshot) {
          var students = []
          querySnapshot.docs.forEach(function (doc) {
            students.push(doc.data())
          })
         
        document.querySelector("#loading-indicator").style.display = "none";
        downloadUserData()
        setUserData(students)
        setProgressVisible(false)
        setStartIndex(1)
        setEndIndex(students.length)
      })
      return
    }
    
    db.collection('zSystemStudents').orderBy('createdAt', 'desc').limit(limit)
    .get()
    .then(function (querySnapshot) {
      var students = []
      querySnapshot.docs.forEach(function (doc) {
        students.push(doc.data())
      })
     
      document.querySelector("#loading-indicator").style.display = "none";
      downloadUserData()
      setUserData(students)
      setProgressVisible(false)
      setStartIndex(1)
      setEndIndex(students.length)
    })
  }

  const getBatch = () => {
    db.collection('Batch').where('type', '==', 'batch')
    .get().then((querySnapshot) => {
      let data = querySnapshot.docs.map((doc) => doc.data());
      setBatchList(data)
    })
  }

  useEffect(() => {
    getBatch()
  }, [])
  
  useEffect(() => {
    getUsers()
  }, [limit])
  

  const filterSearch = (data) => {
    console.log(data)
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection('zSystemStudents').where('status', '==', data).get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map(doc => (doc.data()))
        // doc.data() is never undefined for query doc snapshots
        document.querySelector("#loading-indicator").style.display = "none";

        setUserData(data);
        downloadUserData(data);
        console.log(" => ", data);

      }
      ).catch(err=>{
                                
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })

  }

  const filterBatchSearch = (data) => {
    console.log(data);
    document.querySelector("#loading-indicator").style.display = "flex";

    db.collection('zSystemStudents').where('batch', 'array-contains', data).get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map(doc => (doc.data()))
        document.querySelector("#loading-indicator").style.display = "none";
        setUserData(data);
        downloadUserData(data);
      }).catch(err=>{
                                
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })
  }


  const nextSet = () => {
    // console.log(lastDoc)
    // if (userData.length === limit) {
      document.querySelector("#loading-indicator").style.display = "flex";
    if(sortByErollment) {
      db.collection('zSystemStudents').orderBy('enrollmentNO', sortByErollment).startAfter(userData[userData.length-1].enrollmentNO).limit(limit).get()
      .then(querySnapshot => {
        let data = querySnapshot.docs.map(doc => {

          return doc.data()
        })
        
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          setUserData(data);
          downloadUserData(data);
          setStartIndex(endIndex + 1)
          setEndIndex(prev => prev + data.length)
          
        } else {
          document.querySelector("#loading-indicator").style.display = "none";

          notify('End Of the list', true)
        }

      }).catch(err=>{
        if(document.querySelector("#loading-indicator"))
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })
      return
    }

    db.collection('zSystemStudents').orderBy('createdAt', 'desc').startAfter(userData[userData.length-1].createdAt).limit(limit).get()
      .then(querySnapshot => {
        let data = querySnapshot.docs.map(doc => {

          return doc.data()
        })
        
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          setUserData(data);
          downloadUserData(data);
          setStartIndex(endIndex + 1)
          setEndIndex(prev => prev + data.length)
          
        } else {
          document.querySelector("#loading-indicator").style.display = "none";

          notify('End Of the list', true)
        }

      }).catch(err=>{
        if(document.querySelector("#loading-indicator"))
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })
  }

  const prevSet = async() => {
    document.querySelector("#loading-indicator").style.display = "flex";
    let querySnapshot = {}
    if(sortByErollment) {
      querySnapshot = await db.collection('zSystemStudents').orderBy('enrollmentNO', sortByErollment === 'asc' ? 'desc' : 'asc')
      .startAfter(userData[0].enrollmentNO).limit(limit).get().catch(err=>{
        document.querySelector("#loading-indicator").style.display = "none";
        notify(err.toString(), true);
        console.log(err.toString())
      })
    } else {
      querySnapshot = await db.collection('zSystemStudents').orderBy('createdAt')
        .startAfter(userData[0].createdAt).limit(limit).get().catch(err=>{
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
          console.log(err.toString())
        })
    }
      // .endBefore(quizData[0].questionTitle).get()
      // .then(
        // querySnapshot => {
        let data = querySnapshot.docs.map(doc => {

          return doc.data()
        })
        if (data.length !== 0) {
          document.querySelector("#loading-indicator").style.display = "none";

          data = data.reverse()

          setStartIndex(prev => prev - data.length)
          setEndIndex(prev => prev - userData.length)
          setUserData(data)
          console.log('my user data is ', data)
        } else {
          document.querySelector("#loading-indicator").style.display = "none";

          console.log('Can not go back')
        }
      // })
      
  }

  const batchDataUpdate = (newBatch,action,id,batchName ) => {
    
    const batch = db.batch();
    if(action === "select-option"){
      batch.set(db.collection('zSystemStudents').doc(id),{batch : firebase.firestore.FieldValue.arrayUnion(newBatch), batchName: batchName} , {merge: true});
      batch.set(db.collection('Batch').doc(newBatch),{enrolledStudent:firebase.firestore.FieldValue.increment(1)} , {merge: true});

    } else if(action === "deselect-option") {
      batch.set(db.collection('zSystemStudents').doc(id),{batch : firebase.firestore.FieldValue.arrayRemove(newBatch), batchName: batchName} , {merge: true});
      batch.set(db.collection('Batch').doc(newBatch),{enrolledStudent:firebase.firestore.FieldValue.increment(-1)} , {merge: true});
    }
    batch.commit().then(res => {
      setUserData(prev => prev.map((item) => {
        if(item._uniqueID == id){
          return {
            ...item, 
            batchName: batchName ? batchName :[]
          }
        }
        return item;
      }))
      document.querySelector("#loading-indicator").style.display = "none";

    }).catch(err => {
      document.querySelector("#loading-indicator").style.display = "none";
      console.log("err",err)
    })
  }

  const batchSelect = (newBatch,action, id, batchName) => {
    if(action === "select-option"){
      db.collection("Batch").where('itemID', '==', newBatch).get().then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        if(data[0].enrolledStudent && data[0].enrolledStudent > data[0].maxStudent) {
          notify("Batch is full", true)
          document.querySelector("#loading-indicator").style.display = "none";

        } else {
         batchDataUpdate(newBatch, action,id, batchName)

        }
        // data[0].enrolledStudent ? data[0].enrolledStudent < data[0].maxStudent ? 
        // batchDataUpdate(newBatch, action, id, batchName) : notify("Batch is full", true) 
        // : batchDataUpdate(newBatch, action,id, batchName)   
      })
    } else if(action === "deselect-option") {
      batchDataUpdate(newBatch, action, id, batchName) 
    }
    
  }

  const emptyStudent = () => {
    getUsers()
  }

  const downloadUserData = (data) => {
    if (data) {
      let userDataArr = data.map(student => {
        return ({
          enrollment: student.enrollmentNO ? student.enrollmentNO : "N/A",
          name: `${student.firstname} ${student.lastname}`,
          email: student.email ? student.email : "N/A",
          mobile: student.mobile ? student.mobile : "N/A",
          address: student?.address ? student?.address : "N/A",
          batch: student?.batchName ? student?.batchName?.map(item => item?.label) : "N/A",
          shift: student?.shift ? student?.shift : "N/A",
          status: student?.status ? student?.status : "N/A",
        })
      })
      
      setCsvData(userDataArr);
      return;
    } else {
      db.collection('zSystemStudents').orderBy('createdAt', 'desc')
        .get()
        .then(function (querySnapshot) {
          var students = []
          querySnapshot.docs.forEach(function (doc) {
            students.push(doc.data())

          })
          let userDataArr = students.map(student => {
            return ({
              enrollment: student.enrollmentNO ? student.enrollmentNO : "N/A",
              name: `${student.firstname} ${student.lastname}`,
              email: student.email ? student.email : "N/A",
              mobile: student.mobile ? student.mobile : "N/A",
              address: student?.address ? student?.address : "N/A",
              batch: student?.batchName ? student?.batchName?.map(item => item?.label) : "N/A",
              shift: student?.shift ?  student?.shift : "N/A",
              status: student?.status ? student?.status : "N/A",
            })
          })
          setCsvData(userDataArr);
          return;
        })
    }
  }


  return (
    <>
      {progressVisible ? <Progress color='info' value={progressValue} style={{ height: 4 }} />
        : <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notifi} />
          </div>
          {/* <Row>
            <Col>
            <CSVLink
                data={data}
                asyncOnClick={true}
                onClick={(event, done) => {
                  new Promise(resolve => setTimeout(resolve, 5000)).then(() => {
                    done();
                  });
                }}
              >
                Download me after 5 sec?
              </CSVLink>
            </Col>
          </Row> */}
          <Dialog fullScreen open={fullDialogOpen} onClose={handlefullToggle} TransitionComponent={Transition}>
              <AddMaterialStudent notifi={notify} currentStuId={currentStuId} handleClose={handlefullToggle} />
          </Dialog>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader style={{ display: "flex", alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <CardTitle tag="h4" style={{ marginRight: 'auto', marginLeft: 'auto', fontWeight: 300 }}>STUDENT MANAGEMENT</CardTitle>
                  <Button color='info' style={{ marginRight: 25 }} onClick={() => setIsModalVisible(true)}>Create Student <Add /></Button>
                  <Button style={{ padding: '7px 13px' }}>
                    <CSVLink
                      data={csvData}
                      headers={headers}
                      filename={"my-file.csv"}
                      asyncOnClick={true}
                      onClick={(event, done) => {
                        done();
                      }}
                    >
                      <GetAppIcon style={{ color: 'white' }} />

                    </CSVLink>
                  </Button>

                </CardHeader>
                <CardBody style={{ paddingTop: '10px' }}>
                  <Row>
                    <Col sm={4}>
                      <Input type="text" name="search" placeholder="Search Student" 
                        onChange={(e)=> (setStudentSearchValue(e.target.value),
                          e.target.value.length === 0 && emptyStudent(),setSearch(false))
                        }/>
                    </Col>
                    <Col style={{ paddingLeft: '0px' }} sm={2}>
                      <Button style={{ margin: "0px", padding: '6px 10px' }} color="info"
                      onClick={studentSearch}
                      >
                        <SearchIcon />
                      </Button>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                      {!search && <>
                        <p style={{ position: 'relative', top: '8px', marginRight: '15px' }}>
                        {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                      </p>
                      <div style={{ position: 'relative', top: '-5px'}}>

                      {
                          startIndex > 1 &&
                          <IconButton style={{ outline: 'none' }}
                            onClick={() => {

                              prevSet()
                            }}
                          >
                              <KeyboardArrowLeftSharpIcon style={{ color: 'white' }}
                                  
                              />
                          </IconButton>
                      }
                        <IconButton style={{ outline: 'none' }}
                          onClick={() => nextSet()}
                        >
                          <KeyboardArrowRightSharpIcon style={{ color: 'white' }} />

                        </IconButton>
                      </div>
                      <Input
                        style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                        type="select"
                        value={limit}
                        onChange={(e)=>setLimit(parseInt(e.target.value))}
                        // required
                      >
                        <option value='10'>10</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                      </Input>
                      <div>
                        <Button
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          variant="contained"
                          style={{ padding: '6px 12px' }}
                          color='info'
                          onClick={handleClick}
                        >
                          <FilterListIcon  />
                        </Button>
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <StyledMenuItem>
                            <ListItemText primary="Filter By Status" onClick={() => {
                            setFilter(0);
                          }}/>
                          </StyledMenuItem>
                          <StyledMenuItem>
                            <ListItemText primary="Filter By Batch" onClick={() => {
                            setFilter(1);
                          }}/>
                          </StyledMenuItem>
                          {filter === 0 &&
                            <>
                              <StyledMenuItem>
                                <ListItemText primary="All"  onClick={()=>{
                                  document.querySelector("#loading-indicator").style.display = "flex";
                                  getUsers()
                                  handleClose()
                                }}/>
                              </StyledMenuItem>
                              <StyledMenuItem>
                                <ListItemText primary="Active" onClick={() => {
                                  filterSearch('Active')
                                  handleClose()
                                }}/>
                              </StyledMenuItem>
                              <StyledMenuItem>
                                <ListItemText primary="Inactive" onClick={()=>{
                                  filterSearch('Inactive')
                                  handleClose()
                                }}/>
                              </StyledMenuItem>
                            </>
                          }
                          {filter === 1 &&
                            <>
                            <StyledMenuItem>
                              <ListItemText primary="All" onClick={() =>{ 
                                getUsers()
                                handleClose()
                              }}/>
                            </StyledMenuItem>
                            {batchList.map(item => {
                                return(
                                  <StyledMenuItem>
                                    <ListItemText primary={item.batchName} onClick={() =>{ 
                                      filterBatchSearch(item.itemID)
                                      handleClose()
                                    }}/>
                                  </StyledMenuItem>
                                )
                              })}
                            </>
                          }
                          
                        </StyledMenu>
                      </div>
                      <div>
                        <Button
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          variant="contained"
                          style={{ padding: '6px 12px', marginLeft: 5 }}
                          color='info'
                          onClick={handleClickSort}
                        >
                          <SortIcon  />
                        </Button>
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorElSort}
                          keepMounted
                          open={Boolean(anchorElSort)}
                          onClose={handleCloseSort}
                        >
                           <StyledMenuItem>
                            <ListItemText primary="Sort By Enrollment No" onClick={() => {
                            setFilter(0);
                          }}/>
                          </StyledMenuItem>
                          {filter === 0 &&
                            <>
                              <StyledMenuItem>
                                <ListItemText primary="Ascending" onClick={() => {
                                  setSortByErollment('asc')
                                  getUsers('asc')
                                  handleCloseSort()
                                }}/>
                              </StyledMenuItem>
                              <StyledMenuItem>
                                <ListItemText primary="Descending" onClick={()=>{
                                  setSortByErollment('desc')
                                  getUsers('desc')
                                  handleCloseSort()
                                }}/>
                              </StyledMenuItem>
                              { sortByErollment &&
                                <StyledMenuItem>
                                <ListItemText primary="Clear" onClick={()=>{
                                  setSortByErollment('')
                                  getUsers()
                                  handleCloseSort()
                                }}/>
                              </StyledMenuItem>
                              }
                            </>
                          }
                          
                        </StyledMenu>
                      </div>
                   
                      {/* <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle style={{ padding: '6px 12px' }} className="nav-link" color='info' caret>
                          <FilterListIcon  />
                        </DropdownToggle>
                        <DropdownMenu right style={{height: filter === 1 && 225, overflowY : filter === 1 && "scroll"}}>
                          <DropdownItem style={{ color: 'black', fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                            setFilter(0);
                          }} >Filter By Status</DropdownItem>
                          <DropdownItem style={{ color: 'black', fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                            setFilter(1);
                          }}>Filter By Batch</DropdownItem>
                          {filter === 0 && (
                            <>
                              <DropdownItem style={{ color: 'black' }} onClick={() => {
                                document.querySelector("#loading-indicator").style.display = "flex";
                                getUsers()
                                }}>All</DropdownItem>
                              <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Active')}>Active</DropdownItem>
                              <DropdownItem style={{ color: 'black' }} onClick={() => filterSearch('Inactive')}>Inactive</DropdownItem>
                                </>
                          )}
                          {filter === 1 && (
                            <>
                              <DropdownItem style={{ color: 'black' }} onClick={() => {
                                document.querySelector("#loading-indicator").style.display = "flex";
                                getUsers()
                              }}>All</DropdownItem>
                              {batchList.map(item => {
                                return(
                                  <DropdownItem style={{ color: 'black' }} onClick={() => filterBatchSearch(item.itemID)}>{item.batchName}</DropdownItem>
                                )
                              })}
                            </>
                          )}
                          
                        </DropdownMenu>
                      </Dropdown> */}
                      </>}
                    </Col>

                  </Row>
                  <Modal isOpen={isModalVisible} style={{ display: 'flex', flex: 1, }} size='lg'>
                    <ModalBody style={{
                      width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                      border: '1px solid white !important',
                    }} size='sm'>
                      <Add_Student
                        notify={notify} 
                        setModal={(val)=>setIsModalVisible(val)}
                        setUserData={setUserData}
                       ></Add_Student>
                    </ModalBody>

                  </Modal>
                  <Modal isOpen={editVisible} style={{ display: 'flex', flex: 1, top: 0 }} size='lg'>
                    <ModalBody style={{
                      width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                      border: '1px solid white !important',
                    }} size='sm'>
                      <Edit_Student
                        notify={notify}
                        userData={editUser}
                        setUserData={setUserData}
                        // firstname={editUser!==undefined && editUser.firstname} 
                        // lastname={editUser!==undefined&& editUser.lastname}
                        // mobile={editUser!==undefined &&editUser.mobile}
                        // status={editUser!==undefined &&editUser.status}
                        // enrollment={editUser!==undefined && editUser.enrollment}
                        onClick={() => {
                          setEditVisible(false)
                        }}
                      >
                      </Edit_Student>
                    </ModalBody>

                  </Modal>
                  <Modal isOpen={deleteUserVisible} style={{ width: 250 }}>
                    <ModalBody style={{ width: '100%', padding: 0 }} size='sm'>
                      <Delete_modal
                        notify={notify}
                        setUserData={setUserData}
                        uid={deletedUser !== undefined && deletedUser._uniqueID}
                        onClick={() => {
                          setDeleteUserVisible(false)
                        }}
                      ></Delete_modal>
                    </ModalBody>

                  </Modal>

                  <Table className="tablesorter" >
                    <thead className="text-primary">
                      <tr>
                        <th>Student Name</th>
                        <th style={{textAlign: "center", }}>ENROLL.No</th>
                        <th style={{textAlign: "center", }}>Email</th>
                        <th style={{textAlign: "center"}}>Mobile</th>
                        <th style={{textAlign: "center"}}>Batch</th>
                        <th style={{textAlign: "center"}}>Shift</th>
                        <th style={{textAlign: "center"}}>Status</th>
                        <th style={{textAlign: "center"}}>Action</th>
                        <th style={{textAlign: "center"}}>Study Material</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((user, i) => {
                        // console.log("userData:", user);
                        if(user.firstname ===undefined && user.lastname === undefined){
                          return null
                        }
                        return (
                        <tr key={user._uniqueID} >
                          <td>{user.firstname + ' ' + user.lastname}</td>
                          <td style={{textAlign: "center"}}>{user.enrollmentNO ? user.enrollmentNO : "N/A"}</td>
                          <td style={{textAlign: "center"}}>{user.email}</td>
                          <td style={{textAlign: "center"}}>{user.mobile}</td>
                          {user.enrollmentNO || user.enrolled ? (
                            <td>
                               
                                           
                              <ReactMultiSelectCheckboxes
                                styles={customStyles}
                                value={user.batchName && typeof(user.batchName) == 'object' ? user.batchName : ""}
                                getDropdownButtonLabel={
                                  ({placeholderButtonLabel,value})=> {
                                    if (value && value.length !== 0) {
                                      return `${value?.length} selected`;
                                    } else {
                                      return `Select`;
                                    }
                                  }
                                }
                                onChange={(option,e) => {
                                  document.querySelector("#loading-indicator").style.display = "flex";
                                  batchSelect(e.option.value, e.action, user._uniqueID, option)
                                }}
                                options={batchList.map(item => ({label: item.batchName, value: item.itemID }))}
                                />
                            </td>
                          ): (
                            <td style={{textAlign: "center"}}>
                              N/A
                            </td>
                          )}
                          <td style={{textAlign: "center"}}>{user.shift ? user.shift == "morningShift"? "Morning Shift": user.shift == "afternoonShift" ? "Afternoon Shift" : "Evening Shift" : "N/A"}</td>
                          <td style={{textAlign: "center"}}>{user.status}</td>
                          <td style={{textAlign: "center"}}><Edit onClick={() => {
                            setEditVisible(true)
                            console.log('my edit data', user)
                            setEditUser(user)
                            console.log("my edit state data", editUser)
                          }} style={{color:'#247cf7', cursor: "pointer"}} />
                            {/* <Modal isOpen={editVisible} style={{ display: 'flex', flex: 1, top: 0 }} size='lg'>
                              <ModalBody style={{
                                width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                                border: '1px solid white !important',
                              }} size='sm'>
                                <Edit_Student
                                  notify={notify}
                                  userData={editUser}
                                  setUserData={setUserData}
                                  // firstname={editUser!==undefined && editUser.firstname} 
                                  // lastname={editUser!==undefined&& editUser.lastname}
                                  // mobile={editUser!==undefined &&editUser.mobile}
                                  // status={editUser!==undefined &&editUser.status}
                                  // enrollment={editUser!==undefined && editUser.enrollment}
                                  onClick={() => {
                                    setEditVisible(false)
                                  }}
                                >
                                </Edit_Student>
                              </ModalBody>

                            </Modal> */}
                            <Delete style={{ color: 'red', cursor: "pointer"}} notify={notify} onClick={() => {
                              setDeleteUserVisible(true)
                              setDeletedUser(user)
                            }} />
                            {/* <Modal isOpen={deleteUserVisible} style={{ width: 250 }}>
                              <ModalBody style={{ width: '100%', padding: 0 }} size='sm'>
                                <Delete_modal
                                  notify={notify}
                                  setUserData={setUserData}
                                  uid={deletedUser !== undefined && deletedUser._uniqueID}
                                  onClick={() => {
                                    setDeleteUserVisible(false)
                                  }}
                                ></Delete_modal>
                              </ModalBody>

                            </Modal> */}
                          </td>
                          <td style={{textAlign: "center", minWidth: 70 }}>
                            <Button color='info' 
                                style={{paddingLeft:10, paddingRight:10}}
                                onClick={()=>{
                                    setcurrentStuId(user._uniqueID)
                                    handlefullToggle()
                                }}
                            >
                                <LibraryBooksIcon/> / <QuestionAnswerIcon/>
                            </Button>
                          </td>
                        </tr>
                      )})}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>}
    </>
  );
}


export default Students_Table;
