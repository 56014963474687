import { Table } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

function AddClassesTime({ classesOn, setModalOpen }) {
  const { register, handleSubmit, errors } = useForm();
  const [timeError, setTimeError] = useState('')
  const onSubmit = (data, e) => {
    console.log(data);
    if(data.startTime >= data.endTime) {
      setTimeError('End time cannot be less than start time')
      return
    }      
    classesOn[1]((prev) => [...prev, data]);
    e.target.reset();
  };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card style={{ margin: 0 }}>
            <CardHeader>
              <h5 className="title">Create Classes</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Week of the day</label>
                      <Input
                        innerRef={register({ required: true })}
                        type="select"
                        name="weekName"
                        // required
                      >
                        <option value='' >Please select</option>
                        <option value='Sunday' >Sunday</option>
                        <option value='Monday' >Monday</option>
                        <option value='Tuesday' >Tuesday</option>
                        <option value='Wednesday' >Wednesday</option>
                        <option value='Thrusday' >Thrusday</option>
                        <option value='Friday' >Friday</option>
                        <option value='Saturday' >Saturday</option>
                      </Input>
                      {errors.weekName && errors.weekName.type === "required" && (
                        <span
                          style={{
                            fontSize: 12,
                            color: "red",
                            top: -20,
                            position: "relative",
                          }}
                        >
                          Please select week day
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Start Time</label>
                      <Input
                        // defaultValue={currentBatch && currentBatch.startTime}
                        innerRef={register({ required: true })}
                        name="startTime"
                        placeholder="Start Time"
                        type="time"
                      />
                      {errors.startTime &&
                        errors.startTime.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter start time
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label>End Time</label>
                      <Input
                        // defaultValue={currentBatch && currentBatch.endTime}
                        innerRef={register({ required: true })}
                        name="endTime"
                        placeholder="End Time"
                        type="time"
                        // min={"07:00:00"}
                      />
                      {errors.endTime && errors.endTime.type === "required" && (
                        <span
                          style={{
                            fontSize: 12,
                            color: "red",
                            top: -20,
                            position: "relative",
                          }}
                        >
                          Please enter end time
                        </span>
                      )}
                      {timeError &&
                        <span
                          style={{
                            fontSize: 12,
                            color: "red",
                            top: -20,
                            position: "relative",
                          }}
                        >
                          {timeError}
                        </span> 
                      }
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="5">
                    <FormGroup>
                      <label>Meeting ID</label>
                      <Input
                        // defaultValue = {currentBatch && currentBatch.meetingID}
                        innerRef={register({ required: true })}
                        name="meetingID"
                        placeholder="Meeting ID"
                        type="number"
                      />
                      {errors.meetingID &&
                        errors.meetingID.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter the meeting Id
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label>Meeting Password</label>
                      <Input
                        // defaultValue = {currentBatch && currentBatch.meetingPass}
                        innerRef={register({ required: true })}
                        name="meetingPass"
                        placeholder="Meeting Password"
                        type="text"
                      />

                      {errors.meetingPass &&
                        errors.meetingPass.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter the meeting password
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="2" style={{display: 'flex'}}>
                    <Button style={{alignSelf:'center', marginBottom:0}} color="info" type="submit">
                      Add
                    </Button>
                  </Col>
                </Row>
                {classesOn[0] && classesOn[0].length !== 0 && (
                  <Card>
                    <CardBody>
                      <Table className="tablesorter">
                        <thead className="text-primary">
                          <tr>
                            <th style={{ textAlign: "center", fontWeight: '400', color: 'white' }}>Week</th>
                            <th style={{ textAlign: "center", fontWeight: '400', color: 'white' }}>Start From</th>
                            <th style={{ textAlign: "center", fontWeight: '400', color: 'white' }}>End at</th>
                            <th style={{ textAlign: "center", fontWeight: '400', color: 'white' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {classesOn[0] &&
                            classesOn[0].map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ textAlign: "center" }}>
                                    {item.weekName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.startTime}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.endTime}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      minWidth: 70,
                                    }}
                                  >
                                    <Delete
                                      onClick={() => {
                                        classesOn[1]((prev) =>
                                          prev.filter((_, index) => index !== i)
                                        );
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                )}
                <div>
                  {classesOn[0] && classesOn[0].length !== 0 ? (
                    <Button color="info" onClick={()=> setModalOpen(prev=> !prev)}>Submit</Button>
                  ) : (
                    <Button color="info" disabled>
                      Submit
                    </Button>
                  )}
                  <Button onClick={()=> setModalOpen(prev=> !prev)}>Back</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AddClassesTime;
