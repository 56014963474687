import React, { useEffect, useState, useRef } from 'react'
import NotificationAlert from "react-notification-alert";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalBody,
    Table,
    Progress,
    Input,
} from "reactstrap";
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { Edit, Delete } from '@material-ui/icons';
import DeleteBatch from './DeleteBatch';
import Add_Edit_Batch from './Add_Edit_Batch';
import  format  from 'date-fns/format';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AddMaterial from './AddMaterial';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BatchList() {
    const [limit,setLimit] = useState(10);
    const notification = useRef();
    const [progressVisible, setProgressVisible] = useState(false)
    const [progressValue, setProgressValue] = useState(8)
    const [open, setOpen] = useState(false);
    const [batchData, setBatchData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentBatch, setcurrentBatch] = useState('');
    const [currentBatchId, setcurrentBatchId] = useState('');
    const [fullDialogOpen, setFullDialogOpen] = useState(false)

    const handlefullToggle = () => {
        setFullDialogOpen(prev => !prev);
    };
    const [startIndex, setStartIndex ] = useState(1);
    const [endIndex, setEndIndex ] = useState(0);

    const notify = (msg, err) => {
        let color = err === true ? "danger" : "success"
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
                    </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }

    const handleClickOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const deleteBatch = (data) => {
      if(data.enrolledStudent === 0){
        const afterDelete = batchData.filter(item => item.itemID !== data);
        setBatchData(afterDelete)

        db.collection("Batch").doc(data).delete().then(() => {
            console.log('record deleted ')
            notify("Batch deleted successfully",false);
        }).catch((error) => {
            notify(error.toString(),true);
            console.log('this is error ', error)
        })
      } else {
        notify('This batch has students enrolled', true);
      }
    }

    const nextSet = () => {
          document.querySelector("#loading-indicator").style.display = "flex";
    
          db.collection('Batch').orderBy('createdAt', 'desc').startAfter(batchData[batchData.length-1].createdAt).limit(limit).get()
            .then(querySnapshot => {
              let data = querySnapshot.docs.map(doc => {
    
                return doc.data()
              })
             
              if (data.length !== 0) {
                document.querySelector("#loading-indicator").style.display = "none";
    
                setBatchData(data)
                setStartIndex(endIndex + 1)
                setEndIndex(prev => prev + data.length)
                
              } else {
                document.querySelector("#loading-indicator").style.display = "none";
    
                notify('End Of the list', true)
              }
    
            }).catch(err=>{
                                    
              document.querySelector("#loading-indicator").style.display = "none";
              notify(err.toString(), true);
              console.log(err.toString())
            })
      }
    
      const prevSet = () => {
        document.querySelector("#loading-indicator").style.display = "flex";
    
        db.collection('Batch').orderBy('createdAt')
          .startAfter(batchData[0].createdAt).limit(limit).get()
          .then(querySnapshot => {
            let data = querySnapshot.docs.map(doc => {
    
              return doc.data()
            })
            if (data.length !== 0) {
              document.querySelector("#loading-indicator").style.display = "none";
    
              data = data.reverse()
    
              setStartIndex(prev => prev - data.length)
              setEndIndex(prev => prev - batchData.length)
              setBatchData(data);
            } else {
              document.querySelector("#loading-indicator").style.display = "none";
            }
          }).catch(err=>{                     
            document.querySelector("#loading-indicator").style.display = "none";
            notify(err.toString(), true);
          })
      }

    useEffect(() => {
        setProgressValue(99)
        document.querySelector("#loading-indicator").style.display = "flex";
        db.collection('Batch').orderBy('createdAt', 'desc').limit(limit).get().then((querySnapshot) => {
            let data = querySnapshot.docs.map((doc) => doc.data())
            setBatchData(data);
            setEndIndex(data.length)
          setProgressVisible(false);
          document.querySelector("#loading-indicator").style.display = "none";
        })
    }, [limit])
    
    
    return (
        <>
            {progressVisible ? (
                <>
                    <Progress color='info' value={progressValue} style={{ height: 4 }} />
                </>
            ) : (
                <>
                    <div className="content">
                        <div className="react-notification-alert-container">
                            <NotificationAlert ref={notification} />
                        </div>
                        <Modal isOpen={deleteModal} color='primary' style={{ display: 'flex', flex: 1, top: -40, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
                            <ModalBody size='sm'>
                                <DeleteBatch
                                    deleteBatch={() => deleteBatch(currentBatch)}
                                    setDeleteModal={setDeleteModal}
                                ></DeleteBatch>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={open} style={{ display: 'flex', flex: 1,  minWidth: '85%' }}>
                            <ModalBody style={{
                            width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
                            border: '1px solid white !important',
                            }} size='sm'>
                                <Add_Edit_Batch currentBatch={currentBatch} setBatchData={setBatchData} notify={notify} handleClose={handleClose} />
                            </ModalBody>
                        </Modal>
                        
                        <Dialog fullScreen open={fullDialogOpen} onClose={handlefullToggle} TransitionComponent={Transition}>
                            <AddMaterial notifi={notify} currentBatchId={currentBatchId} handleClose={handlefullToggle} />
                        </Dialog>
                        <Row>
                            <Col md="12">
                                <Card >
                                    <CardHeader>
                                        <Row>
                                            <Col sm={9} style={{ textAlign: 'center' }}>
                                                <CardTitle tag="h4" style={{ fontWeight: 300 }}>
                                                    BATCH MANAGEMENT
                                                </CardTitle>
                                            </Col>
                                            <Col sm={3}>

                                                <Button color='info' style={{float:'right'}} onClick={() => {
                                                    setcurrentBatch('')
                                                    handleClickOpen()
                                                }}>Add Batch</Button>

                                            </Col>

                                        </Row>
                                        <Row style={{ justifyContent: "flex-end" }}>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          sm={6}
                        >
                          <p
                            style={{
                              position: "relative",
                              top: "8px",
                              marginRight: "15px",
                            }}
                          >
                            {startIndex <= 1 ? 1 : startIndex} - {endIndex}
                          </p>
                          <div
                            style={{
                              position: "relative",
                              top: "-5px",
                              marginRight: "5px",
                            }}
                          >
                            {startIndex > 1 && (
                              <IconButton
                                style={{ outline: "none" }}
                                onClick={() => {

                                  prevSet()
                                }}
                              >
                                <KeyboardArrowLeftSharpIcon
                                  style={{ color: "white" }}
                                />
                              </IconButton>
                            )}
                            <IconButton
                              style={{ outline: "none" }}
                                onClick={() => nextSet()
                              }
                            >
                              <KeyboardArrowRightSharpIcon
                                style={{ color: "white" }}
                              />
                            </IconButton>
                          </div>
                          <Input
                            style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                            type="select"
                            value={limit}
                            onChange={(e)=>setLimit(parseInt(e.target.value))}
                            // required
                          >
                            <option value='10'>10</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                          </Input>
                        </Col>
                      </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Table className="tablesorter" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th style={{textAlign: "center"}}>Batch Name</th>
                                                    <th style={{textAlign: "center"}}>Period</th>
                                                    <th style={{textAlign: "center"}}>Timings</th>
                                                    <th style={{textAlign: "center"}}>Week Days</th>
                                                    {/* <th style={{textAlign: "center"}}>Professor Name</th> */}
                                                    <th style={{textAlign: "center"}}>Action</th>
                                                    <th style={{textAlign: "center"}}>Study Material</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {batchData && batchData.map(item => {
                                                   return (
                                                        <tr key={item.itemID}>
                                                            <td style={{ textAlign: "center" }}>{item.batchName}</td>
                                                            <td style={{textAlign: "center"}}>
                                                                {item.startDate ? format(new Date(item.startDate), "dd/MM/yyyy") : 'N/A' }{' to '}
                                                                {item.endDate ? format(new Date(item.endDate), "dd/MM/yyyy") : 'N/A'}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                            {item?.classesOn?.map((items, i) => {
                                                              console.log('items.startTime',items.startTime)
                                                              return `${items.startTime && items.startTime !== undefined ? format(new Date(`2021-06-26T${items.startTime}`), "hh:mm a") : '--:--'} to ${items.endTime && items.endTime !== undefined ? format(new Date(`2021-06-26T${items.endTime}`), "hh:mm a") : '--:--'}${item?.classesOn?.length - 1 === i ? '' : ','} `
                                                            })}
                                                            </td>
                                                            <td style={{textAlign: "center", width: "20%"}}>
                                                                {item?.classesOn?.map((items, i) => `${items?.weekName}${item?.classesOn?.length - 1 === i ? '' : ','} `)}
                                                            </td>
                                                            
                                                            {/* <td style={{textAlign: "center" }}>{item.professorName}</td> */}
                                                            <td style={{textAlign: "center", minWidth: 70 }}>
                                                                <Edit style={{color:'#247cf7', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    setcurrentBatch(item)
                                                                    handleClickOpen()
                                                                }}
                                                                />
                                                                <Delete
                                                                    onClick={() => {
                                                                        setcurrentBatch(item)
                                                                        setDeleteModal(true)
                                                                    }}
                                                                    style={{ color: 'red', cursor: 'pointer',}}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: "center", minWidth: 70 }}>
                                                                <Button color='info' 
                                                                    style={{paddingLeft:10, paddingRight:10}}
                                                                    onClick={()=>{
                                                                        setcurrentBatchId(item.itemID)
                                                                        handlefullToggle()
                                                                    }}
                                                                >
                                                                    <LibraryBooksIcon/> / <QuestionAnswerIcon/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </>
    )
}

export default BatchList;