
import * as firebase from 'firebase'

  // var firebaseConfig = {
  //   apiKey: "AIzaSyD-jnNFP4MtQjzhROzGyGb--_vM5WbfdUA",
  //   authDomain: "tn-edu.firebaseapp.com",
  //   projectId: "tn-edu",
  //   storageBucket: "tn-edu.appspot.com",
  //   messagingSenderId: "117151481797",
  //   appId: "1:117151481797:web:f17ba8b4fab9be6ee415ac",
  //   measurementId: "G-PDLLDCPBF9"
  // };

  // const firebaseConfig = {
  //   apiKey: "AIzaSyCnNv3aJ20QNd4QZcf4cjzm8hqoKpVZ89c",
  //   authDomain: "tn-edu-dev.firebaseapp.com",
  //   projectId: "tn-edu-dev",
  //   storageBucket: "tn-edu-dev.appspot.com",
  //   messagingSenderId: "702740602366",
  //   appId: "1:702740602366:web:1c8dd24a1e308a9fcab4af"
  // };
  
  var firebaseConfig = {
    apiKey: "AIzaSyD-jnNFP4MtQjzhROzGyGb--_vM5WbfdUA",
    authDomain: "tn-edu.firebaseapp.com",
    projectId: "tn-edu",
    storageBucket: "tn-edu.appspot.com",
    messagingSenderId: "117151481797",
    appId: "1:117151481797:web:f17ba8b4fab9be6ee415ac",
    measurementId: "G-PDLLDCPBF9"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig)
  const db = firebaseApp.firestore()
  export {db}
  







