import React, { useState } from 'react';
import { Button } from 'reactstrap';

import * as firebase from 'firebase'

const functions = firebase.functions()


function Delete_modal({ uid,notify,setUserData, onClick }) {
    const [visible, setVisible] = useState(false)
    console.log('user id',uid)
    
    const deleteRecord = () => {
        document.querySelector("#loading-indicator").style.display = "flex";
        setVisible(true)
        console.log('deleteRecord executed')
        const deleteStudent = functions.httpsCallable('deleteStudent');
        deleteStudent({userID:uid}).then(result => {
            console.log('result', result)
            if (result.data.hasError === false) {
                document.querySelector("#loading-indicator").style.display = "none";

                setUserData(prev=>prev.filter(item => item._uniqueID !== uid))
                notify('Student details deleted successfully', false)
            } else {
                document.querySelector("#loading-indicator").style.display = "none";

                notify(result.data.message.errorInfo.message.toString(), true)

            }

        }).catch(err=>{
                                
            notify(err.toString(), true);
            document.querySelector("#loading-indicator").style.display = "none";
            console.log(err.toString())
          })
        // db.collection("zSystemStudents").doc(uid).delete().then(() => {
        //     console.log('record deleted ')
        // }).catch((error) => {
        //     console.log('this is error ', error)
        // })
    }
    return (<div style={{ padding: 20 }}>
        <p>Do you want to delete this record</p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* {visible===false ?<Button color='info' onClick={deleteRecord}>Yes</Button>:<Button color='info' onClick={onClick}>Done</Button> } */}
            <Button color='info' onClick={() => {
                deleteRecord()
                onClick()
            }}>Yes</Button>
            {visible === false && <Button onClick={onClick}>No</Button>}
        </div>
    </div>
    )
}

export default Delete_modal;