import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
  Progress,
  Input,
} from "reactstrap";
import { Edit, Delete, Add } from "@material-ui/icons";
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from "@material-ui/core/IconButton";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import NotificationAlert from "react-notification-alert";
import CampaignDiscountModal from "./CampaignDiscountModal";
import SpecialDiscountModal from "./SpecialDiscountModal";
import AcceptDiscount from './AcceptDiscount';
import DeclineDiscount from './DeclineDiscount';
import CampaignDelete from './CampaignDelete'
import { customAlphabet } from 'nanoid';
import { format } from 'date-fns';
import  algoliasearch  from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";

import { set } from "date-fns";
import { ModuleKind } from "typescript";
import SpecialDelete from "./SpecialDelete";

function DiscountManagement() {
  const [limit,setLimit] = useState(10);


  const [clicked, setClicked] = useState(false);

  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeclineModal, setIsDecline] = useState(false);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [startRequestIndex, setStartRequestIndex] = useState(1);
  const [endRequestIndex, setEndRequestIndex] = useState(0);
  const [startCampaignIndex, setStartCampaignIndex] = useState(1);
  const [endCampaignIndex, setEndCampaignIndex] = useState(0);
  const [startSpecialIndex, setStartSpecialIndex] = useState(1);
  const [endSpecialIndex, setEndSpecialIndex] = useState(0);
  const [campaignActive, setCampaignActive] = useState(true)
  const [specialActive, setSpecialActive] = useState(false)
  const [requestActive, setRequestActive] = useState(false)
  const [campaignData, setCampaignData] = useState([]);
  const [specialData, setSpecialData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [currentCampaignData, setCurrentCampaignData] = useState('');
  const [campaignDeleteModal, setCampaignDeleteModal] = useState(false);
  const [currentSpecialData, setCurrentSpecialData] = useState('');
  const [specialDeleteModal, setSpecialDeleteModal] = useState(false);
  const [currentRequestData, setCurrentRequestData] = useState('')

  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID)
  const couponSearchIndex = searchClient.initIndex("Coupon_Search");
  const studentSearchIndex = searchClient.initIndex("Student_Search");
  const [campaignSearchValue,setCampaignSearchValue] = useState('');
  const [specialSearchValue,setSpecialSearchValue] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState(false);
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 7);
  const [edit, setEdit] = useState(false);
  const notifi = useRef();
  
  const notify = (msg, err) => {
    // console.log('notify')
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
  };

  const getUsers = () => {
    setProgressValue(99);
    setProgressVisible(false);
  };



  const getCampaignCode = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DiscountCoupons').where('type', '==', 'Campaign').orderBy("createdOn", "desc").limit(limit).get().then((querySnapshot) => {
      let data = querySnapshot.docs.map((doc) => doc.data())
      setProgressVisible(false);
      setCampaignData(data);
      setEndCampaignIndex(data.length);
      document.querySelector("#loading-indicator").style.display = "none";
    })
  }

  const getRequest = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DocumentRequest').where('type', '==', 'request').orderBy("createdAt", "desc").limit(limit).get().then((querySnapshot) => {
      let data = querySnapshot.docs.map((doc) => doc.data())
      console.log("request:",data);
      document.querySelector("#loading-indicator").style.display = "none";
      setRequestData(data)
      setEndRequestIndex(data.length);
      setStartRequestIndex(1)
      setProgressVisible(false)
    })
  }

  const getSpecialCode = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DiscountCoupons').where('type', '==', 'Special').orderBy("createdOn", "desc").limit(limit).get().then((querySnapshot) => {
      let data = querySnapshot.docs.map((doc) => doc.data())
      document.querySelector("#loading-indicator").style.display = "none";
      setSpecialData(data)
      setEndSpecialIndex(data.length);
      setStartSpecialIndex(1)
      setProgressVisible(false)
    })
  }

  const deleteCampaign = (data) =>{
    const afterDelete = campaignData.filter(item => item.itemID !== data);
      setCampaignData(afterDelete)

    db.collection("DiscountCoupons").doc(data).delete().then(() => {
      console.log('record deleted ')
      notify("Coupon deleted successfully",false);
    }).catch((error) => {
      notify(error.toString(),true);

      console.log('this is error ', error)
    })
  } 

  const deleteSpecial = (data) => {
    const afterDelete = specialData.filter(item => item.itemID !== data);
    setSpecialData(afterDelete)

    db.collection("DiscountCoupons").doc(data).delete().then(() => {
      console.log('record deleted ')
      notify("Coupon deleted successfully",false);
    }).catch((error) => {
      notify(error.toString(),true);

      console.log('this is error ', error)
    })
  }

  const searchCoupon = () => {
    if (campaignSearchValue == "") {
      notify("Search field can not be empty", true);
    } else if (campaignSearchValue.length <= 3) {
      notify("Search atleast 4 words", true);
    } else {
      setSearch(true);
      document.querySelector("#loading-indicator").style.display = "flex";

      couponSearchIndex
        .search(campaignSearchValue)
        .then((result) => {
          if (result.hits.length === 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            notify("No results found", true);
          } else {
            document.querySelector("#loading-indicator").style.display = "none";
            setCampaignData(result.hits);
            setReload(true);
          }
        })
        .catch((err) => {
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
        });
    }
  }

  const searchSpecialCoupon = () => {
    if (specialSearchValue == "") {
      notify("Search field can not be empty", true);
    } else if (specialSearchValue.length <= 3) {
      notify("Search atleast 4 words", true);
    } else {
      setSearch(true);
      document.querySelector("#loading-indicator").style.display = "flex";

      couponSearchIndex
        .search(specialSearchValue)
        .then((result) => {
          if (result.hits.length === 0) {
            document.querySelector("#loading-indicator").style.display = "none";

            notify("No results found", true);
          } else {
            document.querySelector("#loading-indicator").style.display = "none";
          
            setSpecialData(result.hits);
            setReload(true);
          }
        })
        .catch((err) => {
          document.querySelector("#loading-indicator").style.display = "none";
          notify(err.toString(), true);
        });
    }
  }

  const getStudent = () =>{
    db.collection('zSystemStudents').orderBy('createdAt', 'desc').limit(limit)
      .get().then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => doc.data())
        setStudentData(data);
        setProgressVisible(false)
      })
  }

  const declineRequest = () =>{
    let res_two = db.collection('DocumentRequest').doc(currentRequestData.itemID)
    let finalData_two
    finalData_two = {
      status: 'Declined'
    }

    res_two.set(finalData_two, {merge: true}).then(() => {
      notify("Request Declined", true);
      setRequestData(prev => prev.map((item) => item.itemID == currentRequestData.itemID ? ({ ...item, status: "Declined" }) : item))
    })
    setIsDecline(false);
  }

  const nextCampaignData = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DiscountCoupons')
    .where('type', '==', 'Campaign')
    .orderBy("createdOn", "desc").startAfter(campaignData[campaignData.length - 1].createdOn).limit(limit)
    .get().then((querySnapshot) => {
      
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if(data.length != 0){
        document.querySelector("#loading-indicator").style.display = "none";
        setCampaignData(data);
        setStartCampaignIndex(endCampaignIndex + 1);
        setEndCampaignIndex((prev) => prev + data.length);
      }else{
        document.querySelector("#loading-indicator").style.display = "none";

        notify("End Of the list", true);
      }

    })
    .catch((err) => {
      document.querySelector("#loading-indicator").style.display = "none";
      notify(err.toString(), true);
      console.log(err.toString());
    })
  }

  const nextSpecialData = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DiscountCoupons')
    .where('type', '==', 'Special')
    .orderBy("createdOn", "desc").startAfter(specialData[specialData.length - 1].createdOn).limit(limit)
    .get().then((querySnapshot) => {
      
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if(data.length != 0){
        document.querySelector("#loading-indicator").style.display = "none";
        setSpecialData(data);
        setStartSpecialIndex(endSpecialIndex + 1);
        setEndSpecialIndex((prev) => prev + data.length);
      }else{
        document.querySelector("#loading-indicator").style.display = "none";

        notify("End Of the list", true);
      }

    })
    .catch((err) => {
      document.querySelector("#loading-indicator").style.display = "none";
      notify(err.toString(), true);
      console.log(err.toString());
    })
  }

  const nextRequestData = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DocumentRequest')
    .where('type', '==', 'request')
    .orderBy("createdAt", "desc").startAfter(requestData[requestData.length - 1].createdAt).limit(limit)
    .get().then((querySnapshot) => {
      
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if(data.length != 0){
        document.querySelector("#loading-indicator").style.display = "none";
        setRequestData(data);
        setStartRequestIndex(endRequestIndex + 1);
        setEndRequestIndex((prev) => prev + data.length);
      }else{
        document.querySelector("#loading-indicator").style.display = "none";

        notify("End Of the list", true);
      }

    })
    .catch((err) => {
      document.querySelector("#loading-indicator").style.display = "none";
      notify(err.toString(), true);
      console.log(err.toString());
    })
  }

  const prevCampaignData = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DiscountCoupons')
    .where('type', '==', 'Campaign')
    .orderBy("createdOn").startAfter(campaignData[0].createdOn)
    .limit(startCampaignIndex<=limit+1 ? limit+10 : limit)
    .get().then((querySnapshot) => {
      
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (data.length !== 0) {
        document.querySelector("#loading-indicator").style.display = "none";

        data = data.reverse();
        setStartCampaignIndex((prev) => prev - data.length);
        setEndCampaignIndex((prev) => prev - campaignData.length);
        setCampaignData(data);
      } else {
        document.querySelector("#loading-indicator").style.display = "none";
      }

    })
    .catch((err) => {
      document.querySelector("#loading-indicator").style.display = "none";
      notify(err.toString(), true);
      console.log(err.toString());
    });
  }

  const prevSpecialData = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DiscountCoupons')
    .where('type', '==', 'Special')
    .orderBy("createdOn").startAfter(specialData[0].createdOn)
    .limit(startSpecialIndex<=limit+1 ? limit+10 : limit)
    .get().then((querySnapshot) => {
      
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (data.length !== 0) {
        document.querySelector("#loading-indicator").style.display = "none";

        data = data.reverse();
        setStartSpecialIndex((prev) => prev - data.length);
        setEndSpecialIndex((prev) => prev - specialData.length);
        setSpecialData(data);
      } else {
        document.querySelector("#loading-indicator").style.display = "none";
      }

    })
    .catch((err) => {
      document.querySelector("#loading-indicator").style.display = "none";
      notify(err.toString(), true);
      console.log(err.toString());
    });
  }

  const prevRequestData = () => {
    document.querySelector("#loading-indicator").style.display = "flex";
    db.collection('DocumentRequest')
    .where('type', '==', 'request')
    .orderBy("createdAt").startAfter(requestData[0].createdAt)
    .limit(startRequestIndex<=limit+1 ? limit+10 : limit)
    .get().then((querySnapshot) => {
      
      let data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (data.length !== 0) {
        document.querySelector("#loading-indicator").style.display = "none";

        data = data.reverse();
        setStartRequestIndex((prev) => prev - data.length);
        setEndRequestIndex((prev) => prev - requestData.length);
        setRequestData(data);
      } else {
        document.querySelector("#loading-indicator").style.display = "none";
      }

    })
    .catch((err) => {
      document.querySelector("#loading-indicator").style.display = "none";
      notify(err.toString(), true);
      console.log(err.toString());
    });
  }

  const emptyCallSpecialCode = () => {
    getSpecialCode();
  }

  const emptyCallCampaignCode = () => {
    getCampaignCode();
  }


  useEffect(() => {
    if(campaignActive){

      getUsers();
      getCampaignCode();
      // getSpecialCode();
      // getRequest();
      getStudent();
    } else if(specialActive) {
      getSpecialCode()
    } else {
      getRequest()
    }
  }, [limit]);

  
  const studentSearch = (studentSearchValue) => {
    
    studentSearchIndex
    .search(studentSearchValue)
    .then((result) => {
      if (result.hits.length === 0) {
        notify("No results found", true);
      } else {
        setStudentData(result.hits);
      }
    })
    .catch((err) => {
      notify(err.toString(), true);
    });
   
  };

  return (
    <>
      {progressVisible ? (
        <Progress color="info" value={progressValue} style={{ height: 4 }} />
      ) : (
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notifi} />
          </div>

          <Row>
            <Col md="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Col>
                    <ButtonGroup>
                      <Button
                        className={campaignActive ? "data" : "btn-simple data"}
                        color= "info"
                        size="sm"
                        onClick={() => {
                          setCampaignActive(true)
                          setSpecialActive(false)
                          setRequestActive(false)
                          getCampaignCode()
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Campaign Discount
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        className={specialActive ? "data" : "btn-simple data"}
                        color= "info"
                        size="sm"
                        onClick = {() => {
                          setCampaignActive(false)
                          setSpecialActive(true)
                          setRequestActive(false)
                          getSpecialCode()
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Special Discount
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        className={requestActive ? "data" : "btn-simple data"}
                        color= "info"
                        size="sm"
                        onClick={() => {
                          setCampaignActive(false)
                          setSpecialActive(false)
                          setRequestActive(true)
                          getRequest()
                        }}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Discount Request
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                  {campaignActive ? (
                    <Button
                      color="info"
                      style={{ marginRight: 25 }}
                      onClick={() => {
                        setCurrentCampaignData('')
                        setIsModalVisible(true)}}
                    >
                      Create Campaign Code <Add />
                    </Button>
                  ) : (
                    specialActive ? 
                    <Button
                      color="info"
                      style={{ marginRight: 25 }}
                      onClick={() => {
                        setCurrentSpecialData('')
                        setIsModalVisible(true)
                        setEdit(true)}}
                    >
                      Create Special Code <Add />
                    </Button>
                    : null
                  )}
                </CardHeader>

                {campaignActive ? (
                  <>
                    <CardBody style={{ paddingTop: "10px" }}>
                      <Row style={{ justifyContent: "flex-end" }}>
                        <Col sm={4}>
                          <Input type="text" name="searchDiscount" value={campaignSearchValue} placeholder="Search Discount" 
                            onChange={(e)=> (setCampaignSearchValue(e.target.value),
                              e.target.value.length === 0 && emptyCallCampaignCode(),setSearch(false))
                            }/>
                        </Col>
                        <Col style={{ paddingLeft: '0px' }} sm={2}>
                            <Button style={{ margin: "0px", padding: '6px 10px' }} color="info"
                              onClick={searchCoupon}
                            >
                              <SearchIcon />
                            </Button>
                        </Col>
                        <Col
                          style={{ display: "flex", justifyContent: "flex-end" }}
                          sm={6}
                        >
                      { !search &&
                        <> 
                          <p
                            style={{
                              position: "relative",
                              top: "8px",
                              marginRight: "15px",
                            }}
                          >
                            {startCampaignIndex <= 1 ? 1 : startCampaignIndex} - {endCampaignIndex}
                          </p>
                          <div
                            style={{
                              position: "relative",
                              top: "-5px",
                              marginRight: "20px",
                            }}
                          >
                            {startCampaignIndex > 1 && (
                              <IconButton
                                style={{ outline: "none" }}
                                onClick={() => {
                                  prevCampaignData();
                                }}
                              >
                                <KeyboardArrowLeftSharpIcon
                                  style={{ color: "white" }}
                                />
                              </IconButton>
                            )}
                            <IconButton
                              style={{ outline: "none" }}
                              onClick={() => nextCampaignData()}
                            >
                              <KeyboardArrowRightSharpIcon
                                style={{ color: "white" }}
                              />
                            </IconButton>
                          </div>
                          
                          <Input
                            style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                            type="select"
                            value={limit}
                            onChange={(e)=>setLimit(parseInt(e.target.value))}
                            // required
                          >
                            <option value='10'>10</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                          </Input>
                          </>
                          }
                        </Col>
                      </Row>
                      <Modal
                        isOpen={isModalVisible}
                        style={{ display: "flex", flex: 1 }}
                        size="lg"
                      >
                        <ModalBody
                          style={{
                            width: "100%",
                            display: "inline-block",
                            padding: "0px !important",
                            position: "absolute",
                            border: "1px solid white !important",
                          }}
                          size="sm"
                        >
                          <CampaignDiscountModal
                            notify={notify}
                            id = {nanoid()}
                            currentCampaignData={currentCampaignData}
                            setCampaignData={setCampaignData}
                            setModal={(val) => {
                              setIsModalVisible(val)
                            }}
                          ></CampaignDiscountModal>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={campaignDeleteModal} color='primary' style={{ display: 'flex', flex: 1, top: -40, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
                        <ModalBody style={{ width: '100%' }} size='sm'>
                          <CampaignDelete
                            deleteCapmaign={() => deleteCampaign(currentCampaignData)}
                            setCampaignDeleteModal={setCampaignDeleteModal}
                          ></CampaignDelete>
                        </ModalBody>
                      </Modal>
                      <br></br>
                      <Table className="tablesorter">
                        <thead>
                          <tr>
                            <th>Coupon Code</th>
                            <th style={{textAlign: "center"}}>Coupon for</th>
                            <th style={{textAlign: "center"}}>Validity</th>
                            <th style={{textAlign: "center"}}>Discount amount</th>
                            <th style={{textAlign: "center"}}>Usable</th>
                            <th style={{textAlign: "center"}}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campaignData.map((item) => {
                            return (
                              <tr>
                                <td>{item.couponCode}</td>
                                <td style={{textAlign: "center"}}>{item.validFor}</td>
                                <td style={{textAlign: "center"}}>{format(new Date(item.validFrom), "dd/MM/yyyy hh:mm aa")} - {format(new Date(item.validTill), "dd/MM/yyyy hh:mm aa")}</td>
                                <td style={{fontFamily: 'Roboto, sans-serif', textAlign: "center"}}>{item.discountIn == "Rupees" ? "₹" : null} {item.discountAmount} {item.discountIn == "Percentage" ? "%" : null}</td>
                                <td style={{textAlign: "center"}}>{item.used ?
                                  <ErrorIcon style={{ fontSize: '35px', color: '#f51d1d' }}/>
                                  :
                                  <CheckCircleIcon style={{ fontSize: '35px', color: '#17de3e' }}/>
                                }
                                </td>
                                <td style={{textAlign: "center"}}>
                                  <Edit style={{color:'#247cf7', cursor: 'pointer'}}
                                    onClick={() => {
                                      setCurrentCampaignData(item)
                                      setIsModalVisible(true)
                                    }}
                                  />
                                  <Delete
                                    onClick={() => {
                                      setCurrentCampaignData(item.itemID)
                                      setCampaignDeleteModal(true)
                                    }}
                                    style={{ color: 'red', cursor: 'pointer' }}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </>
                ) :
                    specialActive ? 
                      <>
                        <CardBody style={{ paddingTop: "10px" }}>
                          <Row style={{ justifyContent: "flex-end" }}>
                            <Col sm={4}>
                              <Input type="text" name="search" value={specialSearchValue} placeholder="Search Coupon" 
                                onChange={(e)=> (setSpecialSearchValue(e.target.value), 
                                  e.target.value.length === 0 && emptyCallSpecialCode(),setSearch(false))
                              }/>
                            </Col>
                            <Col style={{ paddingLeft: '0px' }} sm={2}>
                                <Button style={{ margin: "0px", padding: '6px 10px' }} color="info"
                                onClick={searchSpecialCoupon}
                                >
                                  <SearchIcon />
                                </Button>
                            </Col>
                        <Col
                          style={{ display: "flex", justifyContent: "flex-end" }}
                          sm={6}
                        >
                      { !search && <> <p
                                style={{
                                  position: "relative",
                                  top: "8px",
                                  marginRight: "15px",
                                }}
                              >
                                {startSpecialIndex <= 1 ? 1 : startSpecialIndex} - {endSpecialIndex}
                              </p>
                              <div
                                style={{
                                  position: "relative",
                                  top: "-5px",
                                  marginRight: "20px",
                                }}
                              >
                                {startSpecialIndex > 1 && (
                                  <IconButton
                                    style={{ outline: "none" }}
                                    onClick={() => {
                                      prevSpecialData();
                                    }}
                                  >
                                    <KeyboardArrowLeftSharpIcon
                                      style={{ color: "white" }}
                                    />
                                  </IconButton>
                                )}
                                <IconButton
                                  style={{ outline: "none" }}
                                  onClick={() => nextSpecialData()}
                                >
                                  <KeyboardArrowRightSharpIcon
                                    style={{ color: "white" }}
                                  />
                                </IconButton>
                              </div>
                              
                              <Input
                                style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                                type="select"
                                value={limit}
                                onChange={(e)=>setLimit(parseInt(e.target.value))}
                                // required
                              >
                                <option value='10'>10</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                              </Input>
                              </>
                            }
                            </Col>
                          </Row>
                          <Modal
                            isOpen={isModalVisible}
                            style={{ display: "flex", flex: 1 }}
                            size="lg"
                          >
                            <ModalBody
                              style={{
                                width: "100%",
                                display: "inline-block",
                                padding: "0px !important",
                                position: "absolute",
                                border: "1px solid white !important",
                              }}
                              size="sm"
                            >
                              <SpecialDiscountModal
                                notify={notify}
                                id = {nanoid()}
                                studentSearch={studentSearch}
                                studentData= {studentData && studentData}
                                currentSpecialData={currentSpecialData}
                                setSpecialData = {setSpecialData}
                                getStudent={getStudent}
                                setModal={(val) => setIsModalVisible(val)}
                                edit={edit}
                              ></SpecialDiscountModal>
                            </ModalBody>
                          </Modal>
                          <Modal isOpen={specialDeleteModal} color='primary' style={{ display: 'flex', flex: 1, top: -40, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
                            <ModalBody style={{ width: '100%' }} size='sm'>
                                <SpecialDelete
                                  deleteSpecial={() => deleteSpecial(currentSpecialData)}
                                  setSpecialDeleteModal={setSpecialDeleteModal}

                                ></SpecialDelete>
                              </ModalBody>
                          </Modal>
                          <br></br>
                          <Table className="tablesorter">
                            <thead>
                              <tr>
                                <th>Coupon Code</th>
                                <th style={{textAlign: "center"}}>Coupon type</th>
                                <th style={{textAlign: "center"}}>Validity Starts from</th>
                                <th style={{textAlign: "center"}}>Discount amount</th>
                                <th style={{textAlign: "center"}}>Usable</th>
                                <th style={{textAlign: "center"}}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {specialData && specialData.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.couponCode}</td>
                                    <td style={{textAlign: "center"}}>{item.discountType === "oldStudent" ? "Old Student" : item.discountType}</td>
                                    <td style={{textAlign: "center"}}>{format(new Date(item.validFrom), "dd/MM/yyyy hh:mm aa")}</td>
                                    <td style={{fontFamily: 'Roboto, sans-serif', textAlign: "center"}}>{item.discountIn == "Rupees" ? "₹" : null} {item.discountAmount} {item.discountIn == "Percentage" ? "%" : null}</td>
                                    <td style={{textAlign: "center"}}>{item.used ? 
                                      <ErrorIcon style={{ fontSize: '35px', color: '#f51d1d' }}/>
                                      :
                                      <CheckCircleIcon style={{ fontSize: '35px', color: '#17de3e' }}/>
                                    }
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                      <Edit style={{color:'#247cf7', cursor: 'pointer'}}
                                        onClick={() => {
                                          setCurrentSpecialData(item)
                                          setIsModalVisible(true)
                                        }}
                                      />
                                      <Delete
                                        onClick={() => {
                                          setCurrentSpecialData(item.itemID)
                                          setSpecialDeleteModal(true)
                                        }}
                                        style={{ color: 'red', cursor: 'pointer' }}
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </CardBody>
                      </>
                    :
                    <>
                      <CardBody style={{ paddingTop: "10px" }}>
                        <Row style={{ justifyContent: "flex-end" }}>
                          <Col
                            style={{ display: "flex", justifyContent: "flex-end" }}
                            sm={6}
                          >
                            <p
                              style={{
                                position: "relative",
                                top: "8px",
                                marginRight: "15px",
                              }}
                            >
                              {startRequestIndex <= 1 ? 1 : startRequestIndex} - {endRequestIndex}
                            </p>
                            <div
                              style={{
                                position: "relative",
                                top: "-5px",
                                marginRight: "20px",
                              }}
                            >
                              {startRequestIndex > 1 && (
                                <IconButton
                                  style={{ outline: "none" }}
                                  onClick={() => {
                                    prevRequestData();
                                  }}
                                >
                                  <KeyboardArrowLeftSharpIcon
                                    style={{ color: "white" }}
                                  />
                                </IconButton>
                              )}
                              <IconButton
                                style={{ outline: "none" }}
                                onClick={() => nextRequestData()}
                              >
                                <KeyboardArrowRightSharpIcon
                                  style={{ color: "white" }}
                                />
                              </IconButton>
                            </div>
                            
                            <Input
                              style={{maxWidth:67, marginRight:10, position:'relative', top:3}}
                              type="select"
                              value={limit}
                              onChange={(e)=>setLimit(parseInt(e.target.value))}
                              // required
                            >
                              <option value='10'>10</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </Input>
                          </Col>
                        </Row>
                        <Modal
                          isOpen={isModalVisible}
                          style={{ display: 'flex', flex: 1}}
                          size="lg"
                        >
                          <ModalBody
                            style={{
                              width: "100%",
                              display: "inline-block",
                              padding: "0px !important",
                              position: "absolute",
                              border: "1px solid white !important",
                            }}
                            size="sm"
                          >
                            <AcceptDiscount
                              setModal={(val) => {
                                setIsModalVisible(val)
                              }}
                              id={nanoid()}
                              currentRequestData={currentRequestData}
                              setRequestData={setRequestData}
                              notify={notify}
                            >

                            </AcceptDiscount>
                          </ModalBody>
                        </Modal>
                        <Modal
                          isOpen={isDeclineModal}
                          color='primary' 
                          style={{ display: 'flex', flex: 1, top: 0, width: 250, backgroundColor: '#132639', objectFit: 'fill' }}
                          size="lg"
                        >
                          <ModalBody
                            style={{
                              width: "100%",
                            }}
                            size="sm"
                          >
                            <DeclineDiscount
                              setModal={(val) => {
                                setIsDecline(val)
                              }}
                              declineRequest={declineRequest}
                            >

                            </DeclineDiscount>
                          </ModalBody>
                        </Modal>
                        <br></br>
                        <Table className="tablesorter">
                          <thead>
                            <tr>
                              <th>Student Name</th>
                              <th style={{textAlign: "center"}}>Mobile No</th>
                              <th style={{textAlign: "center"}}>Type of discount</th>
                              <th style={{textAlign: "center"}}>Document</th>
                              <th style={{textAlign: "center"}}>Status</th>
                              {/* <th style={{textAlign: "center"}}>Query</th> */}
                              <th style={{textAlign: "center"}}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requestData && requestData.map((item) => {
                              return (
                                <tr>
                                  <td>{item.studentName}</td>
                                  <td style={{textAlign: "center"}}>{item.studentMobile}</td>
                                  <td style={{textAlign: "center"}}>{item.reqType === "oldStudent" ? "Old Student" : item.reqType}</td>
                                  <td style={{textAlign: "center", cursor:"pointer"}} onClick={() => window.open(item.documentURL)}>
                                    <DescriptionIcon />
                                  </td>
                                  <td style={{textAlign: "center"}}>{item.status}</td>
                                  <td style={{textAlign: "center"}}>{item.query ? item.query : "N/A"}</td>
                                  <td style={{textAlign: "center"}}>
                                    {item.status == "Declined" || item.status == "Approved" ? "N/A" :(
                                      <>
                                      <CheckCircleIcon style={{ fontSize: '35px', color: '#17de3e', cursor:"pointer" }} onClick={() => {
                                        setCurrentRequestData(item)
                                        setIsModalVisible(true)
                                      }}/>
                                      <CancelIcon style={{ fontSize: '35px', color: '#f51d1d', cursor:"pointer" }} onClick={() => {
                                        setIsDecline(true)
                                        setCurrentRequestData(item)
                                      }}/>
                                      </>
                                    ) }

                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </> 
                }
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default DiscountManagement;
