import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Label,
    Col,
    Alert,
    Modal,
    ModalBody
} from "reactstrap";
import styles from './quizEditModal.module.css'
import { useForm } from "react-hook-form";

import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";


function QuizEditModal({ onClick, currentQuestion, setquizData, notify }) {
    const { handleSubmit, register, errors, reset } = useForm();
    console.log('edit quiz', currentQuestion)

    const onSubmit = data => {

        // const data = {
        //     questionTitle: values.questionTitle,
        //     options: options,
        //     correctOption: parseInt(values.correctOption),
        //     difficulty: parseInt(values.difficulty),
        //     subject: values.subject,
        // }


        // db.collection('Questions').add(data).then(()=>{db.collection('Questions')
        //     .get()
        //     .then(querySnapshot => {
        //         let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        //         setquizData(data)
        //         // console.log('my user data is ', data)
        //         notify("Question Added Successfully")
        //         // setquizData((previousData) => ([...previousData, data]))
        //         // console.log('documnet successfully inserted',data);
        //         setalert(true);
        //         e.target.reset();
        //     })} )

        const options = [
            data.optionA,
            data.optionB,
            data.optionC,
            data.optionD
        ]
        if (data.optionE !== '') options.push(data.optionE)
        if (data.optionF !== '') options.push(data.optionF)

        console.log('options........',options)
        const updatedData = {
            questionTitle: data.questionTitle,
            options: options,
            correctOption: parseInt(data.correctOption),
            difficulty: parseInt(data.difficulty),
            subject: data.subject,
        }
        console.log('updatedData........',updatedData)

        db.collection('Questions').doc(currentQuestion.id).update(updatedData).then(function () {
            console.log('documnet Edited', data)
            setquizData(prev => prev.map((item) => item.id == currentQuestion.id ? ({ ...updatedData, id: currentQuestion.id }) : item))
            onClick()
            notify("Question updated successfully", false);
        }).catch(function (error) {
            notify(error.toString(), true);

            console.log('this is error', error)
        })

    }
    return (

        <div className="content" >
            <Row >
                <Col md="12">
                    <Card style={{ margin: 0 }}>
                        <CardHeader>
                            <h5 className="title">Edit Question</h5>
                        </CardHeader>
                        <CardBody>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <FormGroup row>
                                    <Col sm={7} style={{ borderRight: '1px solid #29384a' }}>
                                        <FormGroup row>

                                            <Label className={styles.headlabel} sm={2} >Question</Label>
                                            <Col sm={10}>
                                                <Input style={{ minHeight: '295px' }} defaultValue={currentQuestion.questionTitle} innerRef={register({ required: true })} type="textarea" name="questionTitle" placeholder="Please Enter Question" required />
                                            </Col>
                                        </FormGroup>
                                        <br></br>
                                        <FormGroup row>
                                        <Label style={{ padding: '9px' }} className={styles.headlabel} sm={2}>Correct Answer</Label>
                                        <Col sm={10}>
                                            <Input defaultValue={currentQuestion.correctOption} innerRef={register({ required: true })} type="select" name="correctOption" required>
                                                <option value={0}>A</option>
                                                <option value={1}>B</option>
                                                <option value={2}>C</option>
                                                <option value={3}>D</option>
                                                <option value={4}>E</option>
                                                <option value={5}>F</option>

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label style={{ padding: '9px' }} className={styles.headlabel} sm={2}>Difficulty Level</Label>
                                        <Col sm={10}>
                                            <Input defaultValue={currentQuestion.difficulty} innerRef={register({ required: true })} type="select" name="difficulty" required>
                                                <option value={1}>Easy</option>
                                                <option value={2}>Medium</option>
                                                <option value={3}>Difficult</option>
                                                {/* <option value={4}>Level 4</option>
                                                <option value={5}>Level 5</option> */}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Subject</Label>
                                        <Col sm={10}>
                                            <Input defaultValue={currentQuestion.subject} innerRef={register({ required: true })} type="select" name="subject" required>
                                                <option>Science</option>
                                                <option>Math</option>
                                                <option>English</option>
                                                <option>Social Science</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>A.</Label>
                                            <Col sm={10}>
                                                <Input defaultValue={currentQuestion.options[0]} innerRef={register({ required: true })} type="textarea" name="optionA" placeholder="Option A" required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>B.</Label>
                                            <Col sm={10}>
                                                <Input defaultValue={currentQuestion.options[1]} innerRef={register({ required: true })} type="textarea" name="optionB" placeholder="Option B" required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>C.</Label>
                                            <Col sm={10}>
                                                <Input defaultValue={currentQuestion.options[2]} innerRef={register({ required: true })} type="textarea" name="optionC" placeholder="Option C" required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>D.</Label>
                                            <Col sm={10}>
                                                <Input defaultValue={currentQuestion.options[3]} innerRef={register({ required: true })} type="textarea" name="optionD" placeholder="Option D" required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>E.</Label>
                                            <Col sm={10}>
                                                <Input defaultValue={currentQuestion.options.length > 4 ? currentQuestion.options[4] : ''} innerRef={register({ required: false })} type="textarea" name="optionE" placeholder="Option E"  />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className={styles.textalign} sm={2}>F.</Label>
                                            <Col sm={10}>
                                                <Input defaultValue={currentQuestion.options.length > 5 ? currentQuestion.options[5] : ''} innerRef={register({ required: false })} type="textarea" name="optionF" placeholder="Option F"  />
                                            </Col>
                                        </FormGroup>
 
                                    </Col>
                                    {/* <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Correct Answer</Label>
                                        <Col sm={9}>
                                            <Input defaultValue={currentQuestion.correctOption} innerRef={register({ required: true })} type="select" name="correctOption" required>
                                                <option value={0}>A</option>
                                                <option value={1}>B</option>
                                                <option value={2}>C</option>
                                                <option value={3}>D</option>
                                                <option value={4}>E</option>
                                                <option value={5}>F</option>

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Difficulty Level</Label>
                                        <Col sm={9}>
                                            <Input defaultValue={currentQuestion.difficulty} innerRef={register({ required: true })} type="select" name="difficulty" required>
                                                <option value={1}>Level 1</option>
                                                <option value={2}>Level 2</option>
                                                <option value={3}>Level 3</option>
                                                <option value={4}>Level 4</option>
                                                <option value={5}>Level 5</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Subject</Label>
                                        <Col sm={9}>
                                            <Input defaultValue={currentQuestion.subject} innerRef={register({ required: true })} type="select" name="subject" required>
                                                <option>Science</option>
                                                <option>Math</option>
                                                <option>English</option>
                                                <option>Social Science</option>
                                            </Input>
                                        </Col>
                                    </FormGroup> */}
                                </FormGroup>
                                <br></br>

                                <Button color='info' type='submit'>Update</Button>
                                <Button
                                    onClick={onClick}
                                > Back</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default QuizEditModal
